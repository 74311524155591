// /////////////////////////////////////////////
// /////////////////////////////////////////////
// ///////////ENTERPRISE APP////////////////////
// /////////////////////////////////////////////
// /////////////////////////////////////////////
.custom-modal-add-sms-number-wrapper {
  .dropdownSelect__option {
    &:nth-of-type(1) {
      .code-list-item {
        font-family: $primary-bold-font;
      }
    }
  }
}

// enterprise user header
.is-enterprise-user {
  .main-header.enterprise-main-header {
    padding-top: rem(70);
    height: auto;

    .b-custom-logo {
      width: fit-content;
      position: absolute;
      top: rem(10);
      left: 50%;
      transform: translateX(-50%);
    }

    .logo-block {
      // max-width: rem(60);
      max-height: rem(60);

      .image-logo {
        object-fit: contain;
      }
    }

    .logo-text {
      font-size: rem(16);
      font-family: $secondary-bold-font;
    }
  }

  .main-wrapper.enterprises-wrapper {
    top: rem(110);
  }

  .main-wrapper.enterprises-wrapper-show-upgrade-trial {
    top: rem(80);
  }

  @media screen and (max-width: 575px) {
    .main-wrapper.enterprises-wrapper-show-upgrade-trial {
      top: rem(170);
    }
  }

  .icon-custom-view-trash {
    @include size(rem(14));

    svg path {
      fill: $icon-gray;
      @include transition(all 0.3s linear);
    }

    &:hover {
      svg path {
        fill: $danger;
      }
    }
  }

  .icon-custom-view-edit {
    @include size(rem(14));

    svg g {
      stroke: $icon-gray;
      @include transition(all 0.3s linear);
    }

    &:hover svg g {
      stroke: $primary;
    }
  }

  .custom-view-list-item {
    .btn-action {
      @include visibility(hidden);
      @include transition(all 0.3s linear);
    }

    &:hover {
      .btn-action {
        @include visibility(visible);
      }
    }
  }

  .b-reviews-datepicker-filter {
    .custom-icon-calendar {
      @include size(rem(22));
      min-width: rem(22);

      svg {
        max-width: 100%;
      }
    }
  }
}

// end enterprise user header

// location filter
.b-location-filter {
  width: 100%;

  .dropdown-menu {
    width: 100%;
  }

  .left-space {
    padding-left: rem(45);
  }

  .right-space {
    padding-right: rem(40);
  }
}

// end location filter

// category search for add managers modal
.add-managers-form {
  .custom-react-search-select-wrapper.custom-group-select {
    .search-select {
      .globalSelect__input::after {
        content: '';
      }

      .globalSelect__control--is-focused {
        .globalSelect__input {
          &::after {
            content: '';
          }
        }
      }

      .globalSelect__multi-value {
        position: relative;
        padding: 0;
        border: none;
        background: transparent;

        .b-colored {
          padding-right: rem(40);
          display: flex;
          align-items: center;
          height: rem(30);
        }

        .globalSelect__multi-value__remove {
          position: absolute;
          right: rem(9);
          height: rem(30);
          z-index: 1;
        }

        .globalSelect__multi-value__label {
          padding: 0;
        }
      }

      .b-colored {
        display: inline-block;
        padding-left: rem(10);
        padding-right: rem(10);
        @include border-radius(rem(32));
      }
    }
  }
}

// end category search for add managers modal

.custom-location-select-dropdown .dropdownSelect__option {
  text-transform: capitalize;
}

.custom-location-select-dropdown {
  .dropdownSelect__single-value,
  .dropdownSelect__option {
    text-transform: capitalize;
  }
}

// custom dropdown filter style
.custom-filter-dropdown {
  .item-list {
    &:hover {
      background-color: $light-gray;
    }
  }
}

// end custom dropdown filter style

// Contact hub add group modal
.custom-create-group-form {
  .search-select {
    .globalSelect__dropdown-indicator {
      top: rem(-3);
      position: relative;
    }
  }
}

// End Contact hub add group modal

// Contact hub add contact modal
.custom-react-search-select-wrapper {
  &.custom-group-select .custom-add-groups-dropdown {
    .search-select {
      .globalSelect__menu-list {
        max-height: rem(170);
      }
    }
  }
}

// End Contact hub add contact modal

// Edit column modal
.has-x-padding-40 {
  padding-right: rem(40);
}

.right-wrapper {
  max-height: rem(330);
}

.dragula-item {
  display: flex;
  padding-top: rem(11);
  padding-bottom: rem(11);
  @include box-shadow(0 0 rem(4) rgba(0, 65, 88, 0.14));

  .icon-dragIcon {
    max-width: rem(7);
    @include size(rem(7), rem(14));
  }

  .icon-close {
    @include size(rem(12));
    max-width: rem(12);

    svg path {
      fill: $icon-dark;
    }
  }

  &:hover {
    @include box-shadow(0 0 rem(10) rgba(0, 65, 88, 0.2));
  }
}

// End Edit column modal

// Messenger No location nodata page
.messenger-no-location-nodata-wrapper {
  .no-data-page {
    min-height: calc(100vh - 250px) !important; // To override Dynamically calculated height of no data.
  }
}
// End Messenger No location nodata page

//tooltip index updated
.tooltip.show {
  z-index: 1501;
}
// Site settings
.page-settings-branding {
  .form-switch label.has-disabled {
    cursor: not-allowed;
    @include opacity(0.5);
  }
}
// End Site settings
