@include media-breakpoint-up(md) {
  // booking list page
  .page-list {
    .custom-date-picker {
      width: rem(220);
    }
    .status-dropdown {
      width: rem(160);
    }
  }

  // end booking list page

  // calendar page
  .b-calendar-view {
    .b-sideview-booking-list {
      // calendar sideview booking list
      width: rem(400);
      position: absolute;
      top: 0;
      right: 0;
      @include transition(all 0.3s linear);
      height: 100%;
      max-height: 100%;
      background: white;
      z-index: 10;
      box-shadow: rgba(0, 0, 0, 0.15) rem(-2) 0 rem(8) 0;
    }
  }
  .calendar-btn-split-wrapper {
    .calendar-split-list-btn {
      @include size(rem(40));
      svg {
        @include size(rem(18), rem(14));
      }
    }
    .calendar-btn-switch-block {
      @include size(rem(40));
      svg {
        @include size(rem(18));
        min-width: rem(18);
      }
    }
  }
  .page-booking-calendar {
    .calendar-btn-settings {
      border: rem(1) solid $border-color;
      height: rem(44);
      line-height: rem(44);
    }
    .btn-md-primary {
      background-color: $primary;
      height: rem(44);
      line-height: rem(44);
    }
  }
  :global(.calendar-day-events-no) {
    background: none !important;
    cursor: not-allowed;
    z-index: 10;
    position: relative;
    &:after {
      position: absolute;
      display: flex;
      content: 'No events';
      width: 100%;
      top: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      opacity: 0;
    }
  }
  :global(.calendar-day-events-no:hover) {
    &:after {
      opacity: 1;
      @include transition(all 0.3s linear);
    }
  }
  // End calendar page
}
