// react select custom style
.dropdown-select {
    background: $bg-list;
    &.no-focus-shadow{
        .dropdownSelect__control { 
            &.dropdownSelect__control--is-focused{
                @include box-shadow(none); 
            } 
        }
    }
    .dropdownSelect__control {
        height      : rem(46);
        border-color: $border-color;
        background: transparent;
        cursor: pointer;
        &.dropdownSelect__control--is-focused{
            @include box-shadow(0 0 0 rem(1) #DAE6FF, 0 0 0 rem(4) rgba($primary, 0.25));
            border-color: $primary;
            &:hover {
                border-color: $primary;
            }
        }
        &:hover {
            border-color: $border-color;
        }
    }

    .dropdownSelect__menu {
        z-index: 2;
        left: 0;
    }

    .dropdownSelect__value-container {
        padding-left : rem(15);
        padding-right: rem(15);
    }

    .dropdownSelect__placeholder {
        color: rgba($secondary, 0.8);
    }

    .dropdownSelect__indicator-separator {
        display: none;
    }

    .dropdownSelect__dropdown-indicator {
        margin-right: rem(15);
        background  : none;

        svg {
            display: none;
        }
    }
    .dropdownSelect__option--is-selected {
        background: white;
        color: $secondary;
        font-family: $tertiary-font;
    }
    .dropdownSelect__option {
        background: white;
        cursor: pointer;
        &:hover {
            background: $list-bg-color;
            color: $secondary;
        }
    }
    .dropdownSelect__menu-list {
        max-height: rem(200);
        @include scrollbar;
        @include border-radius(rem(4));
    }
    &.no-arrow-down {
        &:before {
            display: none;
        }
        .dropdownSelect__dropdown-indicator {
            display: none;
        }
    }
}

.custom-form-group-dropdown {
    .dropdown-select {
        position: relative;
        // background: transparent;
        &.dropdown-select-height-md{
            .dropdownSelect__control{
                height: rem(44);
            }
        }
        &:before {
            content  : '';
            right    : rem(15);
            position : absolute;
            top      : 50%;
            transform: translateY(-50%);
            z-index  : 0;
            @include opacity(0.3);
            @include caret-down();
        }

        .dropdownSelect__menu-list {
            background: white;
            max-height: rem(200);
            @include scrollbar;
        }
    }
}

// For sent review request popup react select custom style
.sent-review-request-form-wrapper {
    .dropdown-select {

        &.is-not-focus .dropdownSelect__control {
            min-height: rem(46);
            max-height: rem(46);
            overflow-y: hidden;
            position  : relative;

            .dropdownSelect__value-container {
                padding-top: rem(5);
            }

            .dropdownSelect__multi-value {
                display: none;
            }

            .dropdownSelect__multi-value:nth-of-type(1),
            .dropdownSelect__multi-value:nth-of-type(2){
                display: block;
            }
        }

        .badge-collapse {
            position: absolute;
            right   : rem(10);
            top: rem(10);
        }

        &.is-focus .dropdownSelect__control {
            height: auto;
        }

        .dropdownSelect__control {
            height    : auto;
            min-height: rem(46);
            max-height: rem(104);
            overflow  : auto;
            @include scrollbar;
            @include transition(all 0.3s linear);
        }

        .dropdownSelect__multi-value {
            display   : block;
            background: white;
            position  : relative;
            cursor    : pointer;
            padding   : rem(2) rem(25) rem(2) rem(8);
            @include border-radius(rem(4));
            border: rem(1) solid $border-color;
        }

        .dropdownSelect__multi-value__label {
            vertical-align: middle;
        }

        .dropdownSelect__multi-value__remove {
            position: absolute;
            top     : rem(6);
            right   : 0;
            cursor  : pointer;

            &:hover {
                background: transparent;

                svg path {
                    fill: $secondary;
                }
            }
        }

        .dropdownSelect__value-container--has-value {
            padding-left: rem(2);
        }

        .css-b8ldur-Input,
        .globalSelect__placeholder + div,
        .globalSelect__multi-value + div {
            width   : 100%;
            position: relative;

            &:after {
                content  : '';
                position : absolute;
                right    : 0;
                top      : 50%;
                transform: translateY(-50%);
                @include opacity(0.3);
                @include caret-down();
            }
        }

        &:before {
            display: none;
        }

        .dropdownSelect__dropdown-indicator {
            display: none;
        }
    }
}

//dropdown select custom
.dropdown-select--custom {
    height    : rem(40);
    min-height: rem(40); 
    .dropdownSelect__single-value {
      padding-top: 4px;
      margin-left: rem(-2);
      background : $bg-list;
      color: $light-text-color; 
      padding-right: rem(30);
    }

    .dropdownSelect__placeholder {
      padding-top  : 4px;
      width        : rem(108);
      white-space  : nowrap;
      overflow     : hidden;
      text-overflow: ellipsis;
    }

    .dropdownSelect__dropdown-indicator {
      padding-right: 0; 
    }

    .dropdownSelect__indicator {
      padding: 0;
    }

    .dropdownSelect__control {
      height    : rem(40);
      min-height: rem(40);
    }

    .dropdownSelect__value-container {
      top: rem(-2);
    } 
    .dropdown--custom-icon{
      top: 50%;
      transform: translateY(-50%);
      right: rem(15);
      svg path{
          fill: $secondary;
      }
    }
  }