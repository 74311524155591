@include media-breakpoint-up(md) {
  .page-body {
    padding: rem(15);
  }

  // full page loader
  .full-page-loader {
    .center-progress {
      &:before {
        background-size: 50%;
      }

      // &:after {
      //   background-size: 50%;
      // }
    }
  }

  // end full page loader

  // enterprise user header
  .is-enterprise-user {
    .main-header {
      padding-top: rem(0);

      .b-custom-logo {
        top      : 50%;
        transform: translate(-50%, -50%);
      }

      .logo-block {
        margin-left : 0;
        // max-width: rem(100);
      }
    }
  }

  // end enterprise user header

  //  business hours for add service popup
  .common-add-service-modal {
    .b-hours {
      .checkbox-weeks {
        width: auto;
      }

      .switch {
        width: auto;
      }

    }
  }

  // booking calendar
  .calendar-square-loader {
    height: rem(100);
  }

  .modal-send-invoice {
    .modal-header {
      padding-left : rem(40);
      padding-right: rem(40);
    }

    .modal-body {
      padding-left : rem(40);
      padding-right: rem(40);
    }
  }


  // Manage my business General information
  .b-business-info {
    .business-content-left-info {
      padding-right: rem(10);
    }

    .business-content-right-info {
      padding-left: rem(10);
      &.facebook-sync-has-no-left-space {
        width: 50%;
        padding-right: rem(23);
      }
    }
  }

  // end Manage my business General information
  // when booking list side view opened ====  Booking calendar page
  .custom-page-booking-calendar {
    .dropdown-select--custom {
      width: rem(150);
    }
  }
  // when booking list side view opened ====  Booking calendar page

  // UPGRADE TO PRO PAGE //////////////////////
  .update-main-layout{
    min-height: 100vh;
    .page-upgrade-wrapper {
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }
  // UPGRADE TO PRO PAGE END HERE
}

@media (min-width: 1024px) {

  // sticky header wrapper
  .sticky-main-header-wrapper {
    min-height: rem(46);
    height: auto;
    &--lg{
      height: rem(100);
    }
  }

  .custom-sticky-sub-header-wrapper {
    min-width: rem(46);
    min-height: rem(72);
    height: auto
  }

  .sticky-sub-header-wrapper {
    min-height: rem(75);
    height: auto;
  }

  .feedback-sticky-sub-header-wrapper {
    min-height: rem(46);
  }

  .is-header-sticky {
    left : rem(95);
    width: calc(100% - 95px);
  }

  .side-menu-open {
    .is-header-sticky {
      left : rem(265);
      width: calc(100% - 255px);
    }
  }

  .b-page-sub-header {
    width: 100%;
  }

  .b-page-sub-header.is-header-sticky {
    padding: rem(15) rem(30);
  }

  // for sticky header
  .is-header-sticky {
    background    : white;
    display       : block;
    position      : fixed;
    top           : rem(114);
    right         : 0;
    z-index       : 1019;
    padding-bottom: rem(20);
    border-bottom : rem(1) solid $border-color;
    box-shadow    : rem(0) rem(6) rem(14) rgba(206, 211, 218, 0.2);
  }

  .sticky-main-header {
    margin-bottom: rem(20);

    &.is-header-sticky {
      top          : rem(68);
      margin-bottom: 0;
      padding      : rem(15) rem(30) 0;
      border       : none;
    }
  }

  // end for sticky header

  // payment react select
  .custom-page-payment-list {
    .btn-advance-search-close {
      right: rem(-15);
    }

    .page-payment-list-header.is-header-sticky {
      box-shadow: none;
      z-index   : 1019;
    }

    .b-page-payment-sub-header.is-header-sticky {
      top: rem(132);
    }

    // .b-page-sub-header.is-header-sticky {
    //   top: rem(127);
    // }
  }

  // .page-payment-list-header.is-header-sticky {
  //   box-shadow: none;
  //   z-index   : 1032;
  // }

  .b-page-sub-header.is-header-sticky {
    top: rem(127);
  }

  // end payment react select

  // for placement listing page table header only shows in sticky header
  .sticky-table-header {
    display : none;
    height  : rem(40);
    overflow: hidden;

    tbody {
      @include visibility(hidden);
    }

    table.rounded {
      border-radius: 0 !important;
      box-shadow   : none !important;

      thead tr th {
        height: rem(40);

        &:first-child,
        &:last-child {
          @include border-radius(0);
        }
      }
    }
  }

  .side-menu-open .is-header-sticky-activated .b-page-sub-header {
    width: calc(100% - 280px);
  }

  .is-header-sticky-activated {
    .listing-placeholder-loader {
      padding-left: rem(15);
    }

    .page-sub-header-data {
      padding-left: rem(15);
    }

    .b-right-button {
      padding-right: rem(15);
    }

    .b-page-sub-header {
      box-shadow: rem(5) 0 rem(5) rem(-8) rgba(0, 0, 0, 0.7), 0 0 0 rem(-8) rgba(0, 0, 0, 0.7);
    }

    .sticky-table-header {
      display: block;
      width  : calc(100% - 60px);
    }

    .wrap-sticky-table {
      border-top  : rem(1) solid $border-color;
      border-left : rem(1) solid $border-color;
      border-right: rem(1) solid $border-color;

      .table-responsive-lg {
        box-shadow: rem(5) 0 rem(5) rem(-8) rgba(0, 0, 0, 0.7), 0 0 0 rem(-8) rgba(0, 0, 0, 0.7);
      }
    }
  }

  // end placement listing page table

  // contact page sticky header
  .custom-page-contact-list {
    .b-page-sub-header.is-header-sticky {
      top: rem(132);
    }

    .page-contact-list-header.is-header-sticky {
      z-index: 1019;
    }
  }

  .page-contacts-group {
    .page-payment-group-header.is-header-sticky {
      z-index: 1019;
    }
  }

  // end contact page sticky header

  .contact-overflow-x-scrollbar {
    @include scrollbar;
    overflow: hidden;
    overflow-x:auto;
  }

  // UPGRADE TO PRO PAGE //////////////////////
  .update-main-layout{
    min-height: 100vh;
    .page-upgrade-wrapper {
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }
  // UPGRADE TO PRO PAGE END HERE

}