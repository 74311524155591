.action-btn-icons{
    svg {
        opacity: 0.5;
    }
    &:disabled {
        @include opacity(1);
    }
}

.icon-hours-visible {
    svg path {
        fill: $grey-variant;
    }
}