@include media-breakpoint-up(md) {
    .right-header-wrap {
        .notification-wrapper {
            min-width: rem(398);
            width    : rem(398);
            left     : inherit;
            right    : rem(-80);
        }

        .b-custom-notification {
            position: relative;
        }

        .notification-side-popup-wrapper {
            width    : 100%;
            position : absolute;
            top      : 50%;
            transform: translateY(-50%);

            &:before {
                position  : static;
                background: transparent;

            }

            .b-notification-side-popup {
                position     : absolute;
                max-width    : rem(340);
                margin       : 0;
                width        : 100%;
                left         : rem(-150);
                // right     : rem(376);
                box-shadow   : 0 rem(2) rem(8) rgba(206, 211, 218, 0.2);
            }
        }

    }
    .no-notification-list{
        padding: rem(40) rem(15);
        .icon-block{
            @include size(rem(60)); 
        }
    }
}