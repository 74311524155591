.border-primary-hover {
    &:hover {
        border: rem(1) solid $primary !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-auto {
    cursor: auto !important;
}

.cursor-grab {
    cursor: grab !important;
}

.gu-transit {
    cursor: grabbing !important;
}

.has-short-text {
    font-size: rem(26) !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.object-fit-contain {
    object-fit: contain !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-scrollbar {
    @include scrollbar;
    overflow: auto;
}

.overflow-y-scrollbar {
    @include scrollbar;
    overflow: hidden;
    overflow-y: auto;
}

.overflow-x-scrollbar {
    @include scrollbar;
    overflow: hidden;
    overflow-x: auto;
}

.max-height-100 {
    max-height: rem(100) !important;
}

.max-height-200 {
    max-height: rem(200) !important;
}

.max-height-300 {
    max-height: rem(300) !important;
}

.max-height-50vh {
    max-height: 50vh !important;
}

.max-height-100vh {
    max-height: 100vh !important;
}

.min-h-100 {
    min-height: 100% !important;
}

.line-height-normal {
    line-height: normal !important;
}

.height-auto {
    height: auto !important;
}

.height-50 {
    height: rem(50) !important;
}

.height-100 {
    height: rem(100) !important;
}

.height-200 {
    height: rem(200) !important;
}

.height-250 {
    height: rem(250) !important;
}

.h-auto {
    height: auto !important;
}

.width-auto {
    width: auto !important;
}

.width-50 {
    width: rem(50) !important;
}

.width-100 {
    width: rem(100) !important;
}

.width-125 {
    width: rem(125) !important;
}

.width-150 {
    width: rem(150) !important;
}

.width-200 {
    width: rem(200) !important;
}

.width-250 {
    width: rem(250) !important;
}

.width-300 {
    width: rem(300) !important;
}

.width-100-percentage {
    width: 100% !important;
}

// Text decoration
.text-decoration-line-through {
    text-decoration: line-through;
}

//types
.font-weight-bolder {
    font-family: $headings-primary-font-family !important;
    font-weight: normal !important;
}

.font-weight-bold {
    font-family: $headings-font-family !important;
    font-weight: normal !important;
}

.font-weight-light {
    font-family: $font-weight-light !important;
    font-weight: normal !important;
}

.font-weight-regular {
    font-family: $font-weight-regular !important;
    font-weight: normal !important;
}

.font-family-base {
    font-family: $font-family-base !important;
}

.gray-600 {
    color: $gray-600 !important;
}

.primary-font {
    font-family: $primary-font !important;
}

.primary-font-light {
    font-family: $primary-light-font !important;
}

.primary-font-bold {
    font-family: $primary-bold-font !important;
}

.secondary-font {
    font-family: $primary-light-font !important;
}

.secondary-font-medium {
    font-family: $secondary-medium-font !important;
}

.secondary-font-bold {
    font-family: $secondary-bold-font !important;
}

.secondary-font-light {
    font-family: $secondary-light-font !important;
}

.tertiary-font {
    font-family: $tertiary-font !important;
}

.light-text-color {
    color: $light-text-color !important;
}

.light-gray {
    color: $gray-light !important;
}

//margin additonal
//top
.mt-6 {
    margin-top: rem(6) !important;
}

.mt-7 {
    margin-top: rem(7) !important;
}

.mt-8 {
    margin-top: rem(8) !important;
}

.mt-9 {
    margin-top: rem(9) !important;
}

//bottom
.mb-6 {
    margin-bottom: rem(6) !important;
}

.mb-7 {
    margin-bottom: rem(7) !important;
}

.mb-8 {
    margin-bottom: rem(8) !important;
}

.mb-9 {
    margin-bottom: rem(9) !important;
}

//top
.top-0 {
    top: 0 !important;
}

.top-1 {
    top: rem(1) !important;
}

.top-2 {
    top: rem(2) !important;
}

.top-3 {
    top: rem(3) !important;
}

.top-4 {
    top: rem(4) !important;
}

.top-5 {
    top: rem(5) !important;
}

.top-10 {
    top: rem(10) !important;
}

//top minus
.top-minus-1 {
    top: rem(-1) !important;
}

.top-minus-2 {
    top: rem(-2) !important;
}

.top-minus-3 {
    top: rem(-3) !important;
}

.top-minus-4 {
    top: rem(-4) !important;
}

.top-minus-5 {
    top: rem(-5) !important;
}

.top-minus-10 {
    top: rem(-10) !important;
}

//bottom
.bottom-0 {
    bottom: 0 !important;
}

.bottom-1 {
    bottom: rem(1) !important;
}

.bottom-2 {
    bottom: rem(2) !important;
}

.bottom-3 {
    bottom: rem(3) !important;
}

.bottom-4 {
    bottom: rem(4) !important;
}

.bottom-5 {
    bottom: rem(5) !important;
}

.bottom-10 {
    bottom: rem(10) !important;
}

//bottom minus
.bottom-minus-1 {
    bottom: rem(-1) !important;
}

.bottom-minus-2 {
    bottom: rem(-2) !important;
}

.bottom-minus-3 {
    bottom: rem(-3) !important;
}

.bottom-minus-4 {
    bottom: rem(-4) !important;
}

.bottom-minus-5 {
    bottom: rem(-5) !important;
}

.bottom-minus-10 {
    bottom: rem(-10) !important;
}

//left
.left-0 {
    left: 0 !important;
}

.left-1 {
    left: rem(1) !important;
}

.left-2 {
    left: rem(2) !important;
}

.left-3 {
    left: rem(3) !important;
}

.left-4 {
    left: rem(4) !important;
}

.left-5 {
    left: rem(5) !important;
}

.left-10 {
    left: rem(10) !important;
}

//left minus
.left-minus-1 {
    left: rem(-1) !important;
}

.left-minus-2 {
    left: rem(-2) !important;
}

.left-minus-3 {
    left: rem(-3) !important;
}

.left-minus-4 {
    left: rem(-4) !important;
}

.left-minus-5 {
    left: rem(-5) !important;
}

.left-minus-10 {
    left: rem(-10) !important;
}

//right
.right-0 {
    right: 0 !important;
}

.right-1 {
    right: rem(1) !important;
}

.right-2 {
    right: rem(2) !important;
}

.right-3 {
    right: rem(3) !important;
}

.right-4 {
    right: rem(4) !important;
}

.right-5 {
    right: rem(5) !important;
}

.right-10 {
    right: rem(10) !important;
}

//right minus
.right-minus-1 {
    right: rem(-1) !important;
}

.right-minus-2 {
    right: rem(-2) !important;
}

.right-minus-3 {
    right: rem(-3) !important;
}

.right-minus-4 {
    right: rem(-4) !important;
}

.right-minus-5 {
    right: rem(-5) !important;
}

.right-minus-10 {
    right: rem(-10) !important;
}

.border-color-light {
    border-color: $border-color-light !important;
}

//bg
.bg-transparent {
    background-color: transparent !important;
}

.bg-white {
    background-color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-underline-hover {
    &:hover {
        text-decoration: underline;
    }
}

//font sizes
.fz-10 {
    font-size: rem(10) !important;
}

.fz-12 {
    font-size: rem(12) !important;
}

.fz-13 {
    font-size: rem(13) !important;
}

.fz-14 {
    font-size: rem(14) !important;
}

.fz-15 {
    font-size: rem(15) !important;
}

.fz-16 {
    font-size: rem(16) !important;
}

.fz-18 {
    font-size: rem(18) !important;
}

.fz-20 {
    font-size: rem(20) !important;
}

.fz-22 {
    font-size: rem(22) !important;
}

.fz-24 {
    font-size: rem(24) !important;
}

// Grid gutter spaces
.gutter-5 {
    margin-right: -($grid-gutter-width-least/2);
    margin-left: -($grid-gutter-width-least/2);

    > .col,
    > [class*="col-"] {
        padding-right: $grid-gutter-width-least/2;
        padding-left: $grid-gutter-width-least/2;
    }
}

.gutter-10 {
    margin-right: -($grid-gutter-width-xs/2);
    margin-left: -($grid-gutter-width-xs/2);

    > .col,
    > [class*="col-"] {
        padding-right: $grid-gutter-width-xs/2;
        padding-left: $grid-gutter-width-xs/2;
    }
}

.gutter-16 {
    margin-right: -($grid-gutter-width-sm/2);
    margin-left: -($grid-gutter-width-sm/2);

    > .col,
    > [class*="col-"] {
        padding-right: $grid-gutter-width-sm/2;
        padding-left: $grid-gutter-width-sm/2;
    }
}

.gutter-20 {
    margin-right: -($grid-gutter-width-md/2);
    margin-left: -($grid-gutter-width-md/2);

    > .col,
    > [class*="col-"] {
        padding-right: $grid-gutter-width-md/2;
        padding-left: $grid-gutter-width-md/2;
    }
}

.gutter-24 {
    margin-right: -($grid-gutter-width-lg/2);
    margin-left: -($grid-gutter-width-lg/2);

    > .col,
    > [class*="col-"] {
        padding-right: $grid-gutter-width-lg/2;
        padding-left: $grid-gutter-width-lg/2;
    }
}

//shadow
.shadow-md {
    box-shadow: $box-shadow-md !important;
}

.shadow-xl {
    box-shadow: $box-shadow-xl !important;
}

.shadow-darken {
    box-shadow: $box-shadow-darken !important;
}

.shadow-lighten {
    box-shadow: $box-shadow-lighten !important;
}

//z-index
.z-index-0 {
    z-index: 0 !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.transform-none {
    transform: none !important;
}

@include media-breakpoint-up(sm) {
    .ml-minus-5 {
        margin-left: -5px !important;
    }

    .ml-minus-10 {
        margin-left: -10px !important;
    }

    .mr-minus-5 {
        margin-right: -5px !important;
    }

    .mr-minus-10 {
        margin-right: -10px !important;
    }

    .border-sm-0 {
        border: 0 !important;
    }

    .border-sm-top-0 {
        border-top: none !important;
    }

    .border-sm-right-0 {
        border-right: none !important;
    }

    .border-sm-bottom-0 {
        border-bottom: none !important;
    }

    .border-sm-left-0 {
        border-left: none !important;
    }

    .border-sm {
        border: rem(1) solid $border-color !important;
    }

    .border-sm-top {
        border-top: rem(1) solid $border-color !important;
    }

    .border-sm-right {
        border-right: rem(1) solid $border-color !important;
    }

    .border-sm-bottom {
        border-bottom: rem(1) solid $border-color !important;
    }

    .border-sm-left {
        border-left: rem(1) solid $border-color !important;
    }

    .width-sm-200 {
        width: rem(200) !important;
    }
}

@include media-breakpoint-up(md) {
    .border-md-0 {
        border: 0 !important;
    }

    .border-md-top-0 {
        border-top: none !important;
    }

    .border-md-right-0 {
        border-right: none !important;
    }

    .border-md-bottom-0 {
        border-bottom: none !important;
    }

    .border-md-left-0 {
        border-left: none !important;
    }

    .border-md {
        border: rem(1) solid $border-color !important;
    }

    .border-md-top {
        border-top: rem(1) solid $border-color !important;
    }

    .border-md-right {
        border-right: rem(1) solid $border-color !important;
    }

    .border-md-bottom {
        border-bottom: rem(1) solid $border-color !important;
    }

    .border-md-left {
        border-left: rem(1) solid $border-color !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .width-md-125 {
        width: rem(125) !important;
    }

    .width-md-150 {
        width: rem(150) !important;
    }
}

@include media-breakpoint-up(lg) {
    .border-lg-0 {
        border: 0 !important;
    }

    .border-lg-top-0 {
        border-top: none !important;
    }

    .border-lg-right-0 {
        border-right: none !important;
    }

    .border-lg-bottom-0 {
        border-bottom: none !important;
    }

    .border-lg-left-0 {
        border-left: none !important;
    }

    .border-lg {
        border: rem(1) solid $border-color !important;
    }

    .border-lg-top {
        border-top: rem(1) solid $border-color !important;
    }

    .border-lg-right {
        border-right: rem(1) solid $border-color !important;
    }

    .border-lg-bottom {
        border-bottom: rem(1) solid $border-color !important;
    }

    .border-lg-left {
        border-left: rem(1) solid $border-color !important;
    }
}

@include media-breakpoint-up(xl) {
    .has-short-text {
        font-size: rem(36) !important;
    }

    .border-xl-0 {
        border: 0 !important;
    }

    .border-xl-top-0 {
        border-top: none !important;
    }

    .border-xl-right-0 {
        border-right: none !important;
    }

    .border-xl-bottom-0 {
        border-bottom: none !important;
    }

    .border-xl-left-0 {
        border-left: none !important;
    }

    .border-xl {
        border: rem(1) solid $border-color !important;
    }

    .border-xl-top {
        border-top: rem(1) solid $border-color !important;
    }

    .border-xl-right {
        border-right: rem(1) solid $border-color !important;
    }

    .border-xl-bottom {
        border-bottom: rem(1) solid $border-color !important;
    }

    .border-xl-left {
        border-left: rem(1) solid $border-color !important;
    }
}

//borders
@include media-breakpoint-down(xs) {
    .border-xs-only {
        border: 1px solid $border-color !important;
    }

    .border-top-xs-only {
        border-top: 1px solid $border-color !important;
    }

    .border-right-xs-only {
        border-right: 1px solid $border-color !important;
    }

    .border-bottom-xs-only {
        border-bottom: 1px solid $border-color !important;
    }

    .border-left-xs-only {
        border-left: 1px solid $border-color !important;
    }

    .border-color-light-xs-only {
        border-color: $border-color-light !important;
    }

    .border-xs-only-0 {
        border: 0 !important;
    }

    .border-top-xs-only-0 {
        border-top: 0 !important;
    }

    .border-right-xs-only-0 {
        border-right: 0 !important;
    }

    .border-bottom-xs-only-0 {
        border-bottom: 0 !important;
    }

    .border-left-xs-only-0 {
        border-left: 0 !important;
    }
}

@include media-breakpoint-up(sm) {
    .border-sm {
        border: 1px solid $border-color !important;
    }

    .border-sm-top {
        border-top: 1px solid $border-color !important;
    }

    .border-sm-right {
        border-right: 1px solid $border-color !important;
    }

    .border-sm-bottom {
        border-bottom: 1px solid $border-color !important;
    }

    .border-sm-left {
        border-left: 1px solid $border-color !important;
    }

    .border-color-light {
        border-color: $border-color-light !important;
    }

    .border-sm-0 {
        border: 0 !important;
    }

    .border-top-sm-0 {
        border-top: 0 !important;
    }

    .border-right-sm-0 {
        border-right: 0 !important;
    }

    .border-bottom-sm-0 {
        border-bottom: 0 !important;
    }

    .border-left-sm-0 {
        border-left: 0 !important;
    }
}

@include media-breakpoint-up(md) {
    .border-md {
        border: 1px solid $border-color !important;
    }

    .border-md-top {
        border-top: 1px solid $border-color !important;
    }

    .border-md-right {
        border-right: 1px solid $border-color !important;
    }

    .border-md-bottom {
        border-bottom: 1px solid $border-color !important;
    }

    .border-md-left {
        border-left: 1px solid $border-color !important;
    }

    .border-color-light {
        border-color: $border-color-light !important;
    }

    .border-md-0 {
        border: 0 !important;
    }

    .border-top-md-0 {
        border-top: 0 !important;
    }

    .border-right-md-0 {
        border-right: 0 !important;
    }

    .border-bottom-md-0 {
        border-bottom: 0 !important;
    }

    .border-left-md-0 {
        border-left: 0 !important;
    }
}

@include media-breakpoint-up(lg) {
    .border-lg {
        border: 1px solid $border-color !important;
    }

    .border-lg-top {
        border-top: 1px solid $border-color !important;
    }

    .border-lg-right {
        border-right: 1px solid $border-color !important;
    }

    .border-lg-bottom {
        border-bottom: 1px solid $border-color !important;
    }

    .border-lg-left {
        border-left: 1px solid $border-color !important;
    }

    .border-color-light {
        border-color: $border-color-light !important;
    }

    .border-lg-0 {
        border: 0 !important;
    }

    .border-top-lg-0 {
        border-top: 0 !important;
    }

    .border-right-lg-0 {
        border-right: 0 !important;
    }

    .border-bottom-lg-0 {
        border-bottom: 0 !important;
    }

    .border-left-lg-0 {
        border-left: 0 !important;
    }
}

@include media-breakpoint-up(xl) {
    .border-xl {
        border: 1px solid $border-color !important;
    }

    .border-xl-top {
        border-top: 1px solid $border-color !important;
    }

    .border-xl-right {
        border-right: 1px solid $border-color !important;
    }

    .border-xl-bottom {
        border-bottom: 1px solid $border-color !important;
    }

    .border-xl-left {
        border-left: 1px solid $border-color !important;
    }

    .border-color-light {
        border-color: $border-color-light !important;
    }

    .border-xl-0 {
        border: 0 !important;
    }

    .border-top-xl-0 {
        border-top: 0 !important;
    }

    .border-right-xl-0 {
        border-right: 0 !important;
    }

    .border-bottom-xl-0 {
        border-bottom: 0 !important;
    }

    .border-left-xl-0 {
        border-left: 0 !important;
    }
}

.max-width-1000 {
    max-width: rem(1000);
}

.default-text-color {
    color: $light-text-color !important;
}

.max-width-500 {
    max-width: rem(500);
}

.max-width-200 {
    max-width: rem(200);
}

.strike-through {
    text-decoration: line-through;
}

.pe-none {
    pointer-events: none;
}
