.custom-form-group-dropdown {
    .react-datepicker__close-icon {
        //For react datepicker value close icon        
        right: rem(30);

        &:after {
            background: transparent;
            width     : auto;
            height    : auto;
            font-size : rem(20);
            color     : rgba($light-text-color, 0.5);
        }

        &:hover:after {
            color: rgba($light-text-color, 1);
        }
    }
    .react-datepicker-popper {
        z-index: 2;

        &.hide-outside-dates{
            .react-datepicker__day--outside-month{
                @include visibility(hidden);
            }
        }

        .react-datepicker {
            background: white;
            border    : none;
        }

        .react-datepicker__header {
            background: transparent;
            border    : none;
        }

        .react-datepicker__current-month {
            color: $secondary;
            margin-top: rem(8);
            // display: none;
        }

        .react-datepicker__day-name {
            width      : rem(34);
            color: $secondary;
            font-weight: 700;
            margin-top : rem(14);
        }

        .react-datepicker__day {
            width      : rem(34);
            height     : rem(34);
            line-height: rem(34);
            color: rgb(0, 0, 0);

            &:hover {
                color     : $warning;
                background: transparent;
            }
        }

        .react-datepicker__day--disabled {
            color: $text-gray-variant;
            cursor: no-drop;

            &:hover {
                color: #343a409c
            }
        }

        .react-datepicker__day--today,
        .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
            background : rgba(white, 0.3);
            color: $primary;
            font-weight: 500;
            width      : rem(34);
            height     : rem(34);
            @include border-radius(rem(4));

            &:hover {
                background: rgba(white, 0.3);
            }
        }

        .react-datepicker__day--selected {
            background: rgba(white, 0.1);
            color: $primary;

            &:hover {
                background: $primary;
                color: #fff;
            }
        }

        .react-datepicker__day--outside-month {
            color: $text-gray-variant;
            @include opacity(0.5);
            cursor  : not-allowed;
            &:hover {
                color: $text-gray-variant;
            }
        }

        .react-datepicker__day--keyboard-selected {
            background: transparent;
        }

        .react-datepicker__navigation--next,
        .react-datepicker__navigation--previous {
            border: rem(1) solid white;
            @include border-radius(rem(4));
            width : rem(33);
            height: rem(33);

            &:after {
                position    : absolute;
                content     : '';
                border      : solid white;
                border-width: 0 rem(2) rem(2) 0;
                padding     : rem(3);
                display     : inline-block;
            }

        }

        .react-datepicker__navigation--next {
            &:after {
                transform        : rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                left             : rem(10);
                top              : rem(10);
            }

        }

        .react-datepicker__navigation--previous {
            &:after {
                transform        : rotate(135deg);
                -webkit-transform: rotate(135deg);
                right            : rem(10);
                top              : rem(11);
            }

        }

        .react-datepicker .react-datepicker__triangle {
            border-top-color: $dark-gray;
            border-bottom-color: $dark-gray;

            &:before {
                border-top-color: $dark-gray;
                border-bottom-color: $dark-gray;
            }
        }

        .day-disabled {
            position      : relative;
            pointer-events: none;
            @include opacity(0.5);

            &:before {
                content : "";
                position: absolute;
                top     : 0;
                right   : 0;
                bottom  : 0;
                left    : 0;
                cursor  : not-allowed;
            }
        }

        // for months style
        .react-datepicker__header__dropdown {
            margin: rem(10) 0;
            .react-datepicker__month-dropdown,
            .react-datepicker__year-dropdown {
                width: calc(100% - 10px);
                left: rem(5);
                top: rem(60);
                background: $secondary;
                @include border-radius(0);
                padding-top: rem(20);
                .react-datepicker__month-option,
                .react-datepicker__year-option {
                    width: 20%;
                    height: rem(34);
                    margin-bottom: rem(5);
                    color: white;
                    display: inline-block;
                    font-weight: 400;
                    @include border-radius(0);
                    .react-datepicker__month-option--selected,
                    .react-datepicker__year-option--selected {
                        display: none;
                    }
                    &:hover {
                        color: $date-time-highlight; 
                        background: transparent;
                    }
                }
            }
            .react-datepicker__year-dropdown {
                .react-datepicker__year-option {
                    &:nth-last-of-type(1){
                    cursor: pointer;
                    @include opacity(1);
                    }
                }
            }
            .react-datepicker__month-read-view,
            .react-datepicker__year-read-view {
                visibility: visible !important;
            }
            .react-datepicker__month-read-view--down-arrow,
            .react-datepicker__year-read-view--down-arrow {
                border-width: rem(4);
            }
            .react-datepicker__month-read-view--selected-month,
            .react-datepicker__year-read-view--selected-year {
                color: $secondary
            }
        }
        // end for months style
    }
}