////////////////////////////////////////////
// Content loader base start
////////////////////////////////////////////

@include keyframes(contentLoader) {
  from {
    left: -150px;
  }
  to   {
    left: 100%;
  }
} 

.placeholder-loader {
  background: #eee;
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: rgba(0,0,0,0.1);
    background: -moz-linear-gradient(90deg, rgba($black,0) 0%, rgba($black,0.075) 50%, rgba($black,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba($black,0) 0%, rgba($black,0.075) 50%, rgba($black,0) 100%);
    background: linear-gradient(90deg, rgba($black,0) 0%, rgba($black,0.075) 50%, rgba($black,0) 100%);
    @include animation(contentLoader 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite);
  }
}

////////////////////////////////////////////
// Content loader base end
////////////////////////////////////////////


.transaction {
  width: 150px;
  height: 30px;
  margin-left:20px;
}
.refund {
  width: 80px;
  height: 30px;
}
.total-review-square-sm {
  float: right;
  height: 20px;
  width: 20px;
}
.media-loader {
  width: 100%;
  height: 100%;
}
.payment-card-loader {
  height: 50px;
}
.plandetails-loader {
  height: 30px;
  margin: 10px 25% 0 25%;
}
.paymentname-loader {
  height: 25px;
  margin: 0 25% 0 25%;
}
.contact-loader-md {
  height: 40px;
  width: 100px;
}
.reviewRequest-loader-md{
  height: 60px;
  width: 50%;
}
.round {
  border-radius: 50%;
  display: block;
}
.review-round{
  border-radius: 50%;
  display: block;
  float: left;
  height: 40px;
  width: 40px !important;
}
.not-round{
  border-radius: 50%;
  display: block;
  height:26px;
}
.not-desc{
  height: 10px;
  width: inherit;
  margin-bottom: 2px;
}
.not-time{
  height: 10px;
  width: 20%;
  margin-bottom: 2px;
}
.review-left{
  float: left;
  height: 40px;
  width: 20%;
}
.review-right{
  float: left;
  margin-left: 20px;
}
.review-rectangle-sm{
  height: 20px;
  width: 200px;
}
.review-rectangle-md{
  height: 20px;
  width: 400px;
}
.user-button-loader{
  margin:5% 10% 0 10%; 
  height:20px
}
.user-name-loader{
  margin-top:10px
}
.user-email-loader{
  margin-top:10px;
  width:75%;
  height:15px
}
.user-products-loader{
  width:65%;
  height:12px
}
.websitelist{
  height:250px;
  width:350px;
}
.website-buttons{
  width: 80px;
  height: 30px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-right: 15px;
}
.website-table{
  height:250px;
  width:250px;
  margin: 20px;
  padding: 20px
}
.siteurl{
  width: 220px;
  height: 20px;
  margin: 20px;
}
.sitename{
  width: 150px;
  height: 20px;
  margin: 20px;
}
.site-actions{
  width: 250px;
  height: 20px;
  margin: 20px;
}
.create-on{
  width: 250px;
  height: 20px;
}
.publish-list{
  width: 80px;
  height: 20px;
  display: block;
}
.web-button{
 display: inline-flex;;
}
.business-desc-sm{
  height: 20px;
  width: 50%;
  margin-bottom: 5px;
}
.business-desc-md{
  height: 20px;
  width: 70%;
  margin-bottom: 8px;
}
.business-desc-pic{
  margin-top: 41px;
}
.business-soc-lg{
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
}
.business-loader-contact{
  margin-bottom: 10px;
}
.business-con-sm{
  height: 10px;
  width: 60%;
  margin-bottom: 2px;
}
.business-con-md{
  height: 10px;
  width: 100%;
  margin-bottom: 2px;
}
.business-payment-loader{
  height: 10px;
  width: 30%;
  margin-right: 5px;
}
.business-cover-pic{
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
}
.business-hours-lg{
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}
.business-media-loader{
  height: 185px;
  width: 105%
}
.business-area-sm{
  height: 15px;
  width: 60%;
  margin-bottom: 4px;
}
.business-area-md{
  height: 25px;
  width: 100%;
  margin-bottom: 6px;
}
.business-menu-sm{
  height: 25px;
  width: 20%;
}
.business-menu-md{
  height: 40px;
  width: 20%;
  margin-bottom: 10px;
}
.business-menudes-sm{
  margin-top: 15px !important;
  height: 25px;
  width: 20%;
}
.business-menudes-md{
  height: 40px;
  width: 40%;
  margin-bottom: 20px;
}
.business-menudes-sq{
  height: 25px;
  width: 25px;
}
.service-header{
  height:30px;
  width:150px
}
.media-count-loader{
  height:25px;
  width:250px
}
.site-graph{
  width: 100%;
  height:300px;
}
.theme-list{
  width: 100%;
  height: 345px;
}
.info {
  width: 200px;
  height: 30px;
  margin: 20px
}
.social-loader{
  width: 22%;
  height: 40px;
  margin-bottom: 10px;
}
.time-loader{
  width: 100%;
  height: 40px;
}
.feed-sm-loader{
  height: 20px;
  width: 40%;
  margin-bottom: 5px;
}
.feed-md-loader{
  height: 20px;
  width: 60%;
  margin-bottom: 5px;
}
.website-weight{
  width: 90%;
  height: 20px;
  margin: 5px 10px 5px 0px;
}
.acc-lg{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;
}
.acc-md{
  width: 50%;
  height: 20px;
  margin-bottom: 5px;
}
.message-template-loader{
  height: 150px;
  width: 100px;
  margin-right: 10px;
}
.paymnet-pic-loader{
  height: 250px;
  width: 30%;
  margin-bottom: 15px;
}
.google-insights-title{
  width:150px;
}
.google-insights-amount{
  width:50px;
  margin-top:10px
}
.post-textarea{
  height: 80px;
  width: 100%;
}
.social-link{
  height:30px;
  width:250px
}
.social-title{
  height: 30px;
  width: 80%;
  margin-bottom: 6px;
}
.payment-widget-margin{
  margin-bottom: 20px;
  width: 70%;
}
.payment-widget-expDate{
  width: 80%;
}
.no-graph-data{
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  height: 253px;
}
.line-graph-loader-md{
  width: 90%;
  height: 25px;
  margin-bottom: 30px;
}
.line-graph-loader-sm{
  width: 60%;
  height: 25px;
  margin-bottom: 30px;
}
.no-line-data{
  height: 253px;
}
.pagination-count-loader{
  height: 30px;
  width: 100%;
  display: block;
}
.title-img-loader{
  height:40px !important;
}
.title-text-loader{
  height:40px;
  width:150px;
}
.pageination-loader-review{
  height: 30px;
  width: 10%;
  display: block;
}
.pageination-loader-review2{
  height: 30px;
  width: 25%;
  display: block;
}
.pageination-loader-review3{
  height: 30px;
  width: 15%;
  display: block;
}
.campaign-gragh{
  width:150px;
}
.campaign-gragh-loader{
  width:70%;
}
.campaign-graph{
  height:30px;
  width:100%;
}
.campaign-line-loader{
  width: 190px;
    margin: 40%
}
.website-line-loader{
    width: 210px;
    margin: 40% 40%
}
.dashboard-round-loader{
  margin: 10% 30%;
}
.no-sec-border{
  border: none !important;
}
.messenger-loader{
  background-color: #fff !important;
}
.messenger-user-loader{
  @include size(rem(40));
  display: block; 
  @include border-radius(50%);
  position: absolute;
  top: 0;
  z-index: 100;
  left: 0;
   border:none !important;
}
.right-user{
  display: block !important;
}
.max-width{
  width:100% !important;
}