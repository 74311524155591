//trail ////////
.page-trial-preview {
    .b-header {
        svg {
            width: rem(29);

            path {
                stroke: $primary;

                &:nth-last-of-type(1) {
                    stroke: $primary;
                }

                &:nth-last-of-type(2) {
                    stroke   : $primary;
                    // stroke: inherit;
                }

                // &:nth-last-of-type(4) {
                //     stroke: inherit;
                // }
            }
        }

        .title-text {
            font-size: rem(20);
            line-height: rem(28);
            font-family: $primary-bold-font; 
            letter-spacing: rem(2);
        }

        .symbol-registered {
            font-size  : rem(21);
            font-family: $secondary-light-font;
        }

        .sub-title-text {
            font-size: rem(20);
            font-family: $primary-bold-font;
            line-height: rem(32);
        }
        .btn-primary{
            letter-spacing: rem(0.35);
        }
    }    

    .sub-title {
        font-size: rem(16);
    }

    .section-trial-list {
        max-width    : rem(810);
        margin-bottom: rem(60);

        .title-text {
            font-size: rem(20);
            font-family: $primary-bold-font;
        }

        .b-trial-list {
            padding-left: rem(40);
        }

        .icon-badge {
            left     : 0;
            top      : 50%;
            transform: translateY(-50%);
        }

        .trial-list-item {
            font-size: rem(16);
        }
    }

    .section-trial-testimonial {
        max-width    : rem(850);
        padding      : rem(20);
        margin-bottom: rem(40);
        background   : $grey-background;

        .testimonial {
            font-size: rem(16);
            max-width    : rem(650);
        }

        .author-name {
            font-size: rem(14);
        }
    }

    .section-trial-connect {
        max-width    : rem(540);
    }
    .section-trial-footer {
        max-width    : rem(740);
    }
    .section-trial-connect,
    .section-trial-footer {
        margin-bottom: rem(40);

        .title-text {
            font-size: rem(20);
            font-family: $primary-bold-font;
        }
    }
    .box-title-text {
        font-size: rem(16);
        font-family: $primary-bold-font;
    }
    
    .content-para {
        font-size: rem(16);
        font-family: $primary-bold-font;
    }
    .section-trail-list-wrapper {
        max-width: rem(625);
    }
    @include media-breakpoint-up(xl) {
        .b-header {
            .sub-title-text {
                font-size: rem(24);
            }
        }
        .section-trial-testimonial {
            padding      : rem(30);
            margin-bottom: rem(60);

        }
    }
}


////end of trail page ////