@include media-breakpoint-up(md) { 
    .page-list {
        .b-search {
            width: rem(215);
        }
    }

    .add-service-modal {
        .b-partial-payment-option {
            min-width: auto;
        }
    }
}