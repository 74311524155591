////////////////////////////////////////////
// Thumbtack Integration 
////////////////////////////////////////////

.thumbtack-integration-block{
    .toggle-password-btn{
        top: rem(9);
        right: rem(15);
        @include size(rem(22));
        cursor: pointer;
        &:hover{
            svg path{
                fill: $gray-600;
            }
        }
        svg{
            @include size(100%);
            path{
                fill: $gray-500;
                @include transition(all 0.2s linear);
            }
        }
    }
    .verification-icon{
        top: rem(9);
        right: rem(45);
        @include size(rem(22));
        svg{
            @include size(100%);
            path{
                stroke: $bg-green;
            }
        }
    }
    .remove-service-btn{
        top: rem(11);
        right: rem(15);
        @include size(rem(21));
        svg{
            @include size(100%);
            display: block;
            path{
                stroke: $text-gray-variant;
                @include transition(all 0.2s linear);
            }  
        }
        &:hover svg path{
            stroke: $danger;
        }
    }
    .btn-add-new-option {
        font-size: rem(12);
        color: $secondary;
        height: auto;
        line-height: normal;
        &:hover {
            color: $primary;
        }
        .btn-icon {
            svg{
                @include size(rem(18));
                display: block;
            }
            svg path{
                stroke: $primary;
            }
        }
    } 
}