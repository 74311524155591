.send-email-sms-popup-wrapper{
    .b-image-sending{
        min-width: rem(48);
        min-height: rem(48);
        width: rem(48);
        height: rem(48);
      }
      .image {
        height: 100%;
        object-fit: cover;
    }
    .dot-separate{
        position: relative;
        display:inline-block;
        &:after{
          position: absolute;
          content:'';
          display: block;
          width:rem(2);
          height:rem(2);
          background:$light-text-color;
          top:50%;
          margin-top:rem(-1);
          margin-left:rem(-1);
        }
      }
}
