.palette-picker {
    cursor: pointer;
    .widget-color-settings{
        background-color: #f2f2f2;
        padding: rem(6);
        white-space: nowrap; 
        @include border-radius(30px); 
        display: inline-block;
    }
    .widget-color-picker {
        @include size(rem(28));
        @include border-radius(50%); 
        border: solid rem(2) $dark-gray;
    } 
    .color-picker-popover {
        position: absolute;
        z-index: 2;
        top: 100%;
    }
    .color-picker-popover-cover {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.palette-checkbox {
    @include size(rem(25));
    position: relative; 
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // &:hover input ~ .checkmark {
    //     // background-color: #2196f3;
    // }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        & ~ .checkmark {
            border-radius: 50%;
        }
        &:checked ~ .checkmark {
            // background-color: #2196f3;
            border-color: $white;
            &:after,
            &:before{
                @include visibility(visible);
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0; 
        @include size(rem(34));
        background-color: #eee;
        border: solid rem(3) $color-picker;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%; 
            border: solid white;
            border-width: 0 3px 3px 0;
            @include transform(rotate(45deg) translateX(-50%) translateY(-50%)); 
            @include size(rem(7), rem(12));
            margin-left: rem(-6);
            margin-top: rem(-1);
            @include visibility(hidden);
            @include transition(all 0.2s linear);
        }
        &:before{
            content: '';
            position: absolute;
            left: rem(-3);
            top: rem(-3);
            border: solid rem(1) #f2c117;
            @include size(calc(100% + 6px));
            @include border-radius(50%);
            @include visibility(hidden);
            @include transition(all 0.2s linear);
        }
    }
    &--size-lg{
        @include size(rem(42));
        .checkmark { 
            @include size(rem(42));
            border: solid rem(5) $color-picker;
            &:after {   
                @include size(rem(7), rem(14)); 
            }
            &:before{ 
                left: rem(-5);
                top: rem(-5);
                @include size(calc(100% + 10px));
            }
        }
    }
}
