.sidemenu-panel {
    min-height: calc(100vh - 108px);
    height    : calc(100vh - 108px);
    top       : rem(68);
    width     : rem(80);
    position  : fixed;
    z-index:1;
    @include transition(all 0.3s linear);
    overflow: auto;
    left:rem(-80);
    @include scrollbar;
    overflow-y        : scroll;
    -ms-overflow-style: none;
    scrollbar-width   : none;

    &::-webkit-scrollbar {
      display: none;
    }
}


.b-sidemenu {
    margin: rem(45) 0;
    .item {
        list-style   : none;
        margin-bottom: rem(30);
        position: relative;
        &:hover .text {
            color: $secondary;
        }
        a{
            padding-left:rem(10);
            white-space:nowrap;
            color:$body-color;
        }
    }
    .icons {
        vertical-align: middle;
        width : rem(30);
        height: rem(20);
        text-align:center;
        position:relative;
        margin-right:rem(10);
        .btn-icon {
            margin:0 auto;
            position: absolute;
            margin-top: rem(-2);
            width: 100%;
            // @include size(rem(20), rem(20));
            @include transform(translate(-50%, -50%)); 
            top:50%;
            left:50%; 
            max-height:rem(20);
            &.instant-site-normal-icon {
                width: rem(22);
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
    .normal-icon {
        display: block;
    }
    .icon-is-active {
        display: none;
    }
    .text {
        font-size     : rem(14);
        vertical-align: middle;
        width:0;
        overflow: hidden;
        line-height:rem(21);
        height:rem(20);
        @include transition(width 0.3s linear);
        display:inline-block;
    }

    .new-message-badge{
        background-color: $primary;
        color: $white;
        font-family: $primary-font;
        padding: rem(2) rem(6);
        @include border-radius(rem(20));
        font-size: rem(11);
        margin-left: rem(3);
    }

    .item-active {
        &:before {
            content         : '';
            position        : absolute;
            left            : 0;
            width           : rem(3);
            height          : rem(15);
            top             : 50%;
            margin-top      : -(rem(7.5));
            background-color:$primary;
            @include border-radius(rem(32));
        }
        .normal-icon {
            display: none;
        }
        .icon-is-active {
            display: block;
        }
        .text {
            color      : $secondary;
            font-family: $secondary-bold-font;
        }
        .instant-site-active-icon {
            width: rem(22);
            svg {
                path {
                    fill: $primary;
                }
            }
        }
    }
}

 
.main-wrapper {
    // padding-left: rem(0);
    overflow-x  : hidden;
    main.main {
        position: relative;
        @include transition(all 0.3s linear);
        left: 0;
    }
}

.side-menu-open {
    .sidemenu-panel {
        width: $sidebar-width;
        left:0;
    }
    .b-sidemenu {
        li {
            .text {
                width:calc(90% - 30px);
            }
        }
    }
    .main-wrapper {
        // overflow: hidden;
        main.main {
            left: $sidebar-width;
        }
    }
}

