@include media-breakpoint-up(md) {
    .main-footer {
        position: fixed;
        top: inherit;
        right     : 0;
        bottom    : 0;
        left      : 0;

    .footer-action-button {
        background: transparent;
    }
    }
}