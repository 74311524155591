.amount-dot {
  font-size  : rem(45);
  line-height: 0;
  top        : rem(-3);
  position   : relative;
  margin-left: rem(6);
}

.b-code-copy {
  .code-custom-wrap {
    -webkit-user-select: text; /* Chrome/Safari */    
    -moz-user-select: text; /* Firefox */   
    -ms-user-select: text;
  }
  .content-code-copy {
    background: $background-code;
  }
}

.custom-header-width {
  width: 100%;
}

.code-custom-wrap{
  font-family:$primary-font;
  line-height: rem(19);
}

.page-payment-list {
  .dropdown-select .dropdownSelect__control {
    height: rem(38);
  }

  .icon-export {
    width: rem(18);

    svg {
      width: 100%;

      path {
        stroke: $secondary;
      }
    }
  }
  .b-advance-search {
    z-index: 9;
  }
  .btn-advance-search {
    font-size: rem(12);
    line-height: rem(14);
  }

  .view-icon-arrow {
    @include transform(rotate(-90deg)); 
  }
  .search-field{
    padding-right: rem(40);
  }
  .icon-bank-transfer-logo {
    @include size(rem(24), rem(28));
  }
}

.sub-text span {
  font-family : $primary-bold-font;
  color       : $secondary;
  font-size   : rem(26);
  line-height : 1;
  margin-right: 0;
}

.widget-title {
  padding-left: rem(20);
} 

.icon-border {

  svg,
  img {
    max-width : rem(20);
    max-height: rem(20);
  }
}

.pointer {
  cursor: pointer;
}

.field-truncate {
  max-width: rem(140);
}

.export-dropdown {
  width     : rem(300);
  height    : auto;
  text-align: center;

  h2 {
    font-size: rem(16);
  }

  .header {
    border-bottom: solid rem(1) $border-color;
    padding      : rem(10);
  }

  .content {
    padding: rem(10);
  }
  .icon-popover-close {
    font-size: rem(28);
    top: rem(-10);
    right: 0;
    @include opacity(0.5);
  }
  .receipt {
    width: rem(28);
  }
  .download-receipt {
    width: rem(21);
  }
}

.btn-resend-receipt {
  color: $secondary;
  border: rem(1) solid $border-color;
  &:hover {
    color: $primary;
    border: rem(1) solid $primary;
  }
}

.select-dropdown {
  width    : 100%;
  font-size: rem(12);
}

.b-receipt-item {
  text-align: center;

  .receipt-img {
    width : rem(36);
    height: rem(38);
  }
}

.recept-item {
  cursor: pointer;
  border: rem(1) solid $border-color;
  @include transition(all 0.3s linear);
  @include border-radius(rem(4));
  &:hover {
    border: rem(1) solid $primary;
  }

  &.active {
    border: rem(1) solid $primary;
  }
}

.outline-icon {
  width         : 1.875rem;
  height        : 1.875rem;
  display       : inline-block;
  vertical-align: middle;
  margin-left   : 0.3125rem;
  cursor        : pointer;
  position      : relative;
  border        : 0.0625rem solid #dbe3eb;
  border-radius : 0.25rem;

  .mob-icon,
  .email-icon,
  .code-icon {
    display   : inline-block;
    max-height: rem(15);
    position  : absolute;
    top       : 50%;
    left      : 50%;
    transform : translate(-50%, -50%);
    @include opacity(0.7);
  }
}

.subHeader {
  color        : $body-color;
  margin-bottom: rem(12);
}

.cardText {
  color        : $secondary;
  margin-bottom: rem(0);
  font-size    : rem(16);
}

.receipt {
  width : rem(36);
  height: rem(38);
}
// create widget modal
.create-widget-modal-wrapper {

  .color-picker-popover-cover {
    top   : 0;
    right : 0;
    bottom: 0;
    left  : 0;
  }
  .create-widget-modal {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    .custom-close-btn {
      top    : rem(10);
      z-index: 1;
    }

    .widget-color-settings {
      width        : auto;
      background   : $bg-list;
      border       : rem(1) solid $border-color;
      box-sizing   : border-box;
      border-radius: rem(100);
      margin       : rem(0) rem(20) rem(20) rem(0);
      display      : inline-flex;
    }

    .widget-color-picker {
      width        : rem(32);
      height       : rem(32);
      border-radius: 50%;
      border       : rem(1) solid $border-color;
    }

    .color-picker-label {
      display      : inline-block;
      margin-bottom: rem(0);
    }

    .btn-icon-image {
      max-height: rem(10);
    }

    .option-delete-btn {
      right    : rem(10);
      top      : 50%;
      transform: translateY(-50%);
    }

    .color-picker-popover {
      z-index: 2;
      left   : 0;
      bottom: 100%;
      top    : inherit;
    }

    .color-picker-cover {
      top   : rem(0);
      right : rem(0);
      bottom: rem(0);
      left  : rem(0);
    }
  }
  .widget-modal-textarea {
    min-height: rem(64);
    resize: vertical;
  }
  .btn-add-new-option {
    font-size: rem(12);
    color: $secondary;
    &:hover {
      color: $primary;
    }
  }
}
// end create widget modal

.custom-icon {
  max-height: rem(15);
  max-width: rem(15);
  &:hover{
    svg path{
      fill: $primary;
    }
  }
}

.copy-icon-position {
  top  : 0;
  right: 0;
}

.copy-icon-position {
  overflow-wrap: break-word;
  .custom-icon-replicate{
    max-width: rem(15);
    svg path{
      fill: $darker-gray;
    }
    &:hover{
      svg path{
        fill: $primary;
      }
    }
  }
}

.create-widget-modal {
  .pay-button-width{
    min-width: rem(160);
  }
  .b-scroll-box {
    padding   : rem(20);
    // max-height: calc(100vh - 210px);
    // overflow-y: auto;
    // overflow-x: hidden;
    // @include scrollbar;
  }

  .widget-color-settings {
    cursor: pointer;
    width        : auto;
    background   : $bg-list;
    border       : rem(1) solid $border-color;
    box-sizing   : border-box;
    border-radius: rem(100);
    margin       : rem(0) rem(20) rem(20) rem(0);
    display      : inline-flex;
  }

  .widget-color-picker {
    width        : rem(35);
    height       : rem(35);
    border-radius: 50%;
    border       : rem(1) solid $border-color;
  }

  .color-picker-label {
    display      : inline-block;
    margin-bottom: rem(0);
  }
  .action-button-arrow {
    width           : rem(25);
    height          : rem(25);
    top             : rem(4);
    cursor: pointer;
    border          : solid rem(1) $dark-ash-color;
    border-radius   : rem(10);
    vertical-align  : middle;
    text-align      : center;
    display         : inline-block;
    margin          : rem(5) rem(3) rem(0) rem(0);
    &:hover {
      .btn-custom-color {
        width: rem(13); 
        svg path {
          fill: $primary
        }
      }
    }
  }
  .action-button {
    top             : rem(4);
    width           : rem(20);
    height          : rem(20);
    cursor: pointer;
    border          : solid rem(1) $dark-ash-color;
    border-radius   : rem(10);
    vertical-align  : middle;
    text-align      : center;
    display         : inline-block;
    margin          : rem(5) rem(3) rem(0) rem(0);
    background: white;
    @include transition(all 0.3s linear);

    .btn-custom-color {
      width: rem(8); 
      height: rem(8); 
      svg path {
        fill: $dark-ash-color;
      }
    }
  }
  .action-button {
    &:hover {
      background: $folder-active-color;
      border          : solid rem(1) $folder-active-color;
      box-shadow: 0 rem(6) rem(10) rgba(8,178,250,.38);
      .btn-custom-color {
        svg path {
          fill: white;
        }
      }
    }
  }

  .btn-icon-image {
    max-height: rem(10);
  }

  .btn-arrow-rotate {
    max-height: rem(10);

    svg {
      transform        : rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }

  .customform-color {
    min-height: rem(44);
    height: auto;
    line-height: rem(28);
    background-color: $bg-list;
  }

  .btn-custom-color-select {
    width: rem(12);

    svg path {
      fill: $select-icon;
    }
  }
  .btn-add-custom-form {
    font-size: rem(12);
    color: $secondary;
    border: rem(1) solid $border-color;
    &:hover {
      color: $primary;
      border: rem(1) solid $primary;
    }
  }
  .add-icon-color {
    svg {
      path:nth-of-type(1) {
        stroke: $primary;
      }

      path:nth-of-type(2) {
        stroke: $primary;
      }

      path:nth-of-type(3) {
        stroke: $primary;
      }
    }
  }

  .trash-custom-color {
    svg {
      path:nth-of-type(1) {
        stroke: $danger;
      }

      path:nth-of-type(2) {
        stroke: $danger;
      }

      path:nth-of-type(3) {
        stroke: $danger;
      }

      path:nth-of-type(4) {
        stroke: $danger;
      }
    }
  }

  .option-delete-btn {
    right    : rem(10);
    top      : 50%;
    transform: translateY(-50%);
  }

  .color-picker-popover {
    position: absolute;
    z-index : 2;
    top     : rem(10);
  }

  .color-picker-cover {
    top   : rem(0);
    right : rem(0);
    bottom: rem(0);
    left  : rem(0);
  }
  .btn-cancel-modal {
    min-width: rem(170);
    border: rem(1) solid $border-color;
    &:hover {
      color: $primary;
      border: rem(1) solid $primary;
    }
  }
}
.b-alignment-dropdown-select {
  .btn-alignment-dropdown-select {
    z-index: 1;
    background: transparent;
  }
  .btn-dropdown-select-icon {
      position: absolute;
      @include caret-down();
      top: 50%;
      right: rem(15);
      @include transform(translateY(-50%)); 
      @include opacity(0.5);
  }
}

.parent {
  .search-select {
    .globalSelect__dropdown-indicator {
      //background-image: none;
      background: url("https://dufzo4epsnvlh.cloudfront.net/image/icon-new/search-icon.svg") no-repeat;
    }

    svg {
      display: block;
    }
  }
}

.create-button-width {
  min-width:rem(160);
}

.trash-table-container {
  max-height:60vh;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  padding-bottom: rem(50);
  .b-modal-footer {
    bottom: rem(30);
    right: rem(30);
    left: rem(30);
  }
}

.add-transaction-form {
  .add-form-group {
    .add-transaction-textarea {
      height: rem(66);
    }
  }
  .service-link{
font-size: rem(12);
color: $icon-dark;
font-family: $secondary-bold-font;
font-weight: 400;
cursor: pointer;
&:hover{
  color: $primary;
}
  }

  .custom-cancel-btn {
    width: auto;
  }

  .highlight {
    font-size: rem(12);
  }

  .custom-datepicker {
    height: rem(46);
  }

  .custom-time-dropdown {
    >div {
      background: transparent;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .card-loader{
    .loader-img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
    background-color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-61%);
    width: 100%;
}
.card-loader-hidden{
    display: none;
    transition: all 2500ms linear;
}
}

.spinner {
  max-width: rem(15);
}

.status-pending {
  color: #8290A1;
}

.status-overdue-icon {
  fill: #EA4335;
}

.status-message {
  vertical-align: middle;
}

.status-date-message {
  vertical-align: middle;
  color: #8290A1;
}




.invoice-unlocked-list {
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0px;
  width: 100%;

  .invoice-unlocked-list-item {
    
    height: fit-content;

    .card:before {
      position: relative;
      left: 24px;
      top: 45px;
      content: "";
      height: 45px;
      width: 0;
      border-left: 1px solid #0258FF;
    }

    .line:before {
      display: none;
    }

    .card {
      display: flex;
      height: fit-content;
      width: 100%;

      
      
      .invoice-activated-title {
        font-family: $mont-bold;
        padding-left: 5px;
        font-size: 1.25rem;
        color: #082344;
      }
      
      .circle-svg {
        margin: 2rem;
        margin-left: 0;
        width: 48px;
        height: 48px;
        min-width: 48px;
        min-height: 48px;
      }

      .circle {
        border-radius: 50%;
        background: #0258FF;
        color: white;
        width: 48px;
        height: 48px;
        min-width: 48px;
        min-height: 48px;
        font-size: 1.25rem;
        line-height: 1.4rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem;
        margin-left: 0;
        font-family: "Open Sans Regular"
      }

      .info {
        display: flex;
        color: white;
        justify-content: center;
        align-items: center;
        border: 1px solid #DEE4E7;
        border-radius: 6px;
        background: #0258FF;
        height: max-content;
        flex-basis: 100%;
        padding: 1rem;
        min-width: 0;
        
        .icon {
          min-width: 60px;
          min-height: 60px;
          margin-right: 1.5rem; 
          color: white;
          justify-self: flex-start;
        }
        
        .title {
          font-family: $mont-bold;
          font-size: 1.5rem;
          font-weight: 800;
          padding-bottom: .5rem;
        }
        
        .text {
          font-family: "Open Sans Regular";
          font-size: 1rem;
          font-weight: 400;
          flex-wrap: wrap;
        }
        .btn-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          
          .button {
            width: 100%;
            min-width: 204px;
            height: 2.5rem;
            background: white;
            color: #0258FF;
            align-self: center;
            font-size: .875rem;
            font-family: "Open Sans Regular";
            line-height: .875rem;
            font-weight: 700;
            border-radius: 4px;
            text-decoration: none;
            letter-spacing: 0.35px;
            white-space: nowrap;
            border: none;
            margin-bottom: 5px;  
          }
          
          .button-tag {
            font-size: 12px;
            line-height: 18px;
            color: white;
          }
          .button:disabled {
            opacity: 0.65;
            box-shadow: none;
            cursor: not-allowed
          }
        }
        @supports (display: flex) {
          @media screen and (max-width: 767px) {
            .icon {
              margin: 0;
              margin-bottom: 1rem;
            }

            .btn-container {
              margin: 0;
            }
          }
        }
        
      }

    }
  }
}


.payment-landing-container {
  margin: 1.5rem 2.62rem 0 1rem;
  height: 85%;
}



.payment-landing-page {
  width: 100%;
  height: 100%;
  border: 1px solid #DBE3EB;
  border-radius: 6px;
  margin-top: 20px;
  margin-left: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  box-shadow: 0 3px rgba(206, 211, 218, 0.45);
  
  .landing-main {
    display: inline-flex;
    height: auto;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5rem 7.375rem 7.375rem 7.375rem;

    .header {
      text-align: center;
      padding-bottom: 45px;  
    }

    .card {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 2.5rem 1.25rem 0;
      overflow: hidden;


      .top-circle {
        position:relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3rem;
        min-width: 3rem;
        border-radius: 50%;
        background: #0258FF;
        color: white;
        margin-left: 3rem;
        font-size: 20px;
        font-weight: 700;
        font-family: "Open Sans Regular"
      }

      .bottom-circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3rem;
        min-width: 3rem;
        border-radius: 50%;
        background: #0258FF;
        color: #082244;
        margin-left: 3rem;
        font-size: 20px;
        font-weight: 700;
        font-family: "Open Sans Regular"
      }

      .top-circle:before  {
        border: 1px solid #CDD2D8;
        position: absolute;
        top: 3rem;
        left:1.5rem;
        height:6.87rem;
        width: 0;
        content: ""; 
      }

      .bottom-circle:before  {
        border: 1px solid #CDD2D8;
        position: absolute;
        top: -3.5rem;
        left:1.45rem;
        height:3.5rem;
        width: 0;
        content: ""; 
      }

      .info {
        margin-left: 1.25rem;
        border: 1px solid #DBE3EB;
        border-radius: 6px;
        background: #0258FF;
        color: white;
        padding: 1.25rem;
        display: flex;
        height: 9.375rem;
        width: 40rem;

        .icon {
          min-width: 60px;
          min-height: 60px;
          margin-right: .625rem;
          margin-left: .625rem;
        }

        .text-block {
          padding: .625rem;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 1.25rem;
            font-weight: 800;
            font-family: $mont-bold;
            line-height: 1.75rem;
            margin-bottom: 10px;
          }

          .text {
            font-size: 1rem;
            line-height: 1.4rem;
            font-weight: 400;
            // padding: 10px 50px 0 0;
          }
        }

        .button {
          width: 40%;
          min-width: 195px;
          height: 2.5rem;
          background: white;
          color: #0258FF;
          align-self: center;
          font-size: .875rem;
          font-family: "Open Sans Regular";
          line-height: .875rem;
          font-weight: 700;
          border-radius: 4px;
          text-decoration: none;
          border: none;
        }
      }
    }
  }
}

.get-paid-popup-inactive-container {
  background: #F7F9FB;
  padding-top: 0.625rem;
  padding-bottom: 0.375rem;
  border-radius: 8px;
  margin-right: 1rem;
}

.get-paid-popup-inactive-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

}

.get-paid-popup-activate-payments {
  display: flex;
  flex-direction: column;
  align-items: center;

  .activate-payments-btn {
    width: 30%;
    min-width: 233px;
    height: 2.5rem;
    color: white;
    background: #0258FF;
    align-self: center;
    font-size: .875rem;
    font-family: Open Sans Regular;
    line-height: .875rem;
    font-weight: 700;
    border-radius: 4px;
    text-decoration: none;
    letter-spacing: 0.35px;
    white-space: nowrap;
    border: none;
    margin-bottom: 0.5rem;
  }

  .activate-payments-btn:disabled {
    opacity: 0.65;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.invoice-preview-container {
  width: calc( 100% + 3.75rem);
  margin-top: 1.875rem;
  margin-left: -1.875rem;
  margin-bottom: -1.875rem;
  background: #F7F9FB;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.875rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    .title {
      font-family: $mont-bold;
      font-size: 1.25rem;
      color: #082244;
    }

    .icon-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .link {
        font-family: "Open Sans Regular";
        font-size: 0.875rem;
        font-weight: 700;
        color: #0258FF;
        margin-left: 0.3125rem;
        line-height: 1.225rem;
      }
    }
  }
  .preview-main {
    background: white;
    width: 100%;
    height: 100%;
    padding: 1.765rem;
    overflow: auto;
    border: .88px solid #DBE3EB;
    border-radius: 6px;
    box-shadow: 0px 2.64844px 5.29688px rgba(206, 211, 218, 0.45);


    .main-header {
      margin-bottom: 1.25rem;

      .head {
        font-family: "Open Sans Regular";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 0px;
        text-align: left;
        color: #082244;
        margin-bottom: 8px;
      }
      .sub {
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.0625rem;
        letter-spacing: 0px;
        text-align: left;

      }
    }

    .bill-to {
      font-family: "Open Sans Regular";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
      letter-spacing: 0px;
      text-align: left;
      color: #082244;

      .head {
        padding-bottom: 0.625rem;
      }

      .contact {
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.0625rem;
        letter-spacing: 0px;
        text-align: left;
        padding-bottom: 1.75rem;
        border-bottom: 0.88px solid #DBE3EB;
        color: #576C77;
      }
    }

    .item-list {
      display: flex;
      flex-direction: column;
      padding-bottom: 0.625rem;

      .item-title {
        padding-top: 1.125rem;
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans Regular";
        font-size: 0.77rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0px;
        color: #082244;
        margin-bottom: 1.125rem;
      }

      .item-count {
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75rem;
        letter-spacing: 0px;
        margin-bottom: 1.125rem;
      }

      .item-price {
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75rem;
        letter-spacing: 0px;
        padding-bottom: 0.875rem;
        border-bottom: 0.88px solid #DBE3EB;
      }
    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.88px solid #DBE3EB;
      font-size: 0.77rem;
      padding-bottom: 0.625rem;

      .number {
        font-family: "Open Sans Regular";
        font-size: 0.77rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0px;
        color: #082244;
      }
    }

    .total {
      display: flex;
      justify-content: flex-end;
      font-family: "Open Sans Regular";
      font-size: 0.77rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0px;
      color: #082244;
      padding-top: 1.125rem;

      .title {
        margin-right: 1.75rem;
      }
    }
  }
}

@supports (display: flex) {
  @media screen and (max-width: 575px) {
    .invoice-preview-container {
      width: calc( 100% + 2rem);
      margin-top: 1rem;
      margin-left: -1rem;
      margin-bottom: -1rem;
    }
  }
}





.page-payment-trial-preview {
  .b-header {
    // svg {
    //     width: rem(24);

    //     path {
    //         stroke: $primary;

    //         &:nth-last-of-type(1) {
    //             stroke: inherit;
    //         }

    //         &:nth-last-of-type(3) {
    //             fill  : $primary;
    //             stroke: inherit;
    //         }

    //         &:nth-last-of-type(4) {
    //             stroke: inherit;
    //         }
    //     }
    // }

    .title-text {
      font-size: rem(18);
    }

    .symbol-registered {
      font-size  : rem(21);
      font-family: $secondary-light-font;
    }

    .sub-title-text {
      font-size: rem(20);
    }
  }

  .section-trial-list {
    max-width    : rem(810);
    margin-bottom: rem(60);

    .title-text {
      font-size: rem(20);
    }

    // .b-trial-list {
    //     // padding-left: rem(50);
    // }

    .icon-badge {
      left     : 0;
      top      : 50%;
      transform: translateY(-50%);
    }

    .trial-list-item {
      font-size: rem(16);
    }
  }

  .section-trial-testimonial {
    max-width    : rem(850);
    padding      : rem(20);
    margin-bottom: rem(40);
    background   : $grey-background;

    .testimonial {
      font-size: rem(16);
    }

    .author-name {
      font-size: rem(14);
    }
  }

  .section-trial-connect,
  .section-trial-footer {
    max-width    : rem(740);
    margin-bottom: rem(40);

    .title-text {
      font-size: rem(20);
    }

    .sub-title {
      font-size: rem(16);
    }

    .box-title-text,
    .content-para {
      font-size: rem(16);
    }
  }

  .b-offer-price {
    max-width: rem(360);
    margin-bottom: rem(30);
    .offer-price-highlight {
      font-size: rem(48);
    }
  }
}

.get-paid-light {
  svg {
    fill: #ABB4B9;
  }
}

//payment custom widget
.payment-widget-response-wrapper {
  width    : 100%;
  max-width: rem(700);
  height   : 100vh;

  .title {
    font-size: rem(20);
    color    : rgb(79, 94, 101);
  }

  .para {
    margin-bottom: rem(30);
    color        : rgb(79, 94, 101);
    font-size    : rem(15);
  }

  .response-icon {
    width : rem(38);
    height: rem(38);
  }

  .footer-range {
    font-size: rem(14);
  }

  .response-footer {
    max-width: rem(345);

    .btn-range:nth-of-type(8),
    .btn-range:nth-of-type(9) {
      border: rem(1) solid $warning;
    }

    .btn-range {
      width      : rem(30);
      height     : rem(30);
      line-height: rem(30);

      &:nth-last-of-type(1) {
        margin-right: 0;
        border      : rem(1) solid $success;
      }

      margin-right: rem(5);
    }
  }

  .btn-range {
    border: rem(1) solid $danger;

  }
}

// payment details
.page-payment-details {
  .icon-back-arrow {
    left             : rem(3);
    transform        : rotate(90deg);
    -webkit-transform: rotate(90deg);

    svg {
      @include size(rem(12), rem(12));

      path {
        fill: $secondary;
      }
    }
  }
  .icon-link-chain {
    svg {
      @include size(rem(16), rem(16));

      path {
        fill: $secondary;
      }
    }
  }
}
// end payment details

// payment widget list
.page-payment-widget-list {
  .text-widget-name {
    max-width: rem(200);
  }
  .btn-trash {
    color: $secondary;
    font-family: $tertiary-font;
    &:hover{
      color: $primary;
      svg path {
        stroke: $primary;
      }
    }
  }
  .custom-icon-trash {
    width: rem(15);
    svg {
      width: 100%;
      path {
        stroke: $secondary;
      }
    }
  }
}
// end payment widget list

// GET paid pop up
.getpaid-card{
  cursor: pointer;
  .get-paid-icon{
    width: rem(70);
    height: rem(70);
    svg{
      width: 100%;
      height: 100%;
      path {
        @include transition(all 0.3s linear);
      }
    }
  }
  .getpaid-card-label{
     font-family:$secondary-bold-font;
     color: $secondary;
  }
  .b-method {
    border-width:2px !important;
    &:hover {
      svg path {
        fill: $primary;
      }
    }
  }
}
.get-paid-form {
  .dollar-sing {
    top: 50%;
    left: rem(15);
    transform: translateY(-50%);
  }
}
.undo{
  &:hover{
      path{
        fill:$primary;
      }
  }
}
.arrow-icon{
  &:hover{
    svg{
      path{
        fill:$primary;
      }
    }
  }
}
.b-image{
  min-width: rem(30);
  width: rem(30);
}
.b-image-sending{
  min-width: rem(48);
  width: rem(48);
}
.image {
  object-fit: cover;
}
.dot-separate{
  position: relative;
  display:inline-block;
  &:after{
    position: absolute;
    content:'';
    display: block;
    width:rem(2);
    height:rem(2);
    background:$light-text-color;
    top:50%;
    margin-top:rem(-1);
    margin-left:rem(-1);
  }
}
.character-limit {
  height    : rem(6);
  background: $bg-progressbar;
  @include border-radius(rem(10));
  overflow: hidden;

  span {
      height    : rem(6);
      background: $primary;
      @include border-radius(rem(10));
  }
}

.character-left {
  text-align : left;
  font-size  : rem(12);
  line-height: rem(22);
}

// 
.email-phone-error{
  @include border-radius(rem(3));
  font-size: rem(12);
  font-family: $secondary-bold-font;
}

.b-table-status {
  white-space: nowrap;
}

.icon-width{
  min-width:rem(24);
  margin-right:rem(10);
}

.quickbook-connected{
  background:$success-alert;
}

.btn-integration{
  white-space: nowrap;
  background:$bg-green;
  color:#fff;
  &:hover{
    color:#fff;
    @include box-shadow(0 0 3px 1px rgba(0,0,0,0.1));
  }
}
.close-icon{
  position: absolute;
  right:rem(15);
  top:rem(15);
  svg {
    max-width:rem(18);
    path{
      fill:$bg-green;
    }
  }
}
.tick-icon{
  min-width:rem(24);
  path{
    fill:$bg-green;
  }
}

.cancel-button{
  border-color:$secondary;
  &:hover{
      border-color:$primary;
      color:$primary;
  }
}
.icon-payment-error{
  width:rem(82);
  path{
    stroke:$danger;
  }
}


.b-image-block{
  width:rem(48);
  height:rem(48);
}
 
.separation-or{
  color:$gray-light;
  top:rem(-16);
  background:$white;
  padding:rem(8) rem(5);
}

$actions-icon-width: 18px;
$actions-icon-height: 18px;
$actions-send-color: $light-text-color;
 
.actions-dollar-coin {
  path {
    fill: $actions-send-color;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-send {
  path {
    stroke: $actions-send-color;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-link-chain {
  path {
    fill: $actions-send-color;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-check-tick {
  path {
    fill: $actions-send-color;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-refund-outline {
  path {
    fill: $actions-send-color;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-shop-check {
  path {
    fill: $actions-send-color;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-download {
  path {
    stroke: $actions-send-color;    
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-edit-series {
  svg {
    color: $actions-send-color;
    path {
      // fill: $actions-send-color;
      stroke: $actions-send-color;
      stroke-width: 0.5;
    }
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-edit-invoice {
  path {
    fill: $actions-send-color;    
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-cancel {
  path {
    fill: $danger;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-void {
  path {
    fill: $danger;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}

.actions-trash {
  path {
    stroke: $danger;
  }
  width: $actions-icon-width;
  height: $actions-icon-height;
}