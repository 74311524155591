.media-popup-ul {
  .media-popup-li {
    color: $secondary;
    background: $white;
    font-family: $secondary-bold-font;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    margin: rem(10) rem(5) rem(0) rem(0);
  }
  .active {
    color: $white;
    background: $primary;
  }
}
.media-manging {
  border: dashed rem(2) $border-color;
  .drop-files {
    .left-info {
      color: $secondary;
    }
    .media-upload-btn {
      font-size: rem(12);
      .media-upload-img {
        svg {
          margin-top: rem(-3);
        }
      }
    }
  }
}
.galleryImages {
  height: 60vh;
  overflow: auto;
  @include scrollbar;
  img {
    margin: auto;
  }
  .media-list {
    width: calc(50% - 20px);
    margin: 0 rem(20) rem(20) 0;
    float: left;
    height: rem(140);
    @include border-radius($border-radius);
    position: relative;
    cursor: pointer;
    display: block;
    background: $media-color;
    .queue-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: rem(6) rem(10);
      color: $media-color;
      font-size: rem(12);
      visibility: hidden;
      background: $secondary;
      overflow: hidden;
      @include text-truncate();
    }
    .video-queue-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: rem(6) rem(10);
      color: $media-color;
      font-size: rem(12);
      visibility: hidden;
      background: $secondary;
    }
    .media-figure {
      width: 100%;
      height: rem(140);
      @include border-radius($border-radius);
      overflow: hidden;
      vertical-align: middle;
      text-align: center;
      .media-icons {
        width: rem(70);
      }
      .media-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .media-doc-img {
        @include size(rem(72), rem(90));
      }
    }
    .deleteImg {
      position: absolute;
      right: rem(15);
      top: rem(15);
      visibility: hidden;
      background-color: $danger;
      width: rem(36);
      height: rem(36);
      padding: rem(0);
      svg {
        margin-top: rem(-17);
      }
    }
    &:hover {
      .deleteImg {
        visibility: visible;
      }
      .video-queue-info,
      .queue-info {
        visibility: visible;
      }
    }
    .media-list-progress {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($secondary, 0.5);
      svg {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
      }
    }
  }
  .media-list-active {
    .media-figure {
      border: solid rem(4) $primary;
      &:after {
        position: absolute;
        content: '';
        text-align: center;
        background: $primary
          url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABXSURBVHgBpZDBDQAhCAQp4Uq4EizJEuxAO7HUlUQf+9DExU34ADMJmAUD4LNoHK6Y0SUEN1NzhL2RXuB/DboM00I7Sa5v3knkh7EE0W+TRIdJkr3Kze4AGuCfI/QUrb0AAAAASUVORK5CYII=')
          no-repeat center center;
        @include border-radius(rem(15));
        width: rem(30);
        height: rem(30);
        top: rem(-15);
        right: rem(-15);
        left: auto;
        margin: 0;
        z-index: 1;
      }
    }
  }
  .video-item-play-icon {
    &:after {
      position: absolute;
      width: rem(25);
      height: rem(30);
      background: url(https://dufzo4epsnvlh.cloudfront.net/image/icon-new/play-icon.svg);
      content: '';
      left: 50%;
      top: 50%;
      margin-left: rem(-15);
      margin-top: rem(-12);
    }
  }
  .b-media-gallery {
    padding: rem(15) rem(10) 0;
  }
  .social-success-msg {
    background-color: $warningbox-bg-color;
    color: $warning;
    border-color: $warning;
    font-size: rem(13);
    font-family: $font-family-base;
    margin: rem(0 0 15 -40);
    @include border-radius($border-radius-sm);
    padding: rem(6) rem(10);
    border-width: rem(1);
    border-style: solid;
  }
}
.media-sort {
  position: absolute;
  width: 100%;
}
.medias-folders-wrapper {
  // margin-left:rem(-15);
  .media-option {
    height: auto;
  }
  .media-tab {
    border: solid rem(1) $border-color;
    display: block;
    list-style: none;
    max-height: rem(480);
    overflow: auto;
    @include scrollbar;
    .media-folder-list {
      padding: rem(10) rem(15);
      border-bottom: solid rem(1) $border-color;
      cursor: pointer;
      svg {
        float: right;
        opacity: 0.6;
        font-size: rem(15);
        &:hover {
          color: $folder-active-color;
        }
      }
      &:hover {
        color: $folder-active-color;
        .folder-list-img {
          opacity: 1;
        }
      }
    }
    .media-active-folder {
      color: $folder-active-color;
      .folder-list-img {
        opacity: 1;
      }
    }
  }
}
.media-listing {
  position: relative;
  .noImage-content {
    height: rem(350);
  }
  .sort-media-box {
    height: rem(60);
    border-bottom: solid rem(1) $border-color;
    .sort-by {
      float: right;
      width: rem(176);
      font-size: rem(12);
    }
    .media-sort-type {
      float: right;
      padding-top: rem(3);
      .media-sort-asc {
        padding: rem(0);
        opacity: 0.5;
      }
      .media-sort-asc-active {
        padding: rem(0);
        opacity: 1;
      }
      .media-sort-desc {
        display: block;
        position: relative;
        opacity: 0.5;
        top: rem(5);
      }
      .media-sort-desc-active {
        display: block;
        position: relative;
        opacity: 1;
        top: rem(5);
      }
    }
    .connect-facebook {
      svg {
        width: rem(20);
        margin-top: rem(-3);
      }
    }
    .connect-google {
      svg {
        margin-top: rem(-3);
      }
    }
  }
}

.ss-rotate-img {
  img {
    max-height: rem(40);
  }
}
.guidlines {
  &:hover {
    color: $primary;
  }
}
