@include media-breakpoint-up(xl) { 
    .main-wrapper {
        padding-left: rem(80);
    }
    .sidemenu-panel {
        left      : 0;
    }
    .side-menu-open {
        .sidemenu-panel {
            left      : 0;
        } 
        .main-wrapper {
            main.main {
                left:0;
            }
        }
    }
}
@media (min-width: 1500px) {
    .main-wrapper {
        main.main {
            left:rem(0);
        }
    }
    .side-menu-open {
        .main-wrapper {
            main.main {
                left:rem(0);
                padding-left:rem(170);
            }
        }
    }
}