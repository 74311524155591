@import '@assets/scss/tools-index.scss';

.custom-payment-header{
    padding-top: rem(8);
    @include media-breakpoint-up(md) { 
        padding-top: rem(30);
    }
}
.booking-payment-table{
    border-color: $dark-gray;
    background-color: $grey-background;
    thead tr th{
        border-bottom: rem(1) solid $border-color-light;
    }
}