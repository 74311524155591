@include media-breakpoint-up(md) { 
    .galleryImages{
        .media-list{
            width: calc(25% - 20px);
            height: rem(121);
            .media-figure{
                width: 100%;
                height: rem(121);
            }
        }
    }
    .medias-folders-wrapper{
        margin-left:rem(-15);
    }
}