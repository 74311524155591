.tooltip-icon-info {
    min-width : rem(14);
    @include size(rem(14));
    fill  : $gray-600;
}

.tooltip {
    opacity:1 !important;
    .tooltip-inner {
        background: $secondary;
        padding   : rem(10) rem(14);
    }

    .arrow::before {
        border-color: transparent;
    }
}

// for all device width < 375px tooltip visible 
@media (max-width: 374px) {
    .tooltip {
        .tooltip-inner {
            width: rem(150);
        }
    }
}