@include media-breakpoint-up(md) {
    .main-header {
        height: rem(68);

        .logo-block {
            max-width: rem(88);
            margin-left: rem(5);
            .image-logo {
                height: rem(28);
            }
        }
    }
}