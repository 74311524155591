@import '@assets/scss/tools-index.scss';

.user-contact-info {
  .row-user-contact,
  .row-user-email,
  .row-user-phone {
    height: rem(48);
  }
  .user-contact,
  .user-email,
  .user-phone {
    width: 40%;
  }
  .user-names {
    cursor: pointer;
    width: calc(60% - 40px);
    padding-right: rem(15);
    color: $secondary;
  }
  .user-email-value,
  .user-phone-value {
    width: 60%;
    input {
      color: $text-gray-variant;
      color: $secondary;
      border: 0;
      &::placeholder {
        color: $text-gray-variant;
      }
    }
  }

  .contact-avatar-wrapper {
    min-width: rem(30);
    width: rem(30);
    .contact-avatar-image {
      object-fit: cover;
      min-height: rem(30);
      max-height: rem(30);
    }
  }
}
