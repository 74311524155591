
.b-page-sub-header{
    display:block;
    .custom-form-group-dropdown{
        .dropdown-select .dropdownSelect__control{
            height: rem(40);
        }
    }
}
.page-sub-header-data{
     display:inline-block;
     h2,h3{
         margin-bottom:rem(3);
         font-family: $primary-bold-font;
     }
}

 