$font-family-base : $primary-font;

$font-weight-thin   :$primary-light-font;
$font-weight-light  :$secondary-light-font;
$font-weight-regular:$primary-font;

$headings-font-family         : $secondary-bold-font;
$headings-primary-font-family :$primary-bold-font;

$headings-color :$secondary;
$body-color     :$light-text-color; //text color
$body-bg        :$white;


$border-color      : $dark-gray;
$input-border-color: $dark-gray;



//Radius
//=======================
$border-radius-sm: rem(6);
$border-radius   : rem(4);
$border-radius-lg:rem(8);


//Shadow
//=======================
$box-shadow         : 0px 1px 3px rgba(206, 211, 218, 0.2);
$box-shadow-sm      : 0px 1px 2px rgba(206, 211, 218, 0.7);
$box-shadow-md      : 0px 2px 8px rgba(206, 211, 218, 0.2);
$box-shadow-lg      : 0px 1px 4px rgba(206, 211, 218, 0.9);
$box-shadow-xl      : 0px 3px 6px rgba(0, 0, 0, 0.12);
$dropdown-shadow    : 0px 6px 20px rgba(0, 0, 0, 0.08);
$box-shadow-darken  : 0px 1px 3px rgba(206, 211, 218, 0.8);
$box-shadow-lighten : 0px 7px 32px rgba(206, 211, 218, 0.3);


//Input height
//=======================
$input-height : rem($height-md);

//Caret width
//=======================
$caret-width: .4em;


//Customized Breakpoints
//=======================
$grid-breakpoints: (xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px //new breakpoint
);

//Modal width
//=======================
$modal-custom-xxxl: 1000px !default;
$modal-custom-xxl: 820px !default;
$modal-custom-xl : 760px !default;
$modal-custom-lg : 620px !default;
$modal-custom-md : 540px !default;
$modal-custom-sm : 440px !default;


.modal-custom-sm {
    max-width: $modal-custom-sm !important;
}

.modal-custom-md {
    max-width: $modal-custom-md !important;
}

.modal-custom-lg {
    max-width: $modal-custom-lg !important;
}

.modal-custom-xl {
    max-width: $modal-custom-xl !important;
}

.modal-custom-xxl {
    max-width: $modal-custom-xxl !important;
}

.modal-custom-xxxl {
    max-width: $modal-custom-xxxl !important;
}

// $modal-xl: 1140px !default;
// $modal-lg: 800px !default;
// $modal-md: 500px !default;
// $modal-sm: 300px !default;