@include media-breakpoint-up(lg) { 
    .no-table-below-md {
        .thead-bg {
            display: table-header-group;
        }
        .table {
            tbody {
                tr {
                    td {
                        .no-table-th {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}