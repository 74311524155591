@include media-breakpoint-up(sm) { 
    // payment widget list
    .page-payment-widget-list {
      .text-widget-name {
        max-width: rem(300);
      }
    }
    .export-dropdown {
      width     : rem(380);
    }
    .close-icon{
      position: static;
  }
}