@include media-breakpoint-up(lg) {
}

@media (min-width: 1024px) {
  // calendar header scroll on sticky
  :global {
    .rbc-time-header-content {
      .calendar-day-events-no::after {
        content: '';
      }
    }
    .is-calendar-header-sticky {
      position: fixed !important; //for overwrite utility class
      top: rem(127);
      background-color: white;
      z-index: 12;
      left: rem(96);
      width: calc(100% - 112px);
      padding-left: rem(53);
      border-bottom: rem(1) solid $border-color;
      box-shadow: rem(0) rem(6) rem(14) rgba(206, 211, 218, 0.2);
    }
    .side-menu-open {
      .is-calendar-header-sticky {
        left: rem(266);
        width: calc(100% - 282px);
      }
    }
  }
  // End calendar header scroll on sticky
}
