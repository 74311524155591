.full-form-file-upload {
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
  
.input-file-upload {
  display: none;
}
  
.choose-file-description {
  color: #0357FF;
  /* 14px/Regular */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.supported-file-formats {
  color: #082244;
  text-align: center;
  /* 12px/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-bottom: 8px;
}

.label-file-upload-updated {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-style: dashed;
  border-color: #DBE3EB;
  background-color: #ffffff;
  gap: 8px;
}

.drag-and-drop-text {
  color: #082244;
  /* 14px/Semi Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-bottom: 2px;
  margin-top: 8px;
}

.info-button {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
  
.label-file-upload-active {
  background-color: #ffffff;
}
  
.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}
  
.upload-button:hover {
  text-decoration-line: underline;
}
  
.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
  
.show-files {
  width: 95%;
  height: 95%;
  margin-top: 10%;
  margin-left: 10%;
}
  
.table-files {
  width: 100%;
  border-spacing: 30px;
}
  
.file-display-name {
  margin-bottom: 0.625rem;
  font-size: .75rem;
  color: #082344;
  font-family: "Avenir Next LT W01",sans-serif;
  display: 'inline-block'
}
  
.file-icon {
  display: 'inline-block';
  margin-left: 10%;
  cursor: pointer;
}
  