// .amount-select{
//     .amountSelectcss-1hwfws3{
//         padding: 0 10px 0 5px;
//         flex-grow: 0;
//     }
//     .amountSelectcss-1uccc91-singleValue{
//         font-size: 12px;
//     }
//     .amountSelectcss-1hb7zxy-IndicatorsContainer{
//         flex-grow: 1;
//     }
//     .amountSelectcss-tlfecz-indicatorContainer{
//         padding: 0;
//     }
//     .amountSelectcss-6q0nyr-Svg{
//         width: 15px;
//     }
// }
.amountSelect__value-container{
    &.amountSelect__value-container--has-value{
        &.css-1hwfws3 {
            padding: 0 10px 0 5px;
            flex-grow: 0;
        }
    }
    
    }
    .amountSelect__single-value{
        &.css-1uccc91-singleValue{
            font-size: 12px;
        }
    }
    .amountSelect__indicators{
        &.css-1hb7zxy-IndicatorsContainer{
            flex-grow: 1;
        }
    }
    .css-6q0nyr-Svg{
        width: 15px;
    }
    .amountSelect__indicator{
        &.amountSelect__dropdown-indicator{
            &.css-tlfecz-indicatorContainer{
                padding: 0;
            }
        }
    }