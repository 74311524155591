@include media-breakpoint-down(sm) {
  .recent-review-widget-wrapper {
    .review-list-wrapper {
      max-height: rem(420);
      overflow: hidden;
      overflow-y: auto;
      @include scrollbar;
    }
  }
  .mobile-support {
    // width:calc(100% - 70px);
  }
  .btn-mobile-height {
    height: rem(40) !important;
  }
}
