@include media-breakpoint-up(lg) {
    .right-header-wrap {

        .notification-side-popup-wrapper {
            .b-notification-side-popup {
                width    : 100%;
                max-width: rem(500);
                min-width: rem(500);
                left     : rem(-235);
            }
        }

    }
}