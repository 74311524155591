.dropdown{
    .dropdown-toggle{
        background-color: $bg-list;
        &:after{
            display:none;
        }
    }
    .dropdown-menu{
        li{
            cursor: pointer; 
            &:hover {
                background: $light-gray;
            }
        }
    }
}
.dropdown--has-focuz{
    .dropdown-toggle{
        border: rem(1) solid $border-color;
        @include transition(all 0.2s linear);
    }
    &.open{
        .dropdown-toggle{
            @include box-shadow(0 0 0 rem(1) #DAE6FF, 0 0 0 rem(4) rgba($primary, 0.25));
            border-color: $primary;
        }
    }
}

