@include media-breakpoint-only(md) { 

}

// @media (min-width: 992px) and (max-width: 1023px) {
//     .side-menu-open {
//       .is-header-sticky {
//         left : rem(265);
//         width: 100%;
//       }
//     }
// }
@media (min-width: 1024px) and (max-width: 1199px) {

    //Review Widget
    .side-menu-open {
      .page-create-review-widget {
        .custom-widget-color-picker {
          width : rem(16);
          height: rem(16);
        }
  
        .custom-lor-picker-label {
          font-size: rem(13);
        }
      }
  
      // payment react select
      .custom-page-payment-list {
        .custom-select-dropdown {
          width: rem(140);
        }
      }
    }
  
    .disable-sticky.is-header-sticky {
      @include visibility(hidden);
      // @include transition(0.1s ease); 
    }
  }


@include media-breakpoint-between(md, lg) {
    
  .custom-page-booking-calendar {
      .dropdown-select--custom {
        width: auto;
        .dropdownSelect__control {
            @include size(rem(40));
        }
        .dropdownSelect__value-container {
          padding: 0;
          opacity: 0;
        }
        .dropdownSelect__placeholder {
          display: none;
        }
        .dropdownSelect__menu {
          width: rem(180);
          left: inherit;
          right: 0;
        }
        .dropdownSelect__input input {
          opacity: 0 !important; // For overwrite inline code
        }
      }
      .dropdownSelect__value-container {
        padding-right: rem(5);
      }
      .custom-bg-color {
        background-color: $white;
        z-index: 0;
      }
      .dropdown--custom-icon{
        right: 0;
      }
      .dropdown{ 
        .manage-icon-arrow{
          width: 100%;
        }
        .dropdown-toggle{
          @include size(rem(40));
          background-color: $white;
        }
      }
      .calendar-filter-dropdown {
        background-color: transparent;
        z-index: 1;
      }
    }
}