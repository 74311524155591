@include media-breakpoint-up(lg) { 
    
}
@media (min-width: 1024px) {

    .main-wrapper {
        padding-left: rem(80); 
    }

    .sidemenu-panel.sidemenu-panel-update {
        left: 0;
    }

    .side-menu-open {
        .sidemenu-panel.sidemenu-panel-update {
            left: 0;
        }

        .main-wrapper {
            main.main {
                left        : 0;
                padding-left: rem(170);
            }
        }
    }
}