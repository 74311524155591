.custom-time-picker {
    .bootstrap-datetimepicker-widget {
        max-width : rem(266);
        width     : 100%;
        padding   : rem(10) rem(20);
        margin-top: rem(8);
        background: $secondary;
        @include border-radius(rem(6));

        &:before {
            content: '';
            position: absolute;
            top: rem(-7);
            @include caret-up();
            @include opacity(0.5);
        }
    }
    .form-control {
        height: rem(40);
        background: url(https://dufzo4epsnvlh.cloudfront.net/image/data-time.png) no-repeat calc(100% - 10px) center;
    }

    .list-unstyled {
        margin-bottom: 0;
    }

    .table-condensed {
        width: 100%;

        td {
            text-align: center;
        }

        .btn-primary {
            border     : none;
            padding    : 0 rem(10);
            font-weight: normal;
            background : $date-time-text;
            font-family: $primary-font;

            &:hover {
                background: rgba($date-time-text, 0.8);
            }
        }
    }

    .timepicker-hour,
    .timepicker-minute {
        color : $date-time-text;
        cursor: pointer;

        &:hover {
            color: $date-time-highlight;
        }
    }

    .timepicker-second {
        display: none;
    }

    .hour,
    .minute {
        color  : white;
        padding: rem(15) 0;
        cursor : pointer;

        &:hover {
            color: $date-time-highlight;
        }
    }

    .separator {
        color: white;

        &:nth-last-of-type() {
            display: none;
        }
    }

    tr:nth-last-of-type(2) {
        td:nth-last-of-type(3).separator {
            display: none;
        }
    }

    tr:nth-of-type(2) td {
        padding: rem(20) 0;
    }

    tr:nth-of-type(1) td a,
    tr:nth-last-of-type(1) td a {
        span {
            border      : solid $date-time-text;
            border-width: 0 rem(2) rem(2) 0;
            display     : inline-block;
            padding     : rem(3);
        }

        &:hover span {
            border-color: $date-time-highlight;
        }
    }

    tr:nth-of-type(1) td a span {
        transform        : rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    tr:nth-last-of-type(1) td a span {
        transform        : rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    tr:nth-of-type(1) td:nth-of-type(5) a,
    tr:nth-last-of-type(1) td:nth-of-type(5) a {
        display: none;
    }

    @include media-breakpoint-up(md) {}
}