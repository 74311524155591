@include media-breakpoint-down(md) {
    .no-table-below-md {
        .thead-bg {
            display: none;
        }

        .table {
            tbody {
                tr {
                    td {
                        display      : block;
                        width        : 100%;
                        padding      : rem(5) rem(15) rem(12);
                        border-bottom: none;
                        font-size    : rem(14);
                        font-family: $primary-font ;

                        &:first-child {
                            padding-top: rem(20); 
                        }
                        &:last-child {
                            padding-bottom: rem(20);
                            @include border-radius(0);
                            border-bottom: rem(1) solid $border-color;
                        }
 
                        .no-table-th {
                            font-size    : rem(14);
                            color  : $secondary;
                            opacity: 0.5;
                            margin-bottom: rem(3);
                            display: block;
                            font-family: $secondary-bold-font;
                        }
                    }
                }
            }
        }
    }
}