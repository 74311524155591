@include media-breakpoint-down(sm) {
  .is-enterprise-user {
    .main-wrapper {
      top: rem(80);
    }
  }

  // contact auto search
  .contact-auto-search {
    width: 100%;
  }

  //contact details activity height
  .c-details-activity-widget {
    .content {
      padding-top: rem(30);
    }
  }

}

@include media-breakpoint-down(xs) {
  .payment-page-header {
    .btn-quickbooks {
      padding: 0 rem(15) !important;

      span,
      i {
        display: none !important;
      }
    }
  }
  // /////////////////////////////////////////////
  // /////////////////////////////////////////////
  // ///////////ENTERPRISE APP////////////////////
  // /////////////////////////////////////////////
  // /////////////////////////////////////////////
  .for-custom-modal-button {
    .modal .modal-body .custom-modal-close-button {
      right: rem(5);
    }
  }
}