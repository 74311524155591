.modal {
    background: rgba(3, 35, 68, 0.75);
    -webkit-user-select: none; /* Chrome/Safari */  
    -moz-user-select: none; /* Firefox */  
    -ms-user-select: none;

    .modal-title {
        font-size  : rem(20);
        font-family: $headings-primary-font-family;
    }

    .close {
        position   : absolute;
        top        : rem(6);
        right      : rem(10);
        font-size  : rem(32);
        line-height: rem(30);
        @include opacity(0.20);
        @include transition($transition-fade);
    }

    .modal-content {
        @include border-radius(rem(8))
    }

    .remove-btn,
    .cancel-btn {
        font-size: rem(12);
    }
    .modal-custom-footer {
        flex-wrap: inherit;
    }

}

// bootstrap modal close button style 
.custom-modal-close-button{
    &:hover{
        &:before{
            animation: ripple 1s ease-out;
        }
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: rem(50);
        height: rem(50);
        margin-left: rem(-25);
        margin-top: rem(-25);
        background: $btn-close-color;
        opacity: .6;
        transform: scale(0);
        @include border-radius(50%);
    }
}
@keyframes ripple {
    0% {
        -webkit-transform: scale(0);
        -moz-transform   : scale(0);
        -ms-transform    : scale(0);
        -o-transform     : scale(0);
        transform        : scale(0);
    }

    20% {
        -webkit-transform: scale(1);
        -moz-transform   : scale(1);
        -ms-transform    : scale(1);
        -o-transform     : scale(1);
        transform        : scale(1);
    }

    100% {
        opacity          : 0;
        -webkit-transform: scale(1.3);
        -moz-transform   : scale(1.3);
        -ms-transform    : scale(1.3);
        -o-transform     : scale(1.3);
        transform        : scale(1.3);
    }
}