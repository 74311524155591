// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace { font-family: $font-family-monospace !important; }

// Alignment

.text-justify  { text-align: justify !important; }
.text-wrap     { white-space: normal !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate(); }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.btn-erase-data{
  color: red !important;
  border-color: red !important;
  font-family: "Open Sans Bold", sans-serif;
  // height: 2.5rem;
    padding: 0rem 0.9375rem;
    line-height: 2.5rem;
    border-radius: 4px;
    display: inline-block;
    font-weight: 400;
    color: #576c77;
    min-height: 2.5rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 0.875rem;
}
.warning-box-root{
  padding: 8px;
}
.warning-box{
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.warning-box-note{
  font-family: 'Open Sans Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 140%;
display: flex;
align-items: center;
text-align: center;
}
.warning-box-title{
  font-family: 'MontBold';
// font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 27px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
color: #082244;
margin-top: 33px;
margin-bottom: 12px;
}

.warning-box-link{
font-family: 'Open Sans Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 140%;
margin-top: 40px;
display: flex;
align-items: center;
text-align: center;
color: #0357FF;

flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.svg-center-align{
  display: block;
  margin: auto
}
// Weight and italics

.font-weight-light   { font-weight: $font-weight-light !important; }
.font-weight-lighter { font-weight: $font-weight-lighter !important; }
.font-weight-normal  { font-weight: $font-weight-normal !important; }
.font-weight-bold    { font-weight: $font-weight-bold !important; }
.font-weight-bolder  { font-weight: $font-weight-bolder !important; }
.font-italic         { font-style: italic !important; }

// Contextual colors

.text-white { color: $white !important; }

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

.text-body { color: $body-color !important; }
.text-muted { color: $text-muted !important; }

.text-black-50 { color: rgba($black, .5) !important; }
.text-white-50 { color: rgba($white, .5) !important; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}

.text-decoration-none { text-decoration: none !important; }

.text-break {
  word-break: break-word !important; // IE & < Edge 18
  overflow-wrap: break-word !important;
}

// Reset

.text-reset { color: inherit !important; }
