@include media-breakpoint-up(lg) {
  .sub-text span,
  .text-count {
    font-size: rem(36);
  }
  // my business widget
  .business-widget-wrapper {
    .brand-logo {
      @include size(rem(70));
    }
  }
}
