.add-service-modal {

    // /* Chrome, Safari, Edge, Opera */ for input number filed arrow remove
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    // /* Firefox */ for input number filed arrow remove
    input[type=number] {
        -moz-appearance: textfield;
    }

    .time-field {
        width: rem(44);
    }

    .is-resize-none {
        resize: none;
    }

    .widget-color-settings {
        z-index: 1;
        cursor : pointer;

        .color-picker-popover {
            z-index: 2;
            left   : rem(-118);
        }
    }

    .widget-color-picker {
        @include size(rem(24));
        min-width    : rem(24);
        min-height   : rem(24);
        border-radius: 50%;
    }

    .color-picker-label {
        display      : inline-block;
        margin-bottom: rem(0)
    }

    .image-button-border {
        border: solid rem(1) $dark-gray;

        &:hover {

            border: solid rem(1) $primary;
        }
    }

    .user-dropdown-list-item .list-item-text {
        max-width: calc(100% - 24px);
    }

    .service-image {
        @include size(rem(24));
        min-width: rem(24);

        .image-figure {
            margin-bottom: 0;
            height       : 100%;

            .image-cover {
                object-fit: cover;
                width     : 100%;
                height    : 100%;
                @include border-radius(rem(4));
            }
        }

        &.enlarge-image {
            @include size(rem(60));
        }
    }

    .b-enlarge-image {
        .service-image.enlarge-image {
            @include size(rem(150));
            bottom: 100%;
            border: rem(5) solid white;
            bottom: 100%;
            @include visibility(hidden);
            @include transition(all 0.2s linear);
        }

        &:hover {
            .service-image.enlarge-image {
                @include visibility(visible);
            }
        }
    }

    .icon-styles {
        width : rem(40);
        cursor: pointer;
    }

    .btn-trash {
        top       : rem(-6);
        right     : rem(-6);
        background: white;
        @include size(rem(20));
        border: rem(1) solid $border-color;
        @include visibility(hidden);
    }

    .image-trash {
        @include size(rem(10));

        svg {
            path {
                stroke: $danger;
            }
        }
    }

    .sp-preview {
        position             : absolute;
        left                 : 0;
        top                  : 0;
        width                : 100%;
        height               : 100%;
        -webkit-border-radius: 50%;
        -moz-border-radius   : 50%;
        -ms-border-radius    : 50%;
        -o-border-radius     : 50%;
        border-radius        : 50%;

        .sp-preview-inner {

            position        : absolute;
            width           : 28%;
            height          : 97%;
            border-radius   : 50%;
            background-color: white;

        }
    }

    .color-picker {
        display: none;
    }

    .cover {
        background-color: white;
        background-clip : padding-box;
        border          : rem(1) solid rgba(0, 0, 0, 0.15);
        border-radius   : rem(6);
        box-shadow      : 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    }

    .b-partial-payment-option {
        min-width: 100%;
    }

    .icon-export {
        width: rem(18);

        svg {
            width: 100%;

            path {
                stroke: $secondary;
            }
        }
    }

    // Updated design styles
    .b-card-item-small {
        width: 40%;
    }

    .b-card-item-large {
        width     : 60%;
        min-height: rem(44);
    }

    .accordion-card-header {
        background-color: transparent;
    }

    .card-row-item-height {
        min-height: rem(44);
    }

    .b-add-reminder-time {
        border-bottom: rem(1) solid $border-color;

        &:nth-last-of-type(1) {
            border-bottom: none;
        }

        .add-reminder-time-field {
            width: rem(45);
        }

    }

    .button-add {
        @include size(rem(14));

        svg path {
            stroke: $primary;
        }
    }

    .button-remove {
        @include size(rem(15));
        min-width: rem(15);

        svg path {
            stroke: $danger;
        }
    }

    .b-profile-image {
        &:hover {
            .btn-trash {
                @include visibility(visible);

                &:hover {
                    background-color: $danger;

                    .image-trash {

                        svg {
                            path {
                                stroke: white;
                            }
                        }
                    }
                }
            }
        }
    }

    .field-normal-width {
        width    : auto;
        max-width: rem(90);
    }

    .icon-dropdown-arrow {
        min-width: rem(16);
    }

    .private-label-text {
        color: $light-text-color;
    }

    .small-dropdown {
        min-width: rem(50);
    }

    .form-loader {
        background: rgba(white, 0.7);
    }

    .custom-placeholder-color {
        color: $secondary;
        @include placeholder($text-gray-variant, 1);
    }

    // End updated design styles

}

.edit-icon {
    svg {
        max-width: rem(14);

        path {
            stroke: "green";
        }
    }
}

.delete-icon {
    svg {
        max-width: rem(14);

        path {
            stroke: "red";
        }
    }
}

.icon-block {
    width : rem(30);
    height: rem(20);

    svg {
        width : rem(20);
        height: rem(20);
    }
}

.amount-weight {
    font-size  : rem(20);
    font-family: $primary-font;
}

.page-list {
    .b-search {
        .search-input {
            padding-right: rem(40);
        }
    }
    .service-filter { 
        @include media-breakpoint-up(xxl) { 
            margin-left: 400px;
        } 
    }
}

.toggle-text-color {
    color: $secondary;
}