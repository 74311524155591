$spacer: 0.063rem;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 1),
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    10: ($spacer * 10),
    15: ($spacer * 15),
    20: ($spacer * 20),
    25: ($spacer * 25),
    30: ($spacer * 30)
  ),
  $spacers
);

//pixel to rem
@function rem($pxsize) {
    @return ($pxsize/16)+rem;
}