//Fonts name
//==============
$open-sans-light : 'Open Sans Light', sans-serif;
$open-sans-regular : "Open Sans Regular", sans-serif;
$open-sans-medium : "Open Sans Medium", sans-serif;
$open-sans-demi-bold : "Open Sans Demi Bold", sans-serif;
$open-sans-bold : "Open Sans Bold", sans-serif;
$mont-bold : 'MontBold';
$mont-regular : "MontRegular";

//Unused fonts 
$open-sans-demi-bold: 'Open Sans Demi Bold', sans-serif !default;
$mont-semibold: 'MontSemiBold'; 