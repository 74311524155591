////////////////////////////////////////////
// Search select category Customization
////////////////////////////////////////////
.custom-react-search-select-wrapper {
  &.custom-group-select {
    .search-select {
      .globalSelect__menu-list {
        max-height: rem(200);
        @include scrollbar;
        @include border-radius(rem(4));
      }
      .globalSelect__control {
        height: auto;
      }
      .globalSelect__option {
        &:active,
        &:hover {
          background-color: $list-bg-color;
        }
      }
      .globalSelect__option--is-focused {
        background-color: $list-bg-color;
      }
      .globalSelect__value-container {
        padding: rem(11) rem(10) 0;
      }
      .globalSelect__multi-value {
        margin: 0 rem(10) rem(10) 0;
        padding: rem(3) rem(3);
        background-color: $grey-background;
        @include border-radius(rem(4));
      }
      .globalSelect__multi-value__label {
        color: $secondary;
      }
      .globalSelect__multi-value__remove {
        width: rem(30);
        background: url('https://gosite-assets.s3-us-west-1.amazonaws.com/image/activity-icons/thinny-close.svg')
          no-repeat calc(100% - 5px) center;
        @include visibility(visible);
        &:hover {
          background: url('https://gosite-assets.s3-us-west-1.amazonaws.com/image/activity-icons/thinny-close-danger.svg')
            no-repeat calc(100% - 5px) center;
        }
        svg {
          display: none;
        }
      }
      .globalSelect__control--is-focused {
        .globalSelect__input {
          &::after {
            content: '';
          }
        }
      }
      // .globalSelect__value-container--has-value{
      //     .css-b8ldur-Input {
      //         margin-left: rem(5);
      //     }
      // }
      .globalSelect__input {
        position: relative;
        &::after {
          content: '';
          color: $text-gray-variant;
          font-size: rem(14);
          font-family: $primary-font;
        }
      }
      .css-b8ldur-Input,
      .css-1g6gooi,
      .globalSelect__placeholder + div {
        margin: 0 0 rem(10);
        padding: 0;
      }
      .globalSelect__indicators {
        // display: none;
        margin-bottom: 0;
        position: relative;
        .globalSelect__indicator {
          @include visibility(hidden);
        }
        &::after {
          content: '';
          @include caret-down();
          position: absolute;
          right: rem(10);
          top: 50%;
          @include opacity(0.3);
          @include transform(translate(-50%));
          margin-top: rem(-3);
        }
      }

      .globalSelect__control--menu-is-open {
        .globalSelect__indicators {
          // display: none;
          margin-bottom: rem(14);
          .globalSelect__indicator {
            @include visibility(visible);
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
