@include media-breakpoint-up(sm) {

    // location filter
    .is-enterprise-user {
        .b-location-filter {
            width: rem(200);

            &.b-width-location-filter,
            &.b-placement-location-filter {
                width: 100%;
            }
        }
    }
    // end location filter
}