@include media-breakpoint-only(md) { 
  .is-enterprise-user {
    .b-reviews-datepicker-filter {
      max-width: rem(200);
    }
  }
}

// @media (min-width: 992px) and (max-width: 1023px) {
//     .side-menu-open {
//       .is-header-sticky {
//         left : rem(265);
//         width: 100%;
//       }
//     }
// }
@media (min-width: 1024px) and (max-width: 1199px) {

    //Review Widget
    .side-menu-open {
      .page-create-review-widget {
        .custom-widget-color-picker {
          width : rem(16);
          height: rem(16);
        }
  
        .custom-lor-picker-label {
          font-size: rem(13);
        }
      }
  
      // payment react select
      .custom-page-payment-list {
        .custom-select-dropdown {
          width: rem(140);
        }
      }
    }
  
    .disable-sticky.is-header-sticky {
      @include visibility(hidden);
      // @include transition(0.1s ease); 
    }
  }