////////////////////////////////////////////
// TinyMCE
////////////////////////////////////////////
  
.tox.tox-tinymce{
    background: white;
    box-shadow: 0 rem(1) rem(3) rgba(black, 0.3);
    @include border-radius($border-radius);
    border: none;
    .tox-editor-header{
        .tox-toolbar__primary{
            background: white none;
            box-shadow: 0 rem(1) rem(3) rgba(206, 211, 218, 0.8);
            padding: rem(10) 0;
        }
    }
    .tox-tbtn{
        margin-left:rem(1);
        margin-right:rem(1);
    }
    .tox-tbtn--bespoke .tox-tbtn__select-label {
        width: auto;
    }
}