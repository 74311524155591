.messenger-wrapper {
  overflow: hidden;

  // for messenger right block notes
  .notes-wrapper {
    top: 0;
    bottom: 0;

    .b-card-notes {
      border: none;
      box-shadow: none;
      padding-bottom: 0;
      margin-bottom: 0;

      .messenger-note-title {
        font-size: rem(14);
        font-family: $secondary-bold-font;
      }

      .btn-header-note-close {
        font-size: rem(24);
        color: $light-text-color;
      }

      .header {
        height: rem(47);
        min-height: rem(47);
        right: 0;
        left: 0;
      }
    }
  }

  // end for messenger right block notes

  .msg_active {
    background-color: $highlight-yellow;
  }

  .msg_active_focus {
    background-color: $focus-orange;
  }

  .icon-toggle {
    svg {
      width: rem(20);
    }
  }

  .conversation-list-wrapper {
    width: rem(300);
    height: calc(100vh - 202px);
    position: absolute;
    top: 0;
    left: rem(-300);
    z-index: 1033;
    background: white;
    @include transition(all 0.3s linear);
  }

  .btn-conversation-list {
    width: rem(33);
    height: rem(33);
    position: absolute;
    top: rem(30);
    right: rem(-33);
    z-index: 2;
    background: white;
    @include border-radius(rem(0) rem(4) rem(4) rem(0));
  }

  .icon-toggle-arrow {
    width: rem(20);
    height: rem(30);
    right: 0;
    display: none;
    //left  : rem(-20);

    svg {
      width: rem(12);
      @include transition(all 0.5s linear);
    }
  }

  .icon-toggle-user {
    width: rem(33);
    height: rem(33);
    display: flex;
  }

  .btn-account-details {
    position: absolute;
    top: rem(30);
    right: 0;
    z-index: 1032;
    background: white;
    @include transition(all 0.3s linear);
    @include border-radius(rem(4) rem(0) rem(0) rem(4));

    .icon-action-arrow {
      top: 0;
    }
  }

  .conversation-body {
    //width: 90%;

    .drop-section {
      outline: none;
    }

    .media-doc-img {
      @include size(rem(72), rem(90));
    }
  }

  .b-account {
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100vh - 202px);
    background: white;
    z-index: 1031;
    overflow-y: auto;
    overflow-x: hidden;
    @include visibility(hidden);
    @include transform(translateX(100%));
    @include transition(all 0.3s linear);
    @include scrollbar;
  }

  // conversation section
  .conversation-wrapper {
    height: calc(100vh - 202px);
    padding-bottom: rem(110);
    @include transition(all 0.3s linear);

    &.is-search-box-enabled {
      .conversation-content-wrapper {
        height: calc(100vh - 440px);
      }

      .compose-area-has-more-content {
        height: calc(100vh - 440px - 35px);
      }
    }

    .conversation-content-wrapper {
      height: calc(100vh - 375px);
      // overflow-y: auto;
      overflow-x: hidden;
      @include scrollbar;
    }

    .compose-area-has-more-content {
      height: calc(100vh - 375px - 35px);
    }

    .received-message {
      background: $light-gray;
      color: $secondary;
      width: calc(100% - 60px);
      @include border-radius(rem(4));

      .b-time-stamp {
        padding-left: rem(60);
        margin-right: auto;
      }
    }

    .received-message-wrapper,
    .send-message-wrapper {
      .b-time-stamp {
        width: calc(100% - 60px);
      }
    }

    .send-message-wrapper {
      .send-message {
        margin-left: auto;
      }

      .b-time-stamp {
        margin-left: auto;
      }
    }

    .received-message-wrapper {
      .send-message {
        background: $light-gray;
        margin-right: auto;
      }
    }

    .send-message {
      white-space: pre-wrap;
      background: $tertiary;
      width: calc(100% - 60px);
      color: $light-text-color;
      @include border-radius(rem(4));

      .b-time-stamp {
        padding-right: rem(60);
      }

      &.caution-error {
        border: rem(2) solid $danger;

        .strikout-msg {
          &:hover .blocked-info {
            @include visibility(visible);
          }
        }

        .blocked-info {
          top: rem(-18);
          right: rem(10);
          @include visibility(hidden);
          @include transition(all 0.3s linear);
        }
      }
    }

    .chat-profile {
      width: rem(44);
      min-width: rem(44);
      height: rem(44);
      margin-bottom: rem(27);
      background: $bg-grey;

      .profile-image {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        img {
          object-fit: cover;
        }

        .default-profile-img {
          @include size(rem(32));
          margin-top: rem(5);
        }

        .thumbtack-img {
          @include size(100%);

          path {
            fill: #8290a2;
          }
        }
      }
    }

    .time-stamp {
      font-size: rem(12);
      color: $grey-variant;
      font-family: $primary-font;
    }

    .separation-dot {
      width: rem(4);
      height: rem(4);
      background: $bg-grey;
    }

    .assigned-profile {
      width: rem(30);
      height: rem(30);
      background: $bg-grey;
    }

    .icon-assigned-profile {
      width: rem(15);
      min-width: rem(15);
      height: rem(15);

      svg path {
        fill: white;
      }
    }

    .assigned-text {
      font-size: rem(12);
      color: $grey-variant;
    }

    .date-stamp {
      font-size: rem(10);
      line-height: 14px;
      color: $grey-variant;
      border: rem(1) solid $tertiary;
      font-family: $secondary-bold-font;
    }

    .icon-chat-pc {
      width: rem(14);
      height: rem(12);
    }

    .icon-chat-mobile {
      width: rem(8);
      height: rem(12);
    }

    .icon-chat-email {
      width: rem(12);
      height: rem(9);

      svg path {
        stroke: $grey-variant;
      }
    }

    .media-attached {
      overflow: hidden;
      @include border-radius(rem(4));
    }

    .media-attached-image {
      object-fit: contain;
      max-height: rem(330);
      min-width: rem(110);
      max-width: 100%;
      @include border-radius(rem(4));
    }

    .b-attach-hover {
      &:hover .icon-attach-hover {
        @include visibility(visible);
      }
    }

    .icon-save-attach-hover {
      svg {
        width: rem(12);
        height: rem(14);

        path {
          fill: white;
        }
      }
    }

    .icon-play-arrow-attach-hover {
      svg {
        width: rem(22);
        height: rem(22);

        path {
          fill: white;
        }
      }
    }

    .icon-search-attach-hover {
      svg {
        width: rem(16);
        height: rem(15);

        path {
          stroke: white;
        }
      }
    }

    .icon-attach-hover {
      width: rem(30);
      height: rem(30);
      background: rgba(black, 0.45);
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      @include visibility(hidden);
      @include transition(all 0.3s linear);

      &:hover {
        background: rgba(black, 0.55);
      }
    }

    .message-composer {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #fff;
      width: 100%;

      .takeover-active-user {
        font-size: rem(13);
      }
    }

    .message-text-area {
      border: rem(1) solid $border-color;
      @include border-radius(rem(4));

      button:disabled {
        pointer-events: none;
      }

      .message-text-box {
        border: 0;
        resize: none;
        max-width: 100%;
        outline: none;
        line-height: rem(16);
        padding-top: rem(13);
        padding-right: rem(60);
        padding-bottom: rem(13);

        &:focus {
          border: rem(1) solid $primary;
        }
      }

      .send-message-button {
        top: 50%;
        right: rem(2);
        transform: translateY(-50%);

        // background-color: #fff;
        svg path {
          stroke: black;
        }

        &[disabled] {
          //background-color: transparent;
          .btn-icon {
            @include opacity(0.3);
          }
        }
      }

      .hidden-value {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        visibility: hidden;
        padding: 11px 0 12px;
        z-index: -999;
        min-height: rem(44);
      }

      .message-text-box:focus + .send-message-button {
        svg path {
          stroke: $primary;
        }
      }
    }

    .btn-conversation-attach {
      border: rem(1) solid $border-color;

      &:hover {
        border-color: $primary;
      }

      &[disabled] {
        border-color: $border-color;

        .btn-icon {
          @include opacity(0.5);
        }
      }
    }

    .attachment-icon {
      min-width: rem(16);
      width: rem(16);
      height: rem(18);
      top: 0;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: $secondary;
        }
      }
    }

    // for contact chat header
    .chat-header-wrapper {
      border-radius: rem(4) rem(4) 0 0;

      .user-status {
        width: rem(10);
        min-width: rem(10);
        height: rem(10);
        vertical-align: middle;
      }

      .user-status-online {
        background: $status-green;
        box-shadow: 0 0 0 2pt rgba(87, 171, 49, 0.15);
      }

      .user-status-offline {
        background: $bg-grey;
        box-shadow: 0 0 0 2pt rgba($bg-grey, 0.15);
      }

      .user-name {
        font-size: rem(18);
        font-family: $primary-bold-font;
      }

      .icon-notification {
        svg {
          width: 100%;

          path {
            fill: $grey-variant;
          }
        }

        @include size(rem(14));
        min-width: rem(14);
      }

      .b-chat-header-right-block {
        @include visibility(hidden);
        @include transition(all 0.2s linear);
      }

      .icon-arrow-left {
        @include size(rem(14));
      }

      .icon-chat-search {
        @include size(rem(20));
        cursor: pointer;

        svg {
          width: 100%;

          path {
            stroke: $icon-dark;
          }
        }
      }

      .icon-header-favorite {
        @include size(rem(17));

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .icon-chat-delete {
        @include size(rem(16));
        @include opacity(0.5);

        svg {
          width: 100%;

          path {
            stroke: $danger;
          }
        }
      }

      .b-chat-search {
        overflow: hidden;
      }

      .icon-navigation-arrow {
        width: rem(13);
      }

      &.is-search-header-enabled {
        box-shadow: 0 rem(4) rem(5) rgba(2, 90, 132, 0.15);
      }

      .header-search-filed {
        padding-right: rem(40);
      }
    }

    &.is-contact-chat-search-wrapper {
      .conversation-content-wrapper {
        height: calc(100vh - 430px);
      }

      .compose-area-has-more-content {
        height: calc(100vh - 430px - 35px);
      }
    }

    &.is-contact-search-header-enabled {
      .conversation-body .conversation-content-wrapper {
        height: calc(100vh - 480px);
      }

      .conversation-body .compose-area-has-more-content {
        height: calc(100vh - 480px - 35px);
      }
    }

    .tooltip-last-update-time-text {
      color: $off-white;
    }

    .chat-header-tooltip-body {
      left: 50%;

      .content-block {
        min-width: rem(200);
        margin-left: rem(25);
      }
    }

    .b-scroll-bottom {
      @include size(rem(30));
      background: $grey-blue;
      right: rem(35);
      bottom: rem(150);
      @include transition(all 0.2s linear);

      &:hover {
        background: $primary;
      }

      svg path {
        fill: white;
      }
    }

    // end of contact chat header
  }

  .b-chat-search {
    overflow-x: hidden;
    // z-index   : 1036;

    .icon-chat-search {
      top: 50%;
      right: rem(15);
      @include transform(translateY(-50%));
    }

    .search-count {
      font-size: rem(14);
      color: $rare-grey;
    }

    .icon-arrow-rotate {
      @include transform(rotate(-180deg));
    }

    .btn-search-cancel {
      color: $rare-grey;
      font-size: rem(14);
      font-family: $tertiary-font;
    }

    .navigation-arrow {
      svg path {
        fill: rgba($rare-grey, 0.7);
      }

      &:hover svg path {
        fill: $rare-grey;
      }
    }

    .icon-navigation-arrow {
      width: rem(10);
      height: rem(15);
    }
  }

  .uploading-bar-animate {
    height: rem(5);
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #ecf2f4;
    z-index: 101;
    margin: 0;
    text-align: center;
    line-height: rem(13);
    font-size: rem(12);
    display: block;
    left: 0;
    top: 0;
    text-indent: rem(-999);

    &:before {
      display: block;
      position: absolute;
      content: '';
      left: rem(-200);
      width: rem(200);
      height: 100%;
      background-color: $primary;
      animation: uploading-bar 2s linear infinite;
    }
  }

  .b-send-type {
    .type-info {
      font-size: rem(14);
      color: $secondary;
      font-family: $primary-font;
    }

    .gif-loader {
      width: rem(30);
    }
  }

  // end conversation section
}

// when conversation list opens
.conversation-list-open {
  .messenger-wrapper {
    .conversation-list-wrapper {
      left: 0;
    }
  }
}

// end conversation list opens

// when account details open
.account-side-open {
  .messenger-wrapper {
    .b-account {
      z-index: 1015;
      // padding: rem(25);
      width: 100%;
      @include visibility(visible);
      @include transform(translateX(0));
    }

    .btn-account-details {
      z-index: 1034;
    }

    .icon-toggle-arrow {
      margin-right: -1px;
    }

    .icon-toggle-arrow {
      svg {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        @include transition(all 0.5s linear);
      }
    }
  }

  &.modal-open .messenger-wrapper {
    .b-account {
      z-index: 0;
    }

    .btn-account-details {
      z-index: 0;
    }
  }

  // conversation section
  .conversation-wrapper {
    .send-message {
      width: 100%;
    }

    .received-message {
      width: 100%;
    }

    .received-message-wrapper,
    .send-message-wrapper {
      .b-time-stamp {
        width: 100%;
      }
    }
  }

  // end conversation section
}

// end account details open

// For contact messenger/chat section
.c-details-chat-widget {
  .received-message-wrapper {
    // .chat-profile {
    //     background: $color-cyan;
    // }
    .profile-image {
      svg {
        @include size(rem(19));

        path {
          fill: white;
        }
      }
    }
  }

  &:hover {
    .chat-header-wrapper .b-chat-header-right-block {
      @include visibility(visible);
    }
  }
}

// end of contact messenger/chat section

///////////////////////////////////////////////
///////////////////////// custom media queries
///////////////////////////////////////////////

@media (min-width: 1024px) {
  // messenger frame action
  .messenger-wrapper {
    .btn-account-details.icon-toggle-arrow {
      display: block;
      z-index: 1;
    }

    .btn-account-details.icon-toggle-user {
      display: none;
    }
  }

  .side-menu-open.account-side-open .messenger-wrapper {
    .icon-toggle-user {
      right: 39%;
    }
  }

  // end messenger frame action
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .messenger-wrapper {
    .conversation-list-wrapper {
      width: rem(270);
    }
  }

  .side-menu-open .messenger-wrapper {
    .conversation-list-wrapper {
      width: rem(280);
    }
  }

  .side-menu-open.account-side-open .messenger-wrapper {
    .icon-toggle-arrow {
      right: 40%;
    }
  }

  .side-menu-open.account-side-open {
    .messenger-wrapper {
      .b-account {
        width: 40%;
      }
    }
  }
}

.has-thumtack-error {
  .messenger-wrapper {
    .conversation-list-wrapper,
    .conversation-wrapper,
    .b-account {
      height: calc(100vh - (202px + 90px));
    }

    .chat-list-wrapper-inner {
      height: calc(100vh - (350px + 70px));
    }

    .conversation-wrapper .conversation-content-wrapper {
      height: calc(100vh - (375px + 70px));
    }
  }
}
