@include media-breakpoint-down(md) { 
    .notification-outer-wrapper .notification-table{
        td{
            border-bottom: rem(1) solid #dbe3eb;
            padding-top: rem(14);
            padding-bottom: rem(14);
            .form-group{
                margin:0;
                label{
                    margin: rem(2) 0 0;
                }
            }
        }
        td:first-child{
            font-family: $secondary-bold-font;
            padding-top: rem(30);
            padding-bottom: rem(5);
        }
    }
}