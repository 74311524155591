@include media-breakpoint-down(md) {
  // booking list page
  .page-list {
    .b-table-tooltip {
      .list-table-tooltip {
        left: 0;
        transform: inherit;
      }
    }
  }

  // end booking list page
}
