@include media-breakpoint-up(xl) {
  .page-body {
    padding-right: rem(30);
    padding-left : 0;
  }

  // Payment list page
  .field-truncate {
    max-width: rem(140);
  }

  .side-menu-open {
    .field-truncate {
      max-width: rem(80);
    }

    .field-truncate-md {
      max-width: rem(160);
    }
  }

  // end Payment list page
  
  // Manage my business General information
  .b-business-info {
    .business-content-left-info {
      padding-right: rem(23);
    }

    .business-content-right-info {
      padding-left: rem(23);
      &.facebook-sync-has-no-left-space {
        padding-left: 0;
      }
    }
  }
  // end Manage my business General information
  
}


@media (min-width:1336px) {

  // Payment list page
  .field-truncate {
    max-width: rem(190);
  }

  .side-menu-open .field-truncate {
    max-width: rem(190);
  }

  // end Payment list page
}

@include media-breakpoint-up(xxl) {

  // service list
  .custom-page-service-list {
    .table-responsive-lg .table thead tr th {
      &:nth-last-of-type(1) {
        width: rem(110);
      }
    }
  }
  // end service list

    // full page loader
    .full-page-loader {
      .center-progress {
          &:after {
              background-size: 100%;
          }
      }
  }
  // end full page loader

}