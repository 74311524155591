input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.is-disabled-checkbox {
  color: $light-text-color;
  cursor: not-allowed;
}

// sync icon animation
.icon-sync-updated {
  svg {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    @include transition(all 0.2s linear);

    path {
      stroke: rgba($secondary, 0.3);
    }
  }

  &:hover svg {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

// end sync icon animation
// common overlay style
.custom-page-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

// end common overlay style

// common dropdown styles
.b-common-drop-down {
  .drop-down-list-item {
    &:hover {
      background: $light-gray;
    }
  }
}

// end common dropdown styles

// to prevent when modal is opened
.modal-open .main-header {
  z-index: 1030;
}

// end to prevent when modal is opened

// enterprise user header
.is-enterprise-user {
  .main-header {
    padding-top: rem(50);

    .b-custom-logo {
      width: fit-content;
      position: absolute;
      top: rem(10);
      left: 50%;
      transform: translateX(-50%);

      &.ftu-hide-custom-logo {
        //hide the logo for FTU flow ticket: WEB-1201
        display: none;
      }
    }

    .header-title {
      padding-right: 5% !important;
    }

    .logo-block {
      max-width: rem(75);
    }

    .logo-text {
      font-size: rem(16);
      font-family: $secondary-bold-font;
    }
  }
}

// end enterprise user header

// for outer loader
.outer-layout-main-wrapper {
  min-height: 100vh;

  .inner-page-loader {
    min-height: 100vh;
  }
}

// end outer loader

// full page loader
.full-page-loader {
  // margin-top: rem(68);
  margin-bottom: rem(-40);
  height: 100vh;

  .center-progress {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(https://dufzo4epsnvlh.cloudfront.net/image/icon-new/loader-bg-left-top.svg) no-repeat;
      background-size: 60%;
    }

    &:after {
      content: '';
      position: absolute;
      right: rem(-80);
      bottom: 0;
      background: url(https://dufzo4epsnvlh.cloudfront.net/image/icon-new/loader-bg-right-top.svg) no-repeat;
      background-size: 100%;
      background-position: bottom right;
      width: 100%;
      height: 100%;
    }
  }
}

// end full page loader

.main-wrapper {
  top: rem(68);
  min-height: calc(100vh - 108px);
}

.main-wrapper-with-only-min-height {
  min-height: calc(100vh - 108px);
}

svg title {
  display: none;
}

.page-body {
  padding: rem(15);
  margin-bottom: rem(20);

  .frm-shareable-url {
    .custom-form-group-dropdown {
      .dropdown-select {
        &:before {
          @include opacity(0);
          @include visibility(hidden);
        }

        .dropdownSelect__dropdown-indicator {
          display: none;
        }

        .dropdownSelect__clear-indicator,
        .dropdownSelect__control {
          cursor: pointer;
        }
      }
    }
  }
}

// end sticky header wrapper

.btn-loader {
  width: rem(22);
}

.restore_icon {
  max-width: rem(12);
  max-height: rem(12);
  cursor: pointer;
}

.predefined-templates {
  $templateHeight: rem(27);
  padding-left: 0;
  height: $templateHeight;
  overflow: hidden;
  white-space: nowrap;

  .list-active label {
    font-family: $secondary-bold-font;
    border: solid rem(1) $primary;
  }

  li {
    padding: 0 rem(10) 0 0;
    margin: 0;
    float: left;
    list-style: none;

    label {
      color: $color-gary-variant;
      border: solid rem(1) $border-color;
      padding: rem(3) rem(8) rem(4);
      border-radius: rem(4);
      font-size: rem(12);
      height: $templateHeight;

      &:hover {
        color: $secondary;
        background: transparent;
        border: solid rem(1) $primary;
        cursor: pointer;
      }
    }

    .btn-add-Predefined-template {
      color: $primary;
      width: rem(230);
      height: rem(25);
      font-size: rem(12);
      font-weight: 400;
      border: solid rem(1) $border-color;
      cursor: pointer;
      font-family: $secondary-bold-font;

      .icon-add-predefined {
        font-size: rem(18);
        font-family: $secondary-bold-font;
      }

      &:hover {
        color: $secondary;
        background: transparent;
        border: solid rem(1) $border-color;
      }
    }
  }

  margin: rem(0);
}

// booking calendar
.calendar-square-wrapper {
  top: rem(60);
}

.calendar-square-loader {
  width: calc(14% - 10px);
}

.b-calendar {
  .rbc-btn-group {
    top: 0;
    left: rem(1);
    position: absolute;
    z-index: 1;
  }

  .rbc-toolbar-label {
    margin-bottom: rem(20);
  }

  .rbc-calendar {
    width: 100%;

    .rbc-toolbar .rbc-toolbar-label {
      font-size: rem(20);
      font-family: $secondary-bold-font;
      color: $secondary;
      position: relative;
      top: rem(5);
    }

    .rbc-month-view {
      @include border-radius(rem(4) rem(4) 0 0);

      .rbc-month-header .rbc-header {
        padding: rem(16) rem(4);
        background-color: $tertiary;
        font-family: $secondary-bold-font;
        color: $light-text-color;
      }
    }

    .rbc-event-content {
      font-size: rem(11);
    }

    .rbc-row-segment {
      padding-left: rem(3);
      padding-right: rem(3);
    }

    .rbc-off-range-bg {
      background: white;
    }

    .rbc-off-range a {
      color: $text-off-color;
      @include opacity(0.3);
    }

    .rbc-today {
      position: relative;
      background: white;

      &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        -webkit-box-shadow: 0 0 rem(5) $calender-highlight-color;
        -moz-box-shadow: 0 0 rem(5) $calender-highlight-color;
        box-shadow: 0 0 rem(5) $calender-highlight-color;
        border: rem(1) solid $date-time-highlight;
      }
    }

    .rbc-toolbar {
      .rbc-btn-group {
        button {
          width: rem(40);
          height: rem(36);
          padding: 0;
          position: relative;
          text-indent: rem(-5000);
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          border-color: $border-color;
          @include transition(all 0.3s linear);

          &:before {
            content: '';
            position: absolute;
            top: rem(11);
            display: block;
            width: rem(10);
            height: rem(10);
            border-top: rem(2) solid $secondary;
            border-left: rem(2) solid $secondary;
          }

          &:nth-of-type(1) {
            display: none;
          }

          &:nth-of-type(2) {
            color: white;
            @include border-radius(rem(4) 0 0 rem(4));

            &:before {
              transform: rotate(-45deg);
              left: rem(15);
            }
          }

          &:nth-of-type(3) {
            @include border-radius(0 rem(4) rem(4) 0);

            &:before {
              left: rem(13);
              transform: rotate(135deg);
            }
          }

          &:hover {
            background: $primary;
            border-color: $primary;

            &:before {
              border-top: rem(2) solid white;
              border-left: rem(2) solid white;
            }
          }
        }
      }
    }
  }
}

// end booking calendar

.media-library-popup {
  max-width: rem(1130);
}

.modal .modal-body .custom-modal-close-button {
  top: rem(-40);
  right: rem(15);
  opacity: 1;
  font-size: 0;

  span {
    width: rem(30);
    height: rem(30);
    display: block;
    background: url(https://gosite-assets.s3-us-west-1.amazonaws.com/image/svg-icons/modal/close.svg) no-repeat center;
  }
}

// svg and img
svg,
img {
  max-width: 100%;
  height: auto;
}

//for tiny mce editor
.tox-toolbar__primary {
  text-align: center;
  justify-content: center;
}

// For notification visibility
// .notify-wrapper {
//   z-index: 1050;
// }

// for custom tooltip/click on the tooltip content
.custom-tooltip {
  position: relative;
  cursor: pointer;
  &:hover {
    color: #082344;
  }
  .custom-tooltip-active {
    display: none;
    z-index: 1;
    @include transition(all 0.3s linear);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .content-block {
    width: rem(160);
    background: $secondary;
    @include border-radius(rem(4));
    padding: rem(12) rem(10) rem(10);
  }

  .tooltip-description {
    font-size: rem(12);
    color: white;

    a {
      cursor: pointer;
      color: $white;
    }
  }

  .send-link {
    color: $primary;
    font-size: rem(12);
    cursor: pointer;
  }

  &:hover {
    .custom-tooltip-active {
      display: block;
    }
  }

  .custom-tooltip-inactive {
    display: none;
  }
}

// graph wrapper (width:425px)
.b-chart {
  max-width: rem(425);
}

.graph-count-value span {
  font-size: rem(20);
}

// for business hours
.b-hours {
  .left-content,
  .right-content {
    flex: 1;
  }

  .checkbox-weeks {
    width: 100%;
  }

  .switch {
    width: 100%;
  }

  .b-time {
    width: rem(65);

    input {
      padding: 0;

      &:disabled {
        // @include opacity(0.5);
        background: white;
      }
    }
  }

  .caret-button-down {
    @include opacity(0.5);
  }

  .b-time-session {
    width: rem(65);

    select {
      width: rem(30);
      padding-left: 0;
      padding-right: 0;

      &:disabled {
        // @include opacity(0.5);
        background-color: white;
      }

      &.form-control {
        background: none;
      }
    }

    .caret-button-down {
      border-width: rem(6);
    }
  }

  .b-customize {
    width: rem(100);
  }

  .common-action-btn-icons {
    padding: 0 rem(5);
  }

  .action-close-icon {
    .close-icon-effect {
      width: rem(18);
      height: rem(18);
    }

    &:hover {
      .close-icon-effect {
        box-shadow: 0 rem(6) rem(10) rgba($danger, 0.38);

        svg {
          @include opacity(1);

          path {
            &:nth-last-of-type(3) {
              fill: $danger;
              stroke: $danger;
            }

            &:nth-last-of-type(1) {
              stroke: white;
            }

            &:nth-last-of-type(2) {
              stroke: white;
            }
          }
        }
      }
    }
  }

  .action-plus-icon {
    .plus-icon-effect {
      width: rem(18);
      height: rem(18);
    }

    &:hover {
      .plus-icon-effect {
        box-shadow: 0 rem(6) rem(10) rgba($primary, 0.38);

        svg {
          @include opacity(1);

          path {
            &:nth-last-of-type(3) {
              fill: $primary;
              stroke: $primary;
            }

            &:nth-last-of-type(1) {
              stroke: white;
            }

            &:nth-last-of-type(2) {
              stroke: white;
            }
          }
        }
      }
    }
  }

  .action-plus-icon:disabled {
    &:hover {
      .plus-icon-effect {
        box-shadow: none;

        svg {
          @include opacity(0.6);

          path {
            &:nth-last-of-type(3) {
              fill: transparent;
              stroke: rgb(8, 35, 68);
            }

            &:nth-last-of-type(1) {
              stroke: rgb(8, 35, 68);
            }

            &:nth-last-of-type(2) {
              stroke: rgb(8, 35, 68);
            }
          }
        }
      }
    }
  }

  .action-tick-icon {
    .tick-icon-effect {
      width: rem(18);
      height: rem(18);
    }

    &:hover {
      .tick-icon-effect {
        box-shadow: 0 rem(6) rem(10) rgba($primary, 0.38);

        svg {
          @include opacity(1);

          path {
            &:nth-last-of-type(2) {
              fill: $primary;
              stroke: $primary;
            }

            &:nth-last-of-type(1) {
              stroke: white;
            }
          }
        }
      }
    }
  }

  .action-tick-icon:disabled {
    &:hover {
      .tick-icon-effect {
        box-shadow: none;

        svg {
          @include opacity(0.6);

          path {
            &:nth-last-of-type(2) {
              fill: transparent;
              stroke: rgb(8, 35, 68);
            }

            &:nth-last-of-type(1) {
              stroke: rgb(8, 35, 68);
            }
          }
        }
      }
    }
  }
}

//  business hours for add service popup
.common-add-service-modal {
  .b-hours {
    margin-bottom: rem(5);
    padding-bottom: rem(5);
    border-bottom: rem(1) solid $border-color;

    .left-content {
      min-width: rem(160);
    }

    .b-customize {
      width: rem(85);
    }

    .b-time {
      width: rem(40);
      margin-left: rem(10);
    }

    .common-action-btn-icons {
      padding: 0 rem(3);
    }

    .b-time-session {
      width: rem(50);
    }
  }
}

// .tab-content {

//   .feedback-sticky-panel.is-header-sticky,
//   .widget-sticky-panel.is-header-sticky {
//     padding-left : rem(10);
//     padding-right: rem(10);
//   }
// }

.is-header-sticky {
  .is-hide-on-sticky-active {
    display: none;
  }
}

// .side-menu-open {
//   .is-header-sticky {
//     left : rem(250);
//     width: calc(100% - 280px);
//   }
// }

// .side-menu-open {
//   .is-header-sticky {
//     left : $sidebar-width;
//     width: calc(100% - #{$sidebar-width});
//   }
// }

//  manage dropdown icon style
.action-list-icon {
  width: rem(15);
  @include opacity(0.6);

  svg path {
    fill: $light-text-color;
  }
}

// Image loader icon
.image-loader-dot {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

// icon exclamation warining

.icon-exclamation-warining {
  @include size(rem(20), rem(20));
  @include border-radius(50%);
  background: $date-time-highlight;
  color: white;
  line-height: rem(20);
  font-size: rem(15);
  font-family: $primary-bold-font;
  text-align: center;
  display: inline-block;
}

// for activated modal (trial page modal)
.activate-modal-wrapper {
  .header-icon {
    width: rem(80);
  }

  .content-title-text {
    font-size: rem(24);
    line-height: rem(34);
  }
}

.modal .modal-body .activate-modal-wrapper .custom-modal-close-button {
  right: 0;
}

// for table footer buttons
.b-table-footer-btn {
  .footer-custom-button {
    font-size: rem(12);
  }
}

// end for table footer buttons
//start for transaction advance search react select
.transaction-advancesearch {
  .dropdown-select {
    .dropdownSelect__control {
      border-bottom: rem(1) solid $border-color;
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
    }
  }
}

//end for transaction advance search react select
// reviews list page
.page-all-reviewslist {
  .icon-calendar {
    width: rem(16);
    cursor: pointer;
    top: 50%;
    right: rem(10);
    transform: translateY(-50%);
    @include opacity(0.5);
  }

  .react-datepicker-popper {
    top: rem(42) !important;
    transform: none !important;
  }
}

// end reviews list page
// for review request reset/delete schedule popup close button
.page-reviewRequest-list-common {
  .modal .modal-title {
    padding-right: rem(60);
  }
}

// end for review request reset/delete schedule popup close button

// site list page disable button
.page-site-list-common {
  .btn.disabled {
    @include opacity(1);
  }
}

// end of site list page

// Booking settings page
.page-booking-settings-common {
  .tox.tox-tinymce {
    border: none;
    border-bottom: rem(1) solid $border-color;
    @include border-radius(rem(4) rem(4) 0 0);
  }
}

// end of booking settings page

// manage dropdown button click animation
.b-manage-dropdown {
  .manage-icon-arrow {
    @include transition(all 0.3s linear);
    min-width: rem(12);
  }

  .dropdown-menu-manage {
    .manage-list-item:hover {
      background: $light-gray;
    }
  }
  .status-button-height {
    height: 2.5rem !important;
  }
  .btn-manage {
    color: $secondary;
    height: rem(30);
    font-family: $secondary-bold-font;
    @include opacity(0.5);
    @include transition(all 0.3s linear);
  }

  &:hover .btn-manage {
    @include opacity(1);

    svg path {
      fill: $secondary;
    }
  }
}

.dropdown {
  .btn-icon {
    min-width: rem(12);
    cursor: pointer;
  }
}

.dropdown-sm {
  .dropdown-menu-manage {
    min-width: rem(130);
  }
}

.dropdown-md {
  .dropdown-menu-manage {
    min-width: rem(200);
  }
}

.dropdown-lg {
  .dropdown-menu-manage {
    min-width: rem(250);
  }
}

.b-manage-dropdown.show {
  .btn-manage {
    @include opacity(1);
  }

  .manage-icon-arrow {
    top: rem(2);
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    @include transition(all 0.3s linear);
  }
}

// end manage dropdown button click animation

.b-image-cropmodal {
  overflow: hidden;
}

// for media library react dropdown height
.b-media-library-dropdown {
  .dropdown-select .dropdownSelect__control {
    height: rem(36);
  }
}

// end for media library react dropdown height

// website page search scroll issue fixed
.custom-sticky-manage {
  display: none;
}

.custom-sticky-manage.is-header-sticky {
  display: block;
}

// website page search scroll issue fixed

// for table sort icon
.table-sort-icon-hover {
  cursor: pointer;
}

// for table sort icon

// payment react select
.custom-page-payment-list {
  .add-transaction-custom-disabled {
    cursor: pointer;
  }

  .btn-advance-search-close {
    top: rem(-10);
    @include opacity(0.3);
  }

  .advanced-dropdown {
    width: 100%;

    .btn-apply {
      font-size: rem(12);
    }

    .btn-danger-cancel {
      font-size: rem(12);
      font-family: $tertiary-font;

      &:hover {
        color: $danger;
        border-color: $danger;
      }
    }
  }

  .all-transaction-dropdown {
    .dropdownSelect__menu-list {
      max-height: rem(200);
      overflow: auto;
      @include scrollbar;
    }
  }

  .custom-form-group-dropdown {
    .react-datepicker-popper {
      top: rem(42) !important;
      transform: none !important;
    }
  }
}

.custom-get-paid-form {
  .custom-cursor-hide {
    .dropdownSelect__single-value {
      margin-left: rem(-2);
    }
  }
}

// end payment react select

.custom-booking-list-dropdown {
  .dropdownSelect__menu-list {
    overflow: auto;
    @include scrollbar;
  }
}

// service list
.custom-page-service-list {
  .has-full-column-enable-wrapper {
    .table thead tr th {
      &:nth-of-type(3),
      &:nth-of-type(4) {
        text-align: center;
      }
    }
  }

  .table-responsive-lg .table thead tr th {
    &:nth-last-of-type(1) {
      text-align: left;
      max-width: rem(230);
    }
  }
}

// end service list

// link style

.link-color {
  color: $primary;
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

// End of link style

// create widget popup
.custom-create-widget-modal-wrapper {
  .custom-input-field {
    .dropdown-select .dropdownSelect__dropdown-indicator {
      background: transparent;
      margin-right: 0;

      svg {
        width: rem(20);
        display: block;
        @include opacity(0.4);
        @include transition(all 0.3s linear);
      }
    }

    .dropdownSelect__menu-list > div {
      padding: rem(3) rem(15);
      cursor: pointer;

      &:hover {
        background: $light-gray;
      }
    }

    .dropdownSelect__control--menu-is-open {
      .dropdownSelect__dropdown-indicator {
        svg {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          @include transition(all 0.3s linear);
          @include opacity(0.4);
        }
      }
    }
  }

  .preview-dropdown-wrapper {
    .b-preview-select {
      border: rem(1) solid $border-color;
      @include border-radius(rem(4));

      .dropdown-select {
        .dropdownSelect__control {
          height: rem(42);
          line-height: rem(36);
          box-shadow: none;
          border: none;

          &:after {
            content: '';
            position: absolute;
            right: rem(15);
            top: 50%;
            transform: translateY(-50%);
            @include caret-down();
            @include opacity(0.3);
          }
        }
      }
    }
  }
}

// end create widget popup

// booking list cancel
.custom-btn-cancel {
  width: 100%;
  font-size: rem(14);
}

// end booking list cancel

// placement question nodata height reduce
.custom-placement-qalist {
  .b-no-data {
    min-height: rem(200);
  }
}

// end placement question nodata height reduce

// Messenger page
.custom-select-dropdown-for-assigned-to {
  width: rem(192);
  position: relative;
  z-index: 2;

  .dropdown-select {
    .dropdownSelect__single-value {
      padding-top: 4px;
      margin-left: rem(-2);
      background: $bg-list;
    }

    .dropdownSelect__placeholder {
      padding-top: 4px;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdownSelect__dropdown-indicator {
      padding-right: 0;

      svg {
        display: block;
        width: rem(20);
      }
    }

    .dropdownSelect__indicator {
      padding: 0;
    }

    .dropdownSelect__control {
      height: rem(36);
      min-height: rem(36);
    }

    .dropdownSelect__value-container {
      top: rem(-2);
    }
  }
}

// end Messenger page

// Payment list page
.field-truncate {
  max-width: rem(140);
}

// end Payment list page

// Email or phone validation

.email-phone-error {
  @include border-radius(rem(3));
  font-size: rem(12);
  font-family: $secondary-bold-font;
}

// contact page auto search and react select
.custom-section-add-contact-modal {
  .custom-react-search-select-wrapper {
    // &.custom-group-select{
    .search-select {
      .globalSelect__control {
        height: auto;
      }

      .globalSelect__input {
        &::after {
          content: '';
        }
      }

      .globalSelect__indicators {
        display: flex;
      }
    }

    // }
  }
}

.contact-auto-search {
  position: relative;

  .dropdownSelect__control {
    border: 0;
    height: auto;
    min-height: auto;
    padding: rem(0);
    @include box-shadow(none);
  }

  .dropdownSelect__value-container {
    padding: 0;
  }

  .dropdownSelect__single-value {
    margin-left: rem(-1);
    background: $bg-list;
    padding-left: rem(1);

    > div {
      position: relative;
      line-height: rem(35);
      top: rem(-1);
    }
  }

  .dropdownSelect__indicator {
    padding: 0;
    margin-right: rem(20);
  }
}

// contact settings
.custom-page-contact-settings {
  button[disabled].btn.btn-outline-danger:hover {
    color: $secondary;
    border: rem(1) solid $border-color;
  }
}

// end contact settings

// TFA Popup

.tfa-popup {
  .modal .modal-body {
    .custom-modal-close-button {
      top: rem(-65);
      right: rem(-10);
    }
  }
}

// Contact group table

.group-table-wrapper {
  .table {
    thead {
      tr {
        th:first-child {
          width: 50%;
        }

        th:last-child {
          width: rem(75);
        }
      }
    }
  }
}

.cloud-download-icon {
  max-width: rem(20);
}

///drag and drop mirror class
//for table
tr.dragging-helper-class {
  display: flex;
  border: solid rem(1) $border-color;
  background-color: #fff;
  cursor: pointer;
  align-items: center;

  td {
    flex: 1;
    padding: 15px;
  }

  .no-table-th {
    display: none;
  }
}

.imported-contacts-wrapper-popup {
  max-height: rem(200);
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollbar;
}

.more-groups-wrap {
  position: relative;
  display: inline-block;
  $more-size: rem(60);

  &:hover {
    .multiple-groups {
      @include visibility(visible);
    }
  }

  .text-with-dot {
    display: inline-block;
    max-width: rem(350);
    padding-right: $more-size;
  }

  .multiple-groups {
    display: inline-block;
    position: absolute;
    left: calc(100% - #{$more-size});
    top: rem(1);
    cursor: pointer;
    background-color: #fff;
    padding: rem(2) rem(8);
    @include border-radius(rem(20));
    color: $text-gray-variant;
    white-space: nowrap;
    @include transition(all 0.2s linear);
    font-size: rem(10);
    border: solid rem(1) $text-gray-variant;
    @include visibility(hidden);
    z-index: 1;
    margin-left: rem(5);
    width: $more-size;

    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}

//top header icon buttons
.contact-head-right-block {
  .btn-icon-only {
    @include size(rem(44));
  }
}

//contact details chatbox height
.c-details-chat-widget {
  .conversation-content-wrapper {
    padding-left: 10px;
  }

  .conversation-wrapper {
    height: calc(100vh - 120px);
  }

  .conversation-wrapper.is-contact-search-header-enabled .conversation-body {
    .conversation-content-wrapper {
      height: calc(100vh - 380px);
    }

    .compose-area-has-more-content {
      height: calc(100vh - 380px - 35px);
    }
  }

  .conversation-wrapper.is-contact-chat-search-wrapper {
    .conversation-content-wrapper {
      height: calc(100vh - 330px);
    }

    .compose-area-has-more-content {
      height: calc(100vh - 330px - 35px);
    }
  }

  .b-no-data-chat {
    height: rem(400);
  }
}

//contact details note height
.c-details-notes-widget {
  .note-content {
    min-height: rem(300);
  }
}

// engagement theme list
// preview modal
.theme-preview-modal {
  .modal-content {
    border: rem(6) solid white;
  }

  .modal-header,
  .modal-body {
    padding: 0;
  }

  &.modal .modal-body {
    .custom-modal-close-button {
      top: rem(-50);
      right: rem(-10);
    }
  }

  .modal-dialog {
    margin-top: rem(60);
  }

  .custom-modal-close-button {
    top: rem(-50);
    right: rem(-10);
  }
}

// end engagement theme list

// trial home page
.custom-trail-home-page.custom-home-page {
  .b-card-activity .content-list .b-status-list {
    min-height: auto;
  }
}

// end trial home page

// home page
.custom-home-page {
  .b-chart {
    min-height: rem(200);
  }

  // activity widget
  .b-card-activity {
    .content-list {
      width: 100%;

      .b-status-list {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .status-list-item {
        padding-bottom: rem(25);

        // .list-icon svg path {
        //   stroke: $primary;
        // }

        .time {
          margin-bottom: rem(10);
        }

        .location-title {
          margin-top: rem(10);
        }

        .media-updates {
          margin-top: rem(10);

          figure {
            padding: rem(3);
            margin-right: rem(5);
            @include size(rem(70));
            @include border-radius(rem(3));
            border: rem(1) solid $border-color;
            box-shadow: 0 rem(2) rem(8) rgba(206, 211, 218, 0.2);
          }

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        dl {
          margin-bottom: 0;
          @include transition(all 0.3s linear);
        }

        dt {
          font-size: rem(14);
          font-family: $primary-font;
          font-weight: 400;
          position: relative;

          &:before {
            position: absolute;
            content: '';
            top: rem(6);
            left: rem(-41);
            z-index: 1;
            background-color: white;
            @include size(rem(10));
            @include border-radius(100%);
            border: rem(1) solid $border-color;
          }
        }
      }

      .media-title {
        color: $grey-variant;
      }

      .icon-view {
        position: relative;
        top: rem(-1);

        img {
          @include size(rem(16));
        }
      }

      .view-business-update,
      .status-title a img {
        display: inline-block;
        @include size(rem(16));
      }

      .view-business-update {
        margin-left: rem(5);
        position: relative;
        top: rem(-2);
        cursor: pointer;
      }

      .icon-arrow {
        top: rem(9);
        right: 0;
        cursor: pointer;
        @include transition(all 0.3s linear);
      }

      .is-arrow-activated {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        @include transition(all 0.3s linear);
      }

      .message-activity {
        width: 90%;
        max-width: rem(500);
        padding: rem(8) rem(15) rem(8);
        border: rem(1) solid $tertiary;
        @include border-radius(rem(3));
        box-shadow: 0 rem(2) rem(8) rgba(206, 211, 218, 0.2);

        small {
          font-size: rem(14);
          color: $light-text-color;
          margin-left: rem(3);
          font-weight: 600;
          font-family: $primary-font;
        }
      }

      .light-text {
        color: $grey-variant;
      }

      .business-update-content {
        @include transition(all 0.3s linear);
        @include visibility(hidden);
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background: white;
        max-width: rem(240);
        padding: rem(15) rem(20);
        box-shadow: 0 rem(6) rem(12) rgba(0, 64, 88, 0.25);

        &.visible {
          @include visibility(visible);
        }
      }

      .star-empty {
        svg path {
          fill: $dark-gray;
          stroke: $dark-gray;
        }
      }

      .b-updated-hours {
        padding: rem(5) 0;
      }

      .change-time {
        border-bottom: rem(1) solid $tertiary;
      }

      .time-info {
        font-size: rem(13);
      }

      .btn-close {
        position: absolute;
        top: rem(10);
        right: rem(10);
        width: rem(10);
        height: rem(10);
        display: block;
        cursor: pointer;
        background: url(https://gosite-assets.s3-us-west-1.amazonaws.com/image/svg-icons/modal/close.svg) no-repeat
          center;
      }
    }
  }

  // end activity widget
}

.icon-engagement-colored {
  path {
    fill: $primary;
  }
}

// end home page

.send-info {
  font-size: rem(14);
  background-color: transparent;
  border: none;
  @include transition(all 0.3s linear);

  &:hover {
    color: $primary;
  }
}

// Manage my business General information
.b-business-info {
  .dropdown-select {
    .dropdownSelect__control {
      height: rem(40);
    }

    .dropdownSelect__dropdown-indicator {
      padding: 0;
      margin-right: rem(10);

      svg {
        display: block;
        width: rem(20);
      }
    }
  }

  .custom-select-arrow-down {
    right: rem(15);
  }

  .custom-group-select .globalSelect__control {
    height: auto;
  }

  // Manage my business General information
  .business-content-right-info {
    &.facebook-sync-has-no-left-space {
      padding-left: 0;
      width: 100%;
    }
  }

  // end Manage my business General information
}

// end Manage my business General information

// Manage my business
.custom-page-business-settings {
  .icon-menu-arrow {
    margin-top: rem(-2);
    width: rem(12);
    @include transition(all 0.3s linear);
  }

  .down-lg-dropdown-menu-open {
    .icon-menu-arrow {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      @include transition(all 0.3s linear);
    }
  }
}

.custom-page-booking-settings,
.custom-page-placement-settings {
  .tox.tox-tinymce {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
    border-bottom: rem(1) solid $border-color;
    @include border-radius($border-radius $border-radius 0 0);
  }
}

// end Manage my business

//Business settings > Menus > drag mirror
.gu-mirror.menus-menu-drag-mirror {
  background-color: $white;
  @include opacity(1);
  @include box-shadow(0 0 rem(18) rgba($black, 0.1));
  border: solid rem(1) $border-color-light;
  list-style: none;

  figure {
    @include size(rem(50));
    flex: 0 0 rem(50);
    background-color: $bg-grey;

    svg {
      @include size(rem(32));
      object-fit: contain;

      path {
        fill: $light-gray;
      }
    }
  }

  .sub-item-image {
    @include size(rem(50));
    flex: 0 0 rem(50);
    background-color: $bg-grey;
  }

  .cal-text-mirror {
    color: #8390a1;
    margin-right: rem(6);
    position: relative;

    &::after {
      content: '.';
      position: relative;
      top: rem(-3);
      margin-left: rem(6);
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  .menu-item-hover-block-mirror {
    display: none;
  }
}

.custom-media-content-area {
  .b-no-data {
    min-height: auto;
  }
}

// business settings > menus > add menu popup > select.....
.custom-react-search-select-wrapper.allergens-select {
  .search-select {
    .globalSelect__input {
      &::after {
        content: 'e.g. Peanuts, Soy, Dairy';
      }
    }
  }
}

.custom-react-search-select-wrapper.labels-select {
  .search-select {
    .globalSelect__input {
      &::after {
        content: 'Select labels for this item...';
      }
    }
  }
}

//icon-btn-wrap to align center
.icon-btn-wrap {
  .btn-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

// busniss settigs placement
.custom-page-placement-settings {
  .tox.tox-tinymce {
    @include border-radius(rem(4) rem(4) 0 0);
  }
}

// end busniss settigs placement
.side-menu-open {
  .b-sidemenu .present {
    overflow: hidden;
  }
}

body:not(.side-menu-open) {
  .b-sidemenu .present {
    span {
      font-size: 0 !important;
      margin: 0 !important;
    }

    svg {
      min-width: rem(18);
    }
  }
}

// contact hub
.custom-page-contact-list {
  .dropdown .dropdown-menu {
    min-width: rem(180);
  }
}

// end contact hub

// contact hub QB button and dropdown
.qb-cnt .tick {
  display: none;
  position: absolute;
  width: 14px;
  height: 14px;
  bottom: -3px;
  right: -3px;
}

.qb-cnt .tick svg {
  width: 100%;
  display: block;
}

.qb-cnt .qb-icon path {
  fill: #2ca01b;
}

.qb-cnt-disable .qb-icon path {
  fill: #dbe3eb;
}

.qb-cnt-disable .tick {
  display: block;
}

.qb-cnt-disable .tick svg path {
  fill: #0258ff;
}

// contact hub QB button and dropdown

// signup page
.rc-anchor .rc-anchor-error-msg-container {
  top: rem(4);
  left: rem(10);
}

// end signup page

// card number filed

.card-loader-hidden {
  display: none;
  transition: all 2500ms linear;
}

.card-loader {
  .loader-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-61%);
  width: 100%;
}

// end card number filed

// /////////////////////////////////////////////
// /////////////////////////////////////////////
// ///////////ENTERPRISE APP////////////////////
// /////////////////////////////////////////////
// /////////////////////////////////////////////
.custom-modal-add-sms-number-wrapper {
  .dropdownSelect__option {
    &:nth-of-type(1) {
      .code-list-item {
        font-family: $primary-bold-font;
      }
    }
  }
}

/////////////////////////////////////////
/// AsyncCreateSelect has customOptions
////////////////////////////////////////
.custom-form-group-dropdown .dropdown-select {
  &.field-has-value {
    position: relative;

    .dropdownSelect__value-container--has-value {
      display: flex;
      align-items: center;
      height: 100%;

      .dropdownSelect__single-value {
        position: relative;
        @include transform(none);
        top: 0;
        max-width: calc(100% - 15px);
      }
    }
  }
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.not-allowed-pointer-wrapper {
  cursor: not-allowed;
}

//remove owl carousel active bg color from inherit somewhere
.owl-carousel .owl-item.active {
  background: none;
}

.owl-carousel.owl-hidden {
  opacity: 1 !important;
}

//tooltip index updated
.tooltip.show {
  z-index: 1501;
}

.b-event-list-item {
  border-left: rem(2) solid rgba($secondary, 0.5);
  @include border-radius(0);
  background-color: rgba($secondary, 0.4);

  &.dashed-border {
    border-top: rem(2) dashed $secondary;
    border-right: rem(2) dashed $secondary;
    border-bottom: rem(2) dashed $secondary;
  }
}

// Background slice line image for booking page
.bg-slice-line {
  position: relative;
  color: $light-text-color;
  background-color: transparent;
  z-index: 1;

  // background-color: rgba($white, .8);
  &::before {
    position: absolute;
    content: '';
    background: url('#{$s3}/image/slice-line.png') repeat;
    background-size: rem(18);
    @include opacity(0.1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

// End background slice line image for booking page

// when booking list side view opened ====  Booking calendar page
.custom-page-booking-calendar {
  .dropdown-select--custom {
    .dropdownSelect__menu {
      width: rem(180);
      // left: inherit;
      // right: 0;
    }
  }

  &.booking-list-sideview-is-open {
    .dropdown-select--custom {
      width: auto;

      .dropdownSelect__placeholder {
        display: none;
      }

      .dropdownSelect__menu {
        width: rem(180);
        left: inherit;
        right: 0;
      }

      .dropdownSelect__input input {
        opacity: 0 !important; // For overwrite inline code
      }
    }

    .dropdownSelect__value-container {
      padding-right: rem(5);
    }

    .custom-bg-color {
      background-color: $bg-list;
      z-index: 0;
    }

    .calendar-filter-dropdown {
      background-color: transparent;
      z-index: 1;
    }
  }
}

// End booking calendar page

//UPGRADE TO PRO STATE DROPDOWN
.dropdown-select.state-dropdown .dropdownSelect__control {
  height: rem(44);
  border-color: $input-border-color;
  box-shadow: none !important; //important is required otherwise the react select field shadow will be shown

  &:focus,
  &:hover {
    border-color: $input-border-color;
  }
}

.dropdown-select.state-dropdown.invalid .dropdownSelect__control {
  border-color: $danger !important;
  box-shadow: none !important; //important is required otherwise the react select field shadow will be shown
}

//UPGRADE TO PRO STATE DROPDOWN ENDS HERE
//MEDIA LIBRARY DROP DOWN OVERLAY
.media-drop-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: rem(3) dashed $dark-gray;
  background-color: rgba(white, 0.8);
  z-index: 1;
  color: $text-lighter-gray;
}

//END OF MEDIA LIBRARY DROP DOWN OVERLAY

// UPGRADE TO PRO PAGE //////////////////////
.page-upgrade-wrapper {
  padding-top: rem(68);
}

// UPGRADE TO PRO PAGE END HERE

.gmb-spinner {
  svg {
    width: 35px;
    height: 35px;
  }
}
