@import '@assets/scss/tools-index.scss';

.custom-add-booking-form {
  .dropdown-select {
    background: #fff;

    .dropdownSelect__placeholder {
      color: $text-gray-variant;
      font-size: rem(14);
    }

    .dropdownSelect__control--is-focused {
      @include box-shadow(none);
    }

    .dropdownSelect__menu {
      // max-width:80%;
      @include border-radius(7px);
      overflow: hidden;
      @include box-shadow(0px 3px 6px rgba(206, 211, 218, 0.45));

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      div {
        border: none;
        padding: 0;
      }

      .dropdownSelect__option {
        border-bottom: 1px solid $tertiary;
        padding: rem(8) rem(12);
      }
    }

    .max-w {
      max-width: 170px;

      @include media-breakpoint-down(xs) {
        max-width: 150px;
      }

      @media (max-width: 440px) {
        max-width: 140px;
      }

      @media (max-width: 400px) {
        max-width: 120px;
      }
    }
  }

  .search-select .globalSelect__dropdown-indicator {
    top: rem(-3);
    position: relative;
  }

  .selected-user {
    .row-user-contact,
    .row-user-email,
    .row-user-phone {
      height: rem(48);
    }

    .user-contact,
    .user-email,
    .user-phone {
      width: 40%;
    }

    .user-names {
      cursor: pointer;
      width: calc(60% - 40px);
      padding-right: rem(15);
    }

    .icon-block {
      svg {
        path {
          @include transition(all 0.2s linear);
        }
      }

      &:hover {
        svg {
          path {
            fill: $primary;
          }
        }

        svg.stroke-icon {
          path {
            fill: none;
            stroke: $primary;
          }
        }
      }
    }

    .user-names {
      color: $secondary;
    }

    .user-email-value,
    .user-phone-value,
    .user-appointment-message {
      width: 60%;

      input {
        color: $text-gray-variant;
        color: $secondary;
        border: 0;

        &::placeholder {
          color: $text-gray-variant;
        }
      }
    }

    .text-as-placeholder {
      color: $text-gray-variant;
      @include opacity(0.8);
    }

    // .user-phone-value{
    //   width:60%;
    // }
  }

  .booking-detail {
    .send-via-value {
      .send-via-value-item {
        height: rem(48);
      }

      .dropdown-send-via-menu {
        min-width: rem(180);
        top: 100% !important;
        margin-top: 5px;
        z-index: 1;
        @include box-shadow(0px 8px 30px rgba(206, 211, 218, 0.8));
        @include border-radius(6px);
        background: #fff;

        .dropdown-send-via-menu-item {
          &:hover {
            background: $tertiary;
            cursor: pointer;
          }
        }
      }
    }

    .row-send-via,
    .due-date-value-date,
    .row-send-immeediatly,
    .send-immeediatly-value-date,
    .row-due-date,
    .row-smart-reminder {
      height: rem(48);
    }

    .send-via,
    .send-immeediatly,
    .due-date,
    .smart-reminder {
      width: 40%;
    }

    .send-via-value,
    .send-immeediatly-value,
    .due-date-value,
    .smart-reminder-value {
      width: 60%;
    }
  }

  .send-immeediatly-value,
  .due-date-value {
    position: relative;

    .send-immeediatly-value-date,
    .due-date-value-date {
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }

  .send-immeediatly-value,
  .due-date-value {
    input.datepicker {
      background: url('https://gosite-assets.s3-us-west-1.amazonaws.com/image/icons/arrow-down.svg') no-repeat right 0
        center;
    }

    .react-datepicker-popper {
      min-width: rem(180);
      right: 0 !important;
      left: auto !important;
      position: absolute;
      top: 100% !important;
      margin-top: 5px;
      z-index: 1;
      @include box-shadow(0px 8px 30px rgba(206, 211, 218, 0.8));
      @include border-radius(6px);
      background: #fff;

      &.due-date-calendar {
        z-index: 1;

        .calendar-button-block {
          min-width: 180px;
        }
      }

      &.send-immeediatly-calendar {
        .send-immeediatly-calendar-item {
          cursor: pointer;

          &:hover {
            background: $tertiary;
          }
        }
      }
    }

    .react-datepicker__current-month {
      display: block;
    }

    .react-datepicker {
      background: #fff;
      @include box-shadow(none);
      @include border-radius(0);
      border: none;

      .react-datepicker__header {
        background: none;
        border: none;
      }

      .react-datepicker__navigation--next,
      .react-datepicker__navigation--previous {
        border: 0.0625rem solid white;
        border-radius: 0.25rem;
        width: 2.0625rem;
        height: 2.0625rem;
        border-color: $border-color;

        &:after {
          position: absolute;
          content: '';
          border: solid white;
          border-width: 0 0.125rem 0.125rem 0;
          padding: 0.1875rem;
          display: inline-block;

          border-color: $text-gray-variant;
          margin-top: 1px;
        }
      }

      .react-datepicker__navigation--next:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        left: 0.625rem;
        top: 0.625rem;
      }

      .react-datepicker__navigation--previous:after {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        right: 0.625rem;
        top: 0.625rem;
      }

      .react-datepicker__current-month {
        color: $secondary;
      }

      .react-datepicker__day-name {
        color: $secondary;
      }

      .react-datepicker__day {
        color: #000;
      }

      .react-datepicker__day--outside-month,
      .react-datepicker__day--disabled {
        color: $text-gray-variant;
      }

      // .react-datepicker__day--keyboard-selected {}

      .react-datepicker__day--today {
        color: $primary;
      }

      .react-datepicker__day--selected {
        background: $primary;
        color: #fff;
      }
    }
  }

  .table-add-booking {
    th,
    td {
      border-bottom: 0;
      border-bottom: 1px solid $border-color;
    }

    th {
      font-size: rem(14);

      &.amount {
        width: rem(113);
      }

      &.qty {
        width: rem(70);
        padding-right: 20px;
      }

      &.rate {
        padding-right: 20px;
        width: rem(98);
      }
    }

    td {
      vertical-align: top;
      padding-top: 0;
      padding-bottom: 0;

      &.td-add-item {
        border-bottom-width: 2px;
      }

      .remove-item {
        opacity: 0;
        right: rem(-52);
        top: rem(0);
        cursor: pointer;

        svg {
          path {
            fill: $text-gray-variant;
          }
        }

        &:hover {
          svg path {
            fill: $danger;
          }
        }
      }

      .add-note {
        margin-top: rem(13);
        width: rem(16);
        cursor: pointer;
        opacity: 0;

        &:hover {
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }

    tr:hover {
      background: #fff;

      td {
        background: #fff;

        .add-note {
          opacity: 1;
        }

        .remove-item {
          opacity: 1;
        }
      }
      .invoice-row-handle {
        opacity: 1;
      }
    }
    .td-item-label-name {
      .item-name {
        section {
          width: 100%;
          cursor: pointer;
        }
        .dropdown-select {
          max-width: rem(200);

          .new-custom-item {
            height: rem(30);
            display: flex;
            align-items: center;
            color: $primary;

            span {
              margin-right: 5px;
            }
          }
          .max-170 {
            max-width: rem(170);
          }

          .dropdownSelect__control {
            min-height: auto;
            height: auto;
            min-width: rem(150);
            background: #fff;
            border: 0;
            @include border-radius(0);

            &.dropdownSelect__control--is-focused {
              @include box-shadow(0 1px $primary);
              border: none !important;
            }

            .dropdownSelect__indicators {
              display: none;
            }

            .dropdownSelect__value-container {
              height: rem(48);
              padding: 0;

              div {
                margin: 0 !important;
                padding: 0 !important;
              }
            }
          }
          .dropdownSelect__menu {
            min-width: rem(280);
            max-width: 100% !important;
            border: 1px solid $border-color;
            @include border-radius(6px);

            .dropdownSelect__option {
              &:hover {
                background: $tertiary;
              }
            }
          }

          .css-b8ldur-Input {
            margin: 0 !important;
            padding: 0 !important;
          }

          input {
            padding: 0 !important;
          }

          .css-1wa3eu0-placeholder {
            top: 49% !important;
          }
        }
      }

      .item-description {
        .item-description-text {
          padding-bottom: rem(7);
          display: block;
          color: $text-gray-variant;
        }

        input {
          text-align: left;
          margin: 0;
          padding: 0;
          color: $text-gray-variant;
          @include border-radius(0px);
          border: 0;

          &:focus {
            outline: none;
            border-bottom: 1px solid $primary;
          }
        }
      }
    }

    .td-item-label-qty {
      input {
        width: 100%;
        border: 0;
        text-align: right;
        padding: 0 rem(15) 0 0;
        height: rem(48);
        display: inline-block;
        @include border-radius(0);
        color: $text-gray-variant;

        &:focus {
          outline: none;
          border-bottom: 1px solid $primary;
        }
      }
    }

    .td-item-label-rate {
      span {
        color: $text-gray-variant;
        height: rem(48);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 rem(15) 0 0;
      }

      input {
        width: 100%;
        border: 0;
        text-align: right;
        padding: 0 rem(15) 0 0;
        height: rem(48);
        display: inline-block;
        @include border-radius(0);
        color: $text-gray-variant;

        &:focus {
          outline: none;
          border-bottom: 1px solid $primary;
        }
      }
    }

    .td-item-label-total {
      padding-top: rem(13);
    }

    .td-total-calculation,
    .td-add-item {
      height: rem(48);
      vertical-align: middle;
    }
  }

  .appointment-block {
    .due-date-value {
      .due-date-value-date {
        span {
          color: $text-gray-variant;

          &.date-added {
            color: $secondary;
          }
        }
      }
    }

    .css-1uccc91-singleValue {
      color: $secondary;
    }
  }
}

.invoice-row-handle {
  opacity: 0;
  @include transition(all 0.3s linear);
  left: rem(-30);
  width: rem(30);
  height: 100%;
  z-index: 1;
  cursor: grab;

  &:hover {
    svg g {
      fill: $primary;
    }
  }

  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.gu-mirror-invoice-table-row {
  background: #fff;

  &.gu-mirror {
    border: 1px solid $border-color;
    background: #fff;

    td {
      text-align: right;

      input {
        max-width: rem(60);
        padding: 0;
        text-align: right;
        border: none;
      }

      .remove-item {
        display: none;
      }
    }

    .td-item-label-name {
      width: 225px;
      text-align: left;
      padding-left: rem(15);

      .dropdownSelect__control {
        border: none !important;
        @include box-shadow(none !important);
      }

      .dropdownSelect__value-container {
        padding-left: 0;
      }

      .dropdownSelect__indicators,
      svg {
        display: none;
      }
    }

    .td-item-label-qty {
      width: 100px;
    }

    .td-item-label-rate {
      width: rem(98);
    }

    .td-item-label-total {
      text-align: right;
      min-width: rem(100);
    }
  }
}

.b-card-item-small {
  width: 40%;
}

.b-card-item-large {
  width: 60%;
}

.card-row-item-height {
  min-height: rem(44);
}

.b-add-reminder-time {
  border-bottom: rem(1) solid $border-color;

  &:nth-last-of-type(1) {
    border-bottom: none;
  }

  .add-reminder-time-field {
    width: rem(45);
  }
}

.button-add {
  @include size(rem(14));

  svg path {
    stroke: $primary;
  }
}

.button-remove {
  @include size(rem(15));
  min-width: rem(15);

  svg path {
    stroke: $danger;
  }
}

.service-slot-dropdown-section {
  .item-name {
    section {
      width: 100%;
      cursor: pointer;
    }
    .dropdown-select {
      max-width: rem(200);

      .new-custom-item {
        height: rem(30);
        display: flex;
        align-items: center;
        color: $primary;

        span {
          margin-right: 5px;
        }
      }

      .max-170 {
        max-width: rem(170);
      }

      .dropdownSelect__control {
        min-height: auto;
        height: auto;
        min-width: rem(150);
        background: #fff;
        border: 0;
        @include border-radius(0);

        &.dropdownSelect__control--is-focused {
          @include box-shadow(0 1px $primary);
          border: none !important;
        }

        .dropdownSelect__indicators {
          display: none;
        }

        .dropdownSelect__value-container {
          height: rem(48);
          padding: 0;

          div {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
      .dropdownSelect__menu {
        min-width: rem(280);
        max-width: 100% !important;
        border: 1px solid $border-color;
        @include border-radius(6px);

        .dropdownSelect__option {
          &:hover {
            background: $tertiary;
          }
        }
      }
      .css-b8ldur-Input {
        margin: 0 !important;
        padding: 0 !important;
      }

      input {
        padding: 0 !important;
      }

      .css-1wa3eu0-placeholder {
        top: 49% !important;
      }
    }
  }

  .item-description {
    .item-description-text {
      padding-bottom: rem(7);
      display: block;
      color: $text-gray-variant;
    }

    input {
      text-align: left;
      margin: 0;
      padding: 0;
      color: $text-gray-variant;
      @include border-radius(0px);
      border: 0;

      &:focus {
        outline: none;
        border-bottom: 1px solid $primary;
      }
    }
  }
}

.child-no-border-no-padd {
  .dropdownSelect__control {
    border: 0;
  }

  .dropdownSelect__value-container {
    padding: 0;
  }

  .css-b8ldur-Input {
    max-width: rem(120);
  }

  .dropdownSelect__control--is-focused {
    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: rem(1);
      background: $primary;
      bottom: rem(-2);
    }
  }
}

.dropdown-select-add-services {
  .dropdownSelect__indicators {
    display: flex !important;
    align-items: center;

    .dropdownSelect__indicator-separator {
      display: none !important;
    }

    .dropdownSelect__dropdown-indicator {
      padding: 0 !important;
      margin: 0 !important;
    }

    svg {
      display: block;
      fill: $secondary;
      width: rem(16);
      margin-top: rem(2);
    }
  }
}

.dropdown-select-time {
  max-width: none !important;
}

.b-icon-close {
  min-width: rem(18);
}

////////////////////////////////////
//////// MEDIA QUERY DOWN//////////
///////////////////////////////////
@include media-breakpoint-down(sm) {
  .custom-add-booking-form .table-add-booking td .remove-item {
    right: rem(-35);
  }

  .custom-add-booking-form .table-add-booking .td-item-label-name .item-name {
    .dropdown-select-add-services.dropdown-select {
      max-width: rem(180);

      .dropdownSelect__control {
        min-width: rem(130);
      }
    }
  }

  .custom-form-group-dropdown {
    .table tbody tr:last-child td,
    .table thead tr:last-child th {
      padding-right: rem(5);
    }

    .table tbody tr:first-child td,
    .table thead tr:first-child th {
      padding-left: rem(5);
    }
  }
}
.service_rate_label {
  padding-top: rem(13);
  color: $text-gray-variant;
}

.row-due-date {
  input {
    &::placeholder {
      color: $text-gray-variant;
    }
  }
}
