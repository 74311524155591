@include media-breakpoint-only(xl) {
    .is-header-sticky {
        left : rem(80);
        width: calc(100% - 80px);
    }

    .side-menu-open {
        .is-header-sticky {
            left : rem(250);
            width: calc(100% - 250px);
        }
        // .b-table-footer-btn {
        //     .footer-custom-button{
        //         font-size: rem(11);
        //     }
        // }
    }
} 
@include media-breakpoint-only(xxl) {
    .is-header-sticky {
      left : rem(80);
      width: calc(100% - 80px);
    }
    .side-menu-open {
        .is-header-sticky {
            left : rem(250);
            width: calc(100% - 250px);
        }
    }
} 