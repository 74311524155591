@import '@assets/scss/tools-index.scss';  
.send-invoice-success-popup {
  max-width: 950px;
}
.send-invoice-success-popup-without-ad {
  max-width: 550px;
}
.additional-info {
  font-size: 16px;
}

.tick-icon {
  fill: #ff00ff;
}