.main-header {
    height          : rem(68);
    background-color: white;
    .sidemenu-button {
        width  : rem(20);
        outline: none;
        cursor : pointer;

        .h-line {
            width           : rem(20);
            height          : rem(2);
            background-color: $secondary;
        }
    }

    .logo-block {
        max-width: rem(75);
        .image-logo {
            height: rem(24);
        }
    }

    // for upgrade pro plan screen
    &.upgrade-main-header {
        .logo-block {
            max-width: rem(100);
            .image-logo {
                height: rem(32);
            }
        }
    }
} 
