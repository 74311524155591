.advanced-dropdown{
    overflow:visible;
    max-width: rem(400);
    background-color: $body-bg;
    position: absolute;
    top: 100%;
    margin-top: rem(10);
    border: solid rem(1) $border-color;
    z-index:1;
    border-radius: rem(10);
    box-shadow: 0 6px 10px rgba(0, 64, 88, 0.15);
    right:0;
    .btn-apply,
    .cancel-btn  {
        display: block;
        width: 100%;
    }

    .icon-calendar {
        width: rem(16);
        cursor: pointer;
        top: 50%;
        right: rem(10);
        transform: translateY(-50%);
        @include opacity(0.5);
    }

    .form-control {
        cursor: pointer;
    }

    @include media-breakpoint-up(md) {
        min-width: rem(400);
        .btn-apply {
            display: inline-block;
        }
        .cancel-btn {
            display: inline-block;
            width: auto;
        }
    }
}