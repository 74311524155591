@include media-breakpoint-down(sm) {
  :global(#modalAddBooking .modal-content) {
    padding-left: rem(10);
    padding-right: rem(10);
  }
  .b-sideview-booking-list {
    max-height: rem(500);
  }
  .page-booking-calendar {
    .icon-plus-round {
      svg path {
        stroke: $secondary;
      }
    }
  }
}
