@include media-breakpoint-down(sm) {
  .is-enterprise-user {
    .main-wrapper {
      top: rem(80);
    }
  }

  // contact auto search
  .contact-auto-search {
    width: 100%;
  }

  //contact details activity height
  .c-details-activity-widget {
    .content {
      padding-top: rem(30);
    }
  }
  .custom-page-booking-calendar {
    .dropdown-select--custom {
      width: auto;
      .dropdownSelect__control {
        border: none;
      }
      .dropdownSelect__value-container {
        padding: 0;
        opacity: 0;
      }
      .dropdownSelect__placeholder {
        display: none;
      }
      .dropdownSelect__menu {
        width: rem(180);
        left: inherit;
        right: 0;
      }
      .dropdownSelect__input input {
        opacity: 0 !important; // For overwrite inline code
      }
    }
    .dropdownSelect__value-container {
      padding-right: rem(5);
    }
    .custom-bg-color {
      background-color: $bg-list;
      z-index: 0;
    }
    .calendar-filter-dropdown {
      background-color: transparent;
      z-index: 1;
    }
  }

}

@include media-breakpoint-down(xs) {
  .main-wrapper {
    &.main-wrapper-top-space-up {
      top       : rem(130);
    }
  }
  .payment-page-header {
    .btn-quickbooks {
      padding: 0 rem(15) !important;

      span,
      i {
        display: none !important;
      }
    }
  }
  // /////////////////////////////////////////////
  // /////////////////////////////////////////////
  // ///////////ENTERPRISE APP////////////////////
  // /////////////////////////////////////////////
  // /////////////////////////////////////////////
  .for-custom-modal-button {
    .modal .modal-body .custom-modal-close-button {
      right: rem(5);
    }
  }

}