@include media-breakpoint-up(lg) { 
    // create widget modal
    .create-widget-modal-wrapper {
        .create-widget-modal{
            margin-bottom: rem(-30);

            .b-scroll-box {
              // height    : calc(100vh - 210px);
              max-height: calc(100vh - 210px);
              overflow-y: auto;
              overflow-x: hidden;
              @include scrollbar;
            }
        }
    }
}