//notification
.notify-wrapper {
    position: fixed;
    left: 5%;
    bottom: rem(30);
    z-index: 9999;
}

.notification {
    background-color: $toaster-bg;
    border: solid rem(1) rgba($primary, 0.4);
    @include border-radius(rem(50));
    font-size: rem(12);
    color: $toaster-color;
    margin-bottom: rem(10);
    letter-spacing: -0.05em;

    .notification-icon {
        min-width: rem(26);
        @include size(rem(26), rem(26));
        display: inline-block;
        background-color: $toaster-light-bg;
        line-height: rem(26);
        color: white;
        font-size: rem(12);

        img {
            width: rem(12);
        }

        svg {
            width: rem(12);
            * {
                color: white;
                fill: white;
            }
        }
    }

    .close-notification-btn {
        padding-left: 0;
        font-size: rem(22);
        @include opacity(0.5);

        svg {
            fill: $gray-500;
            width: rem(19);
            height: rem(19);
        }

        &:hover svg {
            fill: $gray-700;
        }
    }

    .notification-content {
        font-family: $tertiary-font;

        > p {
            margin: 0;
        }
    }
}

.success-notification {
    .notification-icon {
        background-color: $success;
    }
}

.error-notification {
    .notification-icon {
        background-color: $danger;
    }
}

///Media Query
@include media-breakpoint-up(md) {
    .notify-wrapper {
        left: rem(20);
        bottom: rem(20);
    }

    .notification {
        font-size: rem(14);
        max-width: rem(500);

        .notification-icon {
            min-width: rem(45);
            @include size(rem(45), rem(45));
            line-height: rem(45);
            font-size: rem(20);
            left: rem(15);
            img {
                width: rem(17);
            }

            svg {
                width: rem(17);
                * {
                    color: white;
                    fill: white;
                }
            }
        }
    }
}
