.message-modal {
    .message-icon {
        @include opacity(0.5);
    }

    .form-control-textarea {
        min-height: rem(80);
    }

    .btn-attchment {
        border: rem(1) solid $border-color;

        &:hover {
            border: solid rem(1) $primary;
            color : $primary;
        }
    }

    .btn-close-custom {
        min-width : rem(26);
        height: rem(26);
        @include border-radius(rem(4));
        border: rem(1) solid $border-color;
        @include transition(all 0.3s linear);

        &:hover {
            border-color: $danger;

            svg path {
                fill: $danger;
            }
        }
    }

    .icon-close-custom {
        width: rem(10);
        height: rem(10);
        @include transition(all 0.3s linear);
    }

    .icon-attch-custom {
        min-width : rem(14);
        @include opacity(0.5);
        max-width: calc(100% - 40px);
        svg path {
            stroke: $secondary;
        }
    }
}

// Media Query
@include media-breakpoint-up(sm) {}