@include media-breakpoint-up(md) {

  .is-enterprise-user {

    // enterprise user header
    .main-header.enterprise-main-header {
      padding-top: 0;
      height     : rem(68);

      .b-custom-logo {
        top      : 50%;
        transform: translate(-50%, -50%);
      }

      .logo-block {
        margin-left  : 0;
        // max-width: rem(100);
        // max-height: rem(60);
      }
    }

    .main-wrapper.enterprises-wrapper {
      top: rem(80);
    }

    // end enterprise user header

    // location filter
    .b-location-filter {
      width: rem(200);

      &.b-width-location-filter,
      &.b-placement-location-filter {
        width: rem(160);

        .dropdown-menu {
          width: rem(200);
        }
      }

      .b-location-filter-content {
        width: rem(200);
      }
    }

    // end location filter
  }

  // Edit column modal
  .edit-column-modal-wrapper {
    .b-checkbox {
      border-right: rem(1) solid $border-color;
    }
  }

  // End Edit column modal

}

@media (min-width: 1024px) {

  // sticky header wrapper
  .enterprises-wrapper {
    .sticky-main-header-wrapper {
      min-height: rem(46);
      height    : auto;

      &--lg {
        height: rem(100);
      }
    }

    .custom-sticky-sub-header-wrapper {
      min-width : rem(46);
      min-height: rem(72);
      height    : auto;

      &.for-placement-listing {
        min-height: rem(45);
      }
    }

    .sticky-sub-header-wrapper {
      min-height: rem(75);
      height    : auto;
    }

    .feedback-sticky-sub-header-wrapper {
      min-height: rem(46);
    }

    .is-header-sticky {
      // left : rem(95);
      // width: calc(100% - 95px);
      width: 100%;
    }
  }

  .side-menu-open {
    .enterprises-wrapper {
      .is-header-sticky {
        left : rem(265);
        width: calc(100% - 265px);
      }
    }
  }

  .b-page-sub-header {
    width: 100%;
  }

  .b-page-sub-header.is-header-sticky {
    padding: rem(15) rem(30);
  }

  // for sticky header
  .enterprises-wrapper {
    .is-header-sticky {
      background    : white;
      display       : block;
      position      : fixed;
      top           : rem(114);
      right         : 0;
      z-index       : 1019;
      padding-bottom: rem(20);
      border-bottom : rem(1) solid $border-color;
      box-shadow    : rem(0) rem(6) rem(14) rgba(206, 211, 218, 0.2);
    }

    .sticky-main-header {
      margin-bottom: rem(20);

      &.is-header-sticky {
        top          : rem(68);
        margin-bottom: 0;
        padding      : rem(15) rem(30);
        border       : none;
      }
    }

    .custom-enterprise-page-wrapper {
      .sticky-main-header {

        &.is-header-sticky {
          z-index   : 1019;
          box-shadow: none;
        }
      }
    }
  }

  // end for sticky header

  .b-page-sub-header.is-header-sticky {
    top: rem(127);
  }

  .contact-overflow-x-scrollbar {
    @include scrollbar;
    overflow: hidden;
    overflow-x:auto;
  }

}