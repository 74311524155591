.icon-block {
  width: rem(30);
  height: rem(20);

  svg {
    width: rem(20);
    height: rem(20);
  }
}
.b-booking-view-filter {
  border: rem(1) solid $border-color;
  @include border-radius(rem(4));
}
// Add booking modal search select option avatar style
// .b-image{
//     min-width: rem(30);
//     width: rem(30);
//   }
// End add booking modal search select option avatar style
.add-service-modal {
  // /* Chrome, Safari, Edge, Opera */ for input number filed arrow remove
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  // /* Firefox */ for input number filed arrow remove
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .time-field {
    width: rem(44);
  }

  .is-resize-none {
    resize: none;
  }

  .widget-color-settings {
    z-index: 1;
    cursor: pointer;

    .color-picker-popover {
      // opacity   : 0;
      // visibility: hidden;
      z-index: 2;
      left: rem(-118);
    }

    // &:hover {
    //     .color-picker-popover {
    //         opacity   : 1;
    //         visibility: visible;
    //     }
    // }
  }

  .widget-color-picker {
    @include size(rem(24));
    min-width: rem(24);
    min-height: rem(24);
    border-radius: 50%;
  }

  .color-picker-label {
    display: inline-block;
    margin-bottom: rem(0);
  }

  .image-button-border {
    border: solid rem(1) $dark-gray;

    &:hover {
      border: solid rem(1) $primary;
    }
  }

  .user-dropdown-list-item .list-item-text {
    max-width: calc(100% - 24px);
  }

  .service-image {
    @include size(rem(24));
    min-width: rem(24);

    .image-figure {
      margin-bottom: 0;
      height: 100%;

      .image-cover {
        object-fit: cover;
        width: 100%;
        height: 100%;
        @include border-radius(rem(4));
      }
    }
    &.enlarge-image {
      @include size(rem(60));
    }
  }

  .b-enlarge-image {
    .service-image.enlarge-image {
      @include size(rem(150));
      bottom: 100%;
      border: rem(5) solid white;
      bottom: 100%;
      @include visibility(hidden);
      @include transition(all 0.2s linear);
    }
    &:hover {
      .service-image.enlarge-image {
        @include visibility(visible);
      }
    }
  }

  .icon-styles {
    width: rem(40);
    cursor: pointer;
  }

  .btn-trash {
    top: rem(-6);
    right: rem(-6);
    background: white;
    @include size(rem(20));
    border: rem(1) solid $border-color;
    @include visibility(hidden);
  }

  .image-trash {
    @include size(rem(10));
    svg {
      path {
        stroke: $danger;
      }
    }
  }

  .sp-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    .sp-preview-inner {
      position: absolute;
      width: 28%;
      height: 97%;
      border-radius: 50%;
      background-color: white;
    }
  }

  .color-picker {
    display: none;
  }

  .cover {
    background-color: white;
    background-clip: padding-box;
    border: rem(1) solid rgba(0, 0, 0, 0.15);
    border-radius: rem(6);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  }

  .b-partial-payment-option {
    min-width: 100%;
  }

  .icon-export {
    width: rem(18);

    svg {
      width: 100%;

      path {
        stroke: $secondary;
      }
    }
  }

  // Updated design styles
  .b-card-item-small {
    width: 40%;
  }

  .b-card-item-large {
    width: 60%;
    min-height: rem(44);
    .disabled-dropdown {
      pointer-events: none;
    }
  }

  .accordion-card-header {
    background-color: transparent;
  }

  .card-row-item-height {
    min-height: rem(44);
  }

  .b-add-reminder-time {
    border-bottom: rem(1) solid $border-color;

    &:nth-last-of-type(1) {
      border-bottom: none;
    }

    .add-reminder-time-field {
      width: rem(45);
    }
  }
  .button-add {
    @include size(rem(14));

    svg path {
      stroke: $primary;
    }
  }
  .button-remove {
    @include size(rem(15));
    min-width: rem(15);

    svg path {
      stroke: $danger;
    }
  }

  .b-profile-image {
    &:hover {
      .btn-trash {
        @include visibility(visible);

        &:hover {
          background-color: $danger;

          .image-trash {
            svg {
              path {
                stroke: white;
              }
            }
          }
        }
      }
    }
  }
  .field-normal-width {
    width: auto;
    max-width: rem(90);
  }
  .icon-dropdown-arrow {
    min-width: rem(16);
  }
  .private-label-text {
    color: $light-text-color;
  }
  .small-dropdown {
    min-width: rem(50);
  }

  .form-loader {
    background: rgba(white, 0.7);
  }

  .custom-placeholder-color {
    color: $secondary;
    @include placeholder($text-gray-variant, 1);
  }

  // End updated design styles
}

// service view filter
.b-service-view-filter {
  border: rem(1) solid $border-color;
  @include border-radius(rem(4));
}
// end service view filter
//Booking list page start
.message-main {
  width: rem(100);
  @include text-truncate();
}
.pointer {
  cursor: pointer;
}
.outline-icon {
  width: rem(30);
  height: rem(30);
  cursor: pointer;
  @include border-radius(rem(4));

  .mob-icon,
  .email-icon {
    @include opacity(0.7);
  }
}
.list-table-tooltip {
  min-width: rem(163);
  cursor: pointer;
}
.page-list {
  .custom-date-picker {
    font-size: rem(12);
    height: rem(40);
    width: 100%;
    background-size: rem(22);
  }
  .btn-switch svg {
    rect {
      stroke: $secondary;
    }
    path {
      fill: $secondary;
      &:nth-last-of-type(5),
      &:nth-last-of-type(6) {
        stroke: $secondary;
      }
    }
  }
  .status-dropdown {
    width: 100%;
    font-size: rem(12);
  }
  .date-picker {
    width: 100%;
    input {
      padding: rem(6);
      height: rem(38);
    }
  }
  .booking-status {
    font-family: $secondary-bold-font;
    font-size: rem(12);
  }
  .list-table-tooltip {
    min-width: rem(163);
    cursor: pointer;
  }
  .icon-export {
    width: rem(18);
    svg {
      width: 100%;
      path {
        stroke: $secondary;
      }
    }
  }
}
//Booking list page end
//Booking details page start
.b-history-data {
  .item {
    list-style: none;
    padding-bottom: rem(40);

    &:last-child {
      padding-bottom: 0;
    }

    // border-left: solid rem(1) #EDF1F5;
    .booking-icon {
      left: rem(-12);
      top: 50%;
      transform: translateY(-50%);
      // width: rem(48);
      // height: rem(48);
    }

    .item-title {
      font-family: $open-sans-regular;
    }

    .item-details {
      font-size: rem(12);
    }
  }
}
.details-page {
  .icon-information {
    width: rem(16);
    height: rem(16);
    svg {
      @include opacity(0.4);
    }
  }
  .icon-fill-calendar {
    width: rem(12);
    height: rem(12);
    svg path {
      fill: white;
    }
  }
  .b-information-tooltip {
    min-width: rem(180);
    .description-text {
      font-family: $primary-font;
    }
  }
  .complete-transaction-icon {
    width: rem(15);
    svg path {
      stroke: white;
    }
  }
  .booking-history-close-icon {
    svg {
      path {
        stroke: $primary;
      }
    }
  }
  .status {
    font-size: rem(12);
    // font-family: $secondary-bold-font;
  }

  .cancel-btn {
    border: rem(1) solid $border-color;

    &:hover {
      border: solid rem(1) $danger;
      color: $danger;
    }
  }

  .cancel-icon-wrap {
    width: rem(14);
    height: rem(14);
    color: $secondary;

    svg path {
      stroke: $danger;
    }
  }

  // .edit-booking-btn {
  //     color: $secondary;
  //     border: rem(1) solid $border-color;

  //     &:hover {
  //         border: solid rem(1) $primary;
  //         color : $primary;
  //     }
  // }
  .edit-icon-wrap {
    width: rem(14);
    height: rem(14);
    color: $primary;

    svg path {
      stroke: $primary;
    }
  }

  .confirm-btn {
    border: rem(1) solid $border-color;

    &:hover {
      border: solid rem(1) $success;
      color: $success;
    }
  }
  .confirm-icon-wrap {
    width: rem(14);
    height: rem(14);
    color: $secondary;

    svg path {
      stroke: $success;
    }
  }

  .total-due {
    font-size: rem(24);
    color: $danger;
  }
  .total-amount {
    font-size: rem(24);
    color: $primary;
  }

  .paid-amount {
    font-size: rem(24);
    color: $success;
  }
  .booking-icon {
    height: rem(18);

    svg {
      display: block;
      height: 100%;

      rect {
        stroke: $primary;
      }

      path:nth-child(2),
      path:nth-child(3) {
        stroke: $primary;
      }
    }
  }
}
.b-image {
  min-width: rem(30);
  min-height: rem(30);
  height: rem(30);
  width: rem(30);
  .image {
    object-fit: cover;
    height: 100%;
  }
}
//Booking details page end

@media only screen and (min-width: 1024px) and (max-width: 1110px) {
  :global(.side-menu-open .btn-booking-custom i) {
    margin: 0 !important;
  }
  :global(.side-menu-open .btn-booking-custom span) {
    display: none !important;
  }
}
