.tab-wrapper{
    margin-bottom: rem(30);
}
.tab-header{
    margin-bottom: rem(30);
}
.tab-content{
    border: rem(1) solid $border-color;
    @include box-shadow( $box-shadow );
    padding: rem(20);
    @include border-radius(rem(6));
    background:white;
}
.tab-item{
    border: rem(1) solid rgba($border-color, .5);
    @include border-radius($border-radius $border-radius rem(0) rem(0));
    background:white;
    font-size: rem(14);
    display: block;
    height: rem(50);
    line-height: rem(50);
    padding: 0 rem(15);
    @include transition(color 0.2s linear);
    @include border-radius(rem(6));
    color: $body-color;
    &:hover {
        text-decoration:none;
        color: $secondary;
    }
}
.tab-item-active{
    font-family: $secondary-bold-font;
    color: $secondary;
    border-color:$secondary;
}

//tabs for small screens
.tab-header.tab-header-xs{
    @include media-breakpoint-down(sm) { 
        margin-bottom:rem(-1);
        .tab-item{
            padding: 0 rem(20);
            display: inline-block;
            @include border-radius(rem(6) rem(6) 0 0); 
        }
        .tab-item-active{
            border-color:$border-color;
            border-bottom-color:$body-bg;
        }
    }
    @media (max-width: 360px){
        .tab-item{
            padding: 0 rem(15);
        }
    }
}

// ///////////////
// MEDIA QUERY///
// /////////////
@include media-breakpoint-up(sm) { 
    .tab-header{
        margin-bottom:rem(-1);
    }
    .tab-content{
        padding: rem(30);
        @include border-radius(0 rem(6) rem(6) rem(6));
    }
    .tab-item{
        padding: 0 rem(30);
        display: inline-block;
        @include border-radius(rem(6) rem(6) 0 0);
    }
    .tab-item-active{
        border-color:$border-color;
        border-bottom-color:$body-bg;
    }
    .tab-header.has-long-tab-header {
        .tab-item{
            display: block;
            @include border-radius(rem(6));
        }
        .tab-item-active{
            border-color:$secondary;
        }
    }
}

@include media-breakpoint-up(md) { 
    .tab-header.has-long-tab-header {
        .tab-item{
            padding: 0 rem(15); 
            display: inline-block;
            @include border-radius(rem(6) rem(6) 0 0);
        }
        .tab-item-active{
            border-color:$border-color;
            border-bottom-color:$body-bg;
        }
    }
}

 