@import '@assets/scss/tools-index.scss';
.warning {
  display: inline-flex;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
  background: #fff3f2;
  border-radius: 6px;
  padding: 10px 12px 10px 12px;

  > svg {
    width: rem(20);
    height: rem(20);
    color: #de3121;
  }

  > span {
    font-family: "Open Sans Regular";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;

    color: #de3121;
  }
}