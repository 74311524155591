@include media-breakpoint-up(md) {
    .b-review-nodata {
        min-height: rem(420);
    }

    .page-reviews-list {

        .custom-right-block-review {

            .advanced-dropdown {
                width: rem(400);
            }
        }

    }

    .b-list {

        .list-item-google,
        .list-item {
            .reviews-list-top {
                .right-content {
                    .delete-button {
                        @include visibility(hidden);
                    }

                }
            }

            &:hover {
                .reviews-list-top {
                    .right-content {
                        .delete-button {
                            @include visibility(visible);
                        }
                    }
                }
            }
        }
    }

    // ////////////// trial preview page
    .page-review-trial-preview {
        .b-header {
            .title-text {
                font-size: rem(20);
            }

            .sub-title-text {
                font-size: rem(24);
            }
        }

        .section-trial-list {
            .icon-badge {
                left: rem(10);
            }
        }

        .section-trial-testimonial {
            padding: rem(30) rem(60);
        }
    }

    // ////////////// end trial preview page
}