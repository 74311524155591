@include media-breakpoint-up(lg) { 
    .page-list {
        .table-sortable-wrapper {
            tr th {
                &:first-of-type{
                    width: rem(300);
                }
            }
            &.table-sortable-specific-wrapper {
                table {
                    table-layout: fixed;
                    tr th {
                        &:first-of-type{
                            width: auto;
                        }
                        &:last-of-type{
                            width: rem(120);
                        }
                    }
                }
            }
        }
    }
}