.b-card-status {
    .h-list {
        &--tab-menu{
            .h-list-item {
                position: relative;
                padding: 0 rem(5);
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: rem(-5);
                    width: 100%;
                    height: rem(2);
                    display: block;
                    background-color: $white;
                    @include visibility(hidden);
                }
                &.active{
                    color: $secondary;
                    &::after{
                        background-color: $primary;
                        @include visibility(visible);
                    }
                }
            }            
        }
        .h-list-item {
            list-style-type: none;
            font-size: rem(14);
            color: $grey-variant;
            margin-right: rem(30);
            vertical-align: middle;
            &:last-child {
                margin-left: 0;
            }
            &.active{
                .count {
                    color: $primary;
                }
            }
        }
        .no-count,
        .count {
            font-size: rem(20);
            margin-right: rem(5);
            vertical-align: middle;
            font-family: $headings-font-family;
        }

    }
    .review-star { 
        font-family: inherit;
    }
    .content-list {
        .b-status-list {
            max-height: rem(277);
            min-height: rem(270);
            overflow-y: auto;
            overflow-x: hidden;
            @include scrollbar;
            padding: 0 0 0 rem(28);
        }
        .status-list-item {
            list-style-type: none; 
            padding-bottom: rem(40); 
            position: relative;
            padding-left: rem(20);
            padding-top: rem(3);
            &::before{
                content: "";
                position: absolute;
                width: rem(1);
                height: 100%;
                top: 0;
                left: rem(-16);
                background-color: $border-color;
                z-index: 1;
            }
            a{
                color: black;
            }
            a:hover{
                text-decoration: none;
                color: #0258ff;
            } 
        }
        .status-list-item-end {
            list-style-type: none;  
            position: relative;
            padding-left: rem(20);
            &::before{
                content: "";
                position: absolute;
                width: rem(1);
                height: 100%;
                top: 0;
                left: rem(-16);
                background-color: $border-color;
                z-index: 1;
            }
            .list-icon { 
                top: rem(-12);
            }
        }
        .list-icon { 
            position: absolute;
            top: 0;
            left: rem(-28);
            width: rem(28);
            height: rem(28+14);
            display: flex;
            align-items: center;
            background: white;
            z-index: 1;
            img,
            svg {
                width: 100%;
                height: rem(28);
                object-fit: contain;
            }
        }
        .drop-down-span {
            margin-left: rem(10);
        }
        .status-title {
            font-size: rem(14);
            color: $secondary;
            font-family: $font-family-base;
        }
        .time {
            display: block; 
            font-size: rem(12);
            color: $light-text-color;
        }
        .finished {
            list-style-type: none;
        }
        .send-dropdown-action {
            outline: none;
            &:after {
                display: none;
            }
        }
        .dropdown-title {
            font-family: $secondary-bold-font;
            margin-bottom: rem(15);
        }
        .dropdown-menu {
            width: rem(320);
            text-align: center;
        }
    }
}