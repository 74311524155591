@include media-breakpoint-up(xl) { 
    .main-wrapper{
        &.enterprises-wrapper{
            padding-left: rem(28);
            padding-left: (rem(25));
        }
       
    }
    .enterprices-sidemenu-panel {
        left: 0;
    }
    .side-menu-open {
        .enterprices-sidemenu-panel {
            left      : 0;           
        } 
        .main-wrapper {
            &.enterprises-wrapper {
                main.main {
                    padding-left: rem(220);
                }
            }
        }
    }
    .main-wrapper {
        &.enterprises-wrapper {
            padding-left: rem(28);
        }
    }
}
@media (min-width: 1500px) {
    .main-wrapper {      
        main.main {
            left:rem(0);
        }
    }
    .side-menu-open {
        .main-wrapper.enterprises-wrapper {
            main.main {
                left:rem(0);
                padding-left:rem(222);
            }
        }
    }
}