@import "@assets/scss/tools-index.scss";

.payment-instruction-amount {
  > div:nth-child(2) {
    width: 226px;
    text-align: center;
  }
}

.payment-instruction-contact {
  > div:first-child {
    width: 100%;
    padding-left: 3rem;
  }

  > div:nth-child(2) {
    width: 226px;
    text-align: center;
  }
}
