@include media-breakpoint-down(sm) {
    .b-inner-right {
        width: 100%;
    }
    // payment details page header
    .page-payment-details {
        .btn-header,
        .btn-resend-receipt {
            font-size: rem(12);
        }
    }
    // end payment details page header

    .payment-landing-header {
        display: none;
    }

    .payment-landing-page {
        border: none;
        box-shadow: none;
        
        .landing-main {
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 3rem;
            justify-content: center;
            align-items: center;

          .header {
            padding-bottom: 1.5rem;
          }

          .card {
              width: 100%;
              margin: 0;
              margin-bottom: 1rem;
              justify-content: center;
              padding: 0;

      
            .top-circle {
              display: none;
            }
            .bottom-circle {
              display: none;
            }
      
            .info {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin: 0;
                padding: 10px;
                height: 100%;
      
              .text-block {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
              }
      
            }
          }
        }
      }
      .invoice-unlocked-list-item {
    
    
        .card:before {
            display: none;
        }

        .card {

          .info {
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            
            .icon {
                margin-bottom: 1rem;
            }
    
            .text {
                margin-bottom: 1rem;
            }
          }
    
        }
    }
}