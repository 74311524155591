@include media-breakpoint-up(xl) {
  // calendar page
  .b-calendar-view {
    .b-sideview-booking-list {
      // calendar sideview booking list
      box-shadow: none;
    }
    .b-calendar-side,
    .rbc-toolbar-custom {
      width: calc(100% - 400px);
      @include transition(all 0.3s linear);
    }
  }
  // End calendar page
}
