@include media-breakpoint-down(sm) {
  .recent-review-widget-wrapper {
    .review-list-wrapper {
      max-height: rem(420);
      overflow: hidden;
      overflow-y: auto;
      @include scrollbar;
    }
  }
}
