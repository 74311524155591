@include media-breakpoint-up(sm) {
    .modal {

        .remove-btn,
        .cancel-btn {
            font-size: rem(14);
        }
        .modal-dialog {
            width: calc(100% - (1.75rem * 2));
        }
    }

}