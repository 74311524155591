@include media-breakpoint-up(lg) { 
   
    .notification-outer-wrapper .notification-table{
        th{
            text-align: center;
            i{
                margin-left: rem(5);
            }
            &:first-child{
                text-align: left;
                padding-left: rem(20);
            }
        }
        td{
            text-align:center;
            border-bottom: rem(1) solid $dark-gray;
            padding-top: rem(18);
            padding-bottom: rem(18);
            padding-left: rem(20);
            .form-group{
                margin:0;
                label{
                    margin: rem(2) 0 0;
                }
            }
            &:first-child{
                text-align: left;
            }
        }
    }

    .menu-list{
        .menu-list-item{                  
            padding: rem(13) rem(15) rem(13) rem(35);
            position: relative;
            .menu-title{
                margin: rem(0) rem(13);
            }
           
        }
    }

}


