
    .b-profile {
        background-color: transparent;
        .button {
            background-color: transparent;
            &:after {
                display: none;
            } 
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
        .image {
            width: rem(28);
            height: rem(28);
        } 

        // Media Query
        @include media-breakpoint-up(md) { 
            .image {
                width: rem(40);
                height: rem(40);
            } 
        }
    }