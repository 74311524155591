
// custom date picker button
.calendar-input-wrapper {
    .date-picker-button {
        font-family: $primary-font;
        padding-right: rem(70);
    }
    .b-icons {
        right: rem(15);
        top: 50%;
        transform: translateY(-50%);
    }
    .icon-calendar {
        width: rem(16);
        height: rem(16);
        @include opacity(0.5);
    }
    .icon-close {
        font-size: rem(24);
        @include opacity(0.5);
        @include transition(all 0.3s linear);
        &:hover {
            @include opacity(1);
        }
    }
}
  // end custom date picker button