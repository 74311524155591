$font-size-base: 14; // no need to add 'px' here

//================////================//
// Themeing start  //
//================////================//
//Main Colors - Class names example .bg-primary, .text-primary, .border-primary
//=======================
$primary  : #0258ff; //(variable name is $primary) //this is gosite primary color (updating with bootstrap primary from _dynamic-variables.scss)
$secondary: #082344; //this variable same as #082244, (variable name is $secondary) //this is gosite primary color (updating with bootstrap primary from _dynamic-variables.scss)
$tertiary : #edf1f5; //(this variable same as #EDF1F6) //this is gosite $tertiary color // using bg of table th
//================////================//
// Themeing end  //
//================////================// 

//================////================//
// Usable variable start
//================////================//

// developers can use these pre defined variable
//==============
//$primary
//$secondary
//$tertiary (Its medium gray color)

//gray colors
//============
$light-gray  : #f8f9fb; // - form bg, code block bg
$dark-gray   : #dbe3eb; // (this variable same as #dfe6ed) -  border
$darker-gray : #ABB5BB;

//Border colors
//============
//$border-color ( $dark-gray )
$border-color-light: #EDF1F5;

//Text Colors
//=======================
//$secondary - variable is main text color
$light-text-color: #576c77; // light text color, mainly use for sub text , body color

//Alert variable
//====================
$danger : #ea4335;
$success: #5cb85c;
$warning: #ffb000;
$info   : #0b889d;

//Fonts
//==============
$primary-font          : $open-sans-regular; //using for all paragraph
$primary-light-font    : $open-sans-light; //font-weight thin
$secondary-light-font  : $open-sans-light; //font-weight thin
$secondary-medium-font : $open-sans-medium; //font-weight medium
$primary-bold-font     : $mont-bold; //asper design using this font for master title (h1, h2)
$primary-regular-font  : $mont-regular;
$secondary-bold-font   : $open-sans-bold; //asper design  all bold text except master title
$tertiary-font         : $open-sans-demi-bold;

// Heights (Mainly its using for button and input heights)
//=====================
$height-xs: 26;
$height-sm: 36;
$height-md: 40;
$height-lg: 44;
$height-xl: 50;

// table th/height
//=====================
$thead-height   : $height-md;
$thead-height-sm: $height-lg;

//================////================//
// Usable variable end //
//================////================//

//================////================//
// Re Usable variable start //
//================////================//
$link-color: $primary;
$form-bg   : $light-gray;

//================////================//
// Re Usable variable end //
//================////================//

//================////================//
// Custom variable start //
//================////================//
$favourite-color          : #f1c118;
$folder-active-color      : #08b2fa;
$warningbox-bg-color      : #ffefbc;
$media-color              : #f0f1f2;
$alert-note-background    : #f4fbfe;
$edit-icon-grey           : #8390a1;
$progress-bg              : #e6ecf1;
$bg-progressbar           : #f0f0f0;
$date-time-highlight      : #f1c118;
$date-time-text           : #286090;
$review-list-background   : #fdf9e8;
$bg-list                  : #fbfdff;
$border-list              : #f8f9fb;
$icon-gray                : #aeb8bc;
$icon-dark                : #4f5e65;
$warningbox-border-color  : #ffd756;
$warningbox-color         : #bc892e;
$grey-background          : #f7f9fb;
$btn-disabled-gray        : #d1d7de;
$select-icon              : #545d6b;
$toaster-light-bg         : #cccccc;
$lighter-color            : #cccccc;
$toaster-bg               : #f0faff;
$toaster-color            : #5d6164;
$color-gary-variant       : #363c46;
$unauth-bg-color          : #0787c9;
$light-blue-border        : #54b0df;
$btn-close-color          : #c6c6c6;
$dark-ash-color           : #839BA6;
$light-ash-color          : #E0EBF0;
$light-reddish            : #9a9a9a;
$alert-link-color         : #1b76a4;
$background-code          : #F4F5F7;
$lighter-bg-color         : #e2ecff;
$lighter-blue             : #c6d9ff;
$text-lighter-gray        : #798c99;
$text-gray-variant        : #8290a2;
$text-off-color           : #5d6061;
$calender-highlight-color : #f8e08b;
$list-bg-color            : #f5f5f5;
$grey-variant             : #abb5bb;
$bg-grey                  : #dde2e4;
$bg-green                 : #54AB31;
$highlight-yellow         : #ecbf00;
$focus-orange             : #e86100;
$status-green             : #57AB31;
$rare-grey                : #72808e;
$off-grey                 : #f6f6f6;
$gray-light               : #8290A1;
$offline-color            : #b4becb;
$color-cyan               : #14bbce;
$off-white                : #ced3da;
$grey-blue                : #c6c6cb;
$success-alert            :#DAEFD1;
$color-picker             : #f2f2f2;
$success-alert            : #DAEFD1;
$date-picker-today        :#dbe3eb5e;
$bg-grey-variant          : #edf0f2;
$radio-btn-border         : #dde0e4;
$grey-icon                : #aeb8bd;
$off-secondary            : #545454;
$google                   : #4285f4;
$grey-shade               : #cccccc;
$blue-shade               : #f2fafe;
$confirm-green            : #8DC150;
$alert-info               :#E2ECFF;
$location-bg              :#d4d4d41a;
$location-avatar-bg       :#F1EFF0;
$grey-gradient            :#ABB4B9;
$action-center-complete   :#409C1C;
$light-blue-background    :#F7F9FC;
$light-white              :#FFFDFD;
$danger-light-bg          :#F9D7D4;
//================////================//
// Custom variable end //
//================////================// 


//================////================//
// Brand color variable start //
//================////================//
$facebook-color: #3b5998;
//================////================//
// Brand color variable end //
//================////================//

//================////================//
// Layout settings //
//================////================//
$sidebar-width: rem(250);
//================////================//
// Brand color variable end //
//================////================//

//================////================//
// Grid gutter spaces //
//================////================//
$grid-gutter-width-least : 5px;
$grid-gutter-width-xs    : 10px;
$grid-gutter-width-sm    : 16px;
$grid-gutter-width-md    : 20px;
$grid-gutter-width-lg    : 24px;
$grid-gutter-width       : 30px;

//================////================//
// S3 //
//================////================//
$s3: "https://dufzo4epsnvlh.cloudfront.net"