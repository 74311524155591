@include media-breakpoint-up(lg) {
}

@media (min-width: 1024px) {
    .side-menu-open {
        .main-wrapper.enterprises-wrapper {
            main.main {
                left: 0;
                padding-left: $sidebar-width;
            }
        }
    }
    .enterprises-wrapper {
        .is-header-sticky {
            left: 0;
        }
    }
}