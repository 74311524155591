////////////////////////////////////////////
// Collapse/Accordion
// Package: https://www.npmjs.com/package/rc-collapse
////////////////////////////////////////////
  
.rc-collapse-anim-active {
    @include transition(height 0.2s ease-out);
} 
.rc-collapse-content-inactive {
    display: none;
}
 

.c-collapse{
    border-bottom: rem(1) solid $border-color;
    outline: none;
    &:last-child{
        border-bottom: none;
    } 
    .rc-collapse-header{
        outline: none;
        background-color: #fafafa;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: rem(10) rem(15);
        cursor: pointer;
        font-size: rem(14);
        .arrow {
            display: inline-block;
            position: relative;
            top: rem(2);
            content: ''; 
            width: 0;
            height: 0;
            border-style: solid;
            border-width: rem(5) rem(4) 0 rem(4);
            border-color: currentColor transparent transparent transparent;
            margin-right: rem(10);
            @include transition(all 0.3s linear);
        } 
    } 
    .rc-collapse-content{
        overflow: hidden;
        background-color: #fff;
    }
    .rc-collapse-content-box{
        padding: rem(10) rem(15);
    }
    .rc-collapse-item-disabled > .rc-collapse-header {
        cursor: not-allowed;
        color: #999;
        background-color: #f3f3f3;
    }
    .rc-collapse-item-active > .rc-collapse-header .arrow {
        @include transform(rotate(-180deg));     
    }
}