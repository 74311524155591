.b-pagination {
    .pagination-item {
        min-width  : rem(30);
        height     : rem(30);
        color      : $dark-ash-color;
        cursor     : pointer;
        font-size  : rem(12);
        font-family: $secondary-bold-font;

        &:hover {
            background: $light-ash-color;
        }
    }

    .pagination-item.active {
        min-width : rem(30);
        height    : rem(30);
        background: $primary;
        color     : white;
        @include border-radius(50%);
    }

    .pagination-arrow {
        width : rem(30);
        height: rem(30);
        cursor: pointer;

        &:hover {
            background: $light-ash-color;
        }
    }

    .disabled {
        cursor: not-allowed;

        .pagination-arrow {

            &:hover {
                background: transparent;
                cursor    : not-allowed;
            }
        }
    }

}