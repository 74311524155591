.search-select {
    .globalSelect__indicators{
        -webkit-align-items: flex-end;
        -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
        align-items: flex-end;
        margin-bottom: rem(11);
    }
    .globalSelect__control {
        height      : rem(44);
        border-color: $border-color;

        &:hover {
            border-color: $border-color;
        }
    }

    .globalSelect__value-container {
        padding-left : rem(15);
        padding-right: rem(15);
    }

    .globalSelect__placeholder {
        color: rgba($secondary, 0.8);
    }

    .globalSelect__indicator-separator {
        display: none;
    }
    
    .globalSelect__dropdown-indicator {
        margin-right: rem(15);
        background  : url('https://dufzo4epsnvlh.cloudfront.net/image/icon-new/search-icon.svg')no-repeat;

        svg {
            display: none;
        }
    }

    .globalSelect__multi-value {
        background: $body-bg;
        border    : rem(1) solid $border-color;
        @include border-radius(rem(3));

        &:hover {
            border-color: $primary;
            .globalSelect__multi-value__label {
                font-family: $secondary-bold-font;
            }
            .globalSelect__multi-value__remove {
                @include visibility(visible);
                cursor: pointer;
                @include transition(all 0.3s linear); 
                svg {
                    fill: $primary;
                }
                &:hover {
                    background: transparent;
                }
            }
        }
    }
    .globalSelect__clear-indicator {
        display: none;
    }

    .globalSelect__multi-value__remove {
        @include visibility(hidden);
    }
}