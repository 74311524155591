@include media-breakpoint-up(md) {
  // service view filter
  .b-service-view-filter {
    border: none;
    @include border-radius(rem(0));
  }
  // end service view filter
  .page-list {
    .date-picker {
      width: rem(215);
    }
  }
  .b-booking-view-filter {
    border: none;
    @include border-radius(rem(0));
  }
  .service-list-dropdown {
    max-width: rem(160);
  }
}
