.media-carousel-wrapper {
    .modal {
        background: rgba(255, 255, 255, 0.8);

        .modal-body {
            padding: rem(10);
            .custom-modal-close-button {
                top        : rem(20);
                right      : rem(20);
                background : rgba(black, 0.8);
                width      : rem(24);
                height     : rem(24);
                line-height: rem(22);
                border     : none;
                color      : white;
                text-shadow: none;
                z-index    : 2;
                @include opacity(0.6);
                @include transition(all 0.3s linear);

                span {
                    width     : rem(15);
                    height    : rem(15);
                    margin-top: rem(4);
                }
            }

            .modal-body {
                padding: rem(10);
            }

            .modal-content {
                border    : none;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
            }
        }
    }

    .slick-slider {

        .slick-arrow {
            z-index: 1;
        }

        .slick-prev {
            left: 0;

            &:after {
                content          : "";
                margin-top       : rem(12);
                margin-left      : rem(6);
                border           : solid white;
                border-width     : 0 rem(3) rem(3) 0;
                display          : inline-block;
                padding          : rem(4);
                transform        : rotate(135deg);
                -webkit-transform: rotate(135deg);
                position         : relative;
                left             : rem(-2);
            }
        }

        .slick-next {
            right: 0;

            &:after {
                content          : "";
                margin-top       : rem(12);
                margin-right     : rem(6);
                border           : solid white;
                border-width     : 0 rem(3) rem(3) 0;
                display          : inline-block;
                padding          : rem(4);
                transform        : rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                position         : relative;
                right            : rem(-1);
            }
        }

        .slick-prev,
        .slick-next {
            background     : rgba(0, 0, 0, .6);
            color          : white;
            font-size      : 0;
            border-radius  : 0;
            width          : rem(30);
            height         : rem(40);
            line-height    : rem(26);
            display        : flex;
            justify-content: center;
            padding        : 0 !important;
            margin         : rem(-15) 0 0;
            @include transition(all 0.3s linear);

            &:before {
                display: none;
            }

            &:hover {
                background: black;
            }

            &.slick-disabled {
                @include visibility(hidden);
            }
        }

        .b-slide-item {
            position: relative;
            z-index : 0;
        }

        .b-footer-buttons {
            z-index: 1;
        }
    }

    .b-media-description {
        max-width: calc(100% - 100px);
    }

    .media-attached-by {
        font-size: rem(14);
    }

    .btn-media-action {
        @include border-radius(rem(3));
        border: rem(1) solid $folder-active-color;
        @include transition(all 0.3s linear);

        &:hover {
            background: $folder-active-color;
        }
    }

    .icon-media-download {
        width : rem(10);
        height: rem(12);
    }

    .btn-media-download {
        padding: 0 rem(7);

        svg path {
            fill: $folder-active-color;
        }

        &:hover {
            svg path {
                fill: white;
            }
        }
    }

    .btn-target-blank {
        @include size(rem(26));

        svg path {
            stroke: $folder-active-color;
        }

        &:hover {
            svg path {
                stroke: white;
            }
        }
    }
}