.table {
    border         : rem(1) solid $border-color;
    border-spacing : 0;
    border-collapse: inherit;

    tr {

        th,
        td {
            vertical-align: middle;
        }
    }

    .table-link {
        // cursor: pointer;
    }

    thead {
        th {
            font-family: $headings-font-family;
            font-size  : rem(12);
            border     : 0;
            padding    : rem(6) rem(5);
            height     : rem($thead-height);
            color      : $light-text-color;
            font-weight: 400;
            white-space: nowrap;

            &:first-child {
                padding-left: rem(20);
            }

            &:last-child {
                padding-right: rem(20);
            }
        }

        &.thead-sm {
            th {
                height: rem($thead-height-sm);
            }
        }
    }

    tbody {
        tr {
            td {
                border       : 0;
                border-bottom: rem(1) solid $border-color;
                padding      : rem(12) rem(5);
                font-size    : rem(14);
                color        : $secondary;

                &:first-child {
                    padding-left: rem(20);
                }

                &:last-child {
                    padding-right: rem(20);
                }
            }

            &:hover td {
                background: $bg-list;
            }
            &:last-child {
                td {
                    border: none;
                }
            }
        }
    }

    &.rounded {
        thead {
            tr {
                th:first-child {
                    @include border-radius($border-radius 0 0 0);
                }

                th:last-child {
                    @include border-radius(0 $border-radius 0 0);
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    @include border-radius(0 0 0 $border-radius);
                }

                td:last-child {
                    @include border-radius(0 0 $border-radius 0);
                }
            }
        }
    }
}

.thead-bg th {
    background: $tertiary;
    border    : 0;
}

.caret-button-down {
    display: inline-block;
    @include caret-down();
    @include opacity(0.5);
}

.caret-button-up {
    display: inline-block;
    @include caret-up();
    @include opacity(0.5);
}

.td-phone-number {
    min-width: rem(100);
}