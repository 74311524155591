////////////////////////////////////////////
// Timeline
////////////////////////////////////////////

.timeline-wrapper {
    &.timeline-loaded{
        .timeline-item.active{
            &::after {
                width: 100%;
            }
            .timeline-pin::after,
            .timeline-dot,
            .timeline-pin svg {
                @include transform(scale(1));
            }
            &:nth-child(2){
                &::after{
                    transition-delay: 1s;
                }
                .timeline-pin::after {
                    transition-delay: 1s;
                }
                .timeline-dot,
                .timeline-pin svg {
                    transition-delay: 1s;
                }
            }
            &:nth-child(3){
                &::after{
                    transition-delay: 2s;
                }
                .timeline-pin::after {
                    transition-delay: 2s;
                }
                .timeline-dot,
                .timeline-pin svg {
                    transition-delay: 2s;
                }
            }  
        }
    }
    .timeline-item {
        position: relative;
        flex: 1;
        padding-bottom: rem(35);

        &:last-child {
            flex: none;
            width: 0;

            .timeline-pin {
                right: rem(15);
            }

            .timeline-content {
                left: auto;
                right: 0;
                min-width: rem(100);
                text-align: right;
            }

            .timeline-icon{
                float: right;
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: rem(7);
            width: 100%;
            height: rem(2);
            background-color: $dark-gray;
            z-index: 0;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: rem(7);
            width: 0;
            height: rem(2);
            background-color: $primary;
            z-index: 0;
            @include transition(all .85s ease-in);
        }

        .btn-icon{
            vertical-align: top;
            width: 100%;
        }

        .timeline-content {
            position: absolute;
            left: 0;
            top: rem(25);
            max-width: 100%;
        }

        .timeline-pin {
            background-color: $dark-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            @include size(rem(15));
            @include border-radius(50%);
            &::after{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $primary;
                z-index: -1;
                @include size(rem(15));
                @include border-radius(50%);
                @include transition(all .2s ease-in);
                @include transform(scale(0));
            }
            svg{
                @include transition(all .2s ease-in);
                @include transform(scale(0));
            }
        }

        .timeline-dot {
            background-color: $white;
            @include size(rem(7));
            @include border-radius(50%);
            @include transition(all .2s ease-in);
            @include transform(scale(0));
        }

        .timeline-title {
            font-size: rem(14);
            margin-bottom: rem(2);
        }

        .timeline-link {
            font-size: rem(12);
            text-decoration: underline;
            color: $primary;
        }

        .timeline-info-text {
            font-size: rem(12);
            color: $light-text-color;
        }

        .timeline-icon {
            position: relative;
            top: rem(5);
            svg {
                display: block;
            }
        }

        &:first-child,
        &.active {  
            .timeline-icon {
                path {
                    fill: $primary;
                }
            }
        }
        &.active.last::after {
            display: none;
        }  

        // &.active {
        //     &::after {
        //         width: 100%;
        //     }
        //     &:nth-child(2)::after{
        //         transition-delay: 1s;
        //     }
        //     &:nth-child(3)::after{
        //         transition-delay: 2s;
        //     }
        //     &:nth-child(4)::after{
        //         transition-delay: 3s;
        //     }
        // }
    }
}