.right-header-wrap {
    .icon-dropdown-item {
        width: rem(16);
        height: rem(16);
    }

    .dropdown-item {
        cursor: pointer;
        color: $light-text-color;
        line-height: rem(38);
        border-left: rem(4) solid transparent;
        @include transition(all 0.3s linear);

        .icon-dropdown-item svg path {
            fill: $light-text-color;
        }

        &:hover,
        &:focus {
            color: $primary;
            border-left: rem(4) solid $primary;
            background-color: $gray-100;
            .icon-dropdown-item svg path {
                fill: $primary;
            }

            .icon-logout svg path {
                stroke: $primary;
                fill: transparent;
            }
        }
        .icon-logout svg {
            path {
                stroke: $light-text-color;
                fill: transparent;
            }

            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
        }
    }

    .notification-wrapper {
        width: 90%;
        left: 0;
        right: 0;
        cursor: pointer;

        .notification-active {
            background-color: $border-list;
        }

        .custom-active:active {
            background-color: $dark-gray;
        }

        .notification-list {
            overflow-y: auto;
            max-height: calc(100vh - 180px);
            @include scrollbar;

            .notification-active:active,
            .notification-inactive:active {
                color: $secondary;
                background-color: transparent;
            }
            .cursor-none {
                cursor: initial;
            }
        }

        .b-icon,
        .b-read-icon {
            width: rem(30);
            height: rem(30);
            top: 50%;
            transform: translateY(-50%);
        }

        .b-content {
            white-space: normal;
        }

        .b-btn-zoom {
            @include visibility(hidden);
            @include transition(all 0.3s linear);
        }

        .btn-zoom {
            height: auto;
            line-height: inherit;
            @include transition(all 0.3s linear);
        }

        .zoom-icon {
            svg {
                height: rem(15);
                width: rem(15);
            }

            &:hover {
                svg {
                    fill: $primary;
                }
            }
        }

        .item-content {
            padding-left: rem(42);
            padding-right: rem(42);

            &:hover {
                .b-btn-zoom {
                    @include visibility(visible);
                }
            }
        }

        .b-read-icon {
            right: 0;

            .signal {
                width: rem(8);
                height: rem(8);
                display: block;
                border: rem(1) solid $success;
                margin: 0;
                border-radius: 50%;

                &:hover {
                    background-color: $success;
                }
            }
        }

        .b-icon {
            width: rem(22);
            left: rem(20);
        }

        .btn-dropdown-close {
            font-size: rem(30);
            @include opacity(0.2);
        }

        .custom-header {
            cursor: initial;
        }
    }

    .b-custom-notification {
        position: static;
    }

    .icon-help,
    .icon-notification {
        width: rem(20);
        height: rem(20);
        cursor: pointer;
    }

    .animation-ball {
        width: rem(8);
        height: rem(8);
        background: $danger;
        top: 0;
        right: rem(-3);
    }

    .b-help-content {
        width: rem(260);
    }

    .highlight-link {
        cursor: pointer;
        color: white;
        font-size: rem(14);
        @include transition(all 0.3s linear);

        &:hover {
            color: $primary;
        }
    }

    .description-text {
        color: white;
        font-size: rem(14);
        font-family: $secondary-bold-font;
    }

    .notification-side-popup-wrapper {
        &:before {
            content: "";
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(black, 0.5);
        }

        .b-notification-side-popup {
            position: absolute;
            bottom: 90%;
            width: 100%;
            max-width: rem(340);
            left: 50%;
            transform: translateX(-50%);
        }

        .btn-notification-side-popup-close {
            top: rem(8);
            right: rem(15);
        }

        .title-text {
            font-size: rem(16);
        }

        .list-item {
            list-style: none;
        }

        .list-title {
            font-size: rem(14);
            font-family: $primary-font;
        }

        .list-description {
            font-size: rem(14);
            font-family: $secondary-bold-font;
            margin-bottom: rem(15);
        }

        .b-icon-visit {
            width: rem(36);
            height: rem(36);
        }

        .notification-duration {
            font-size: rem(12);
        }
    }
    .profile-img-tag {
        display: inline-block;
        object-fit: cover;
        background: $bg-grey;
    }

    .resume-subscription-btn {
        background-color: #409c1c;

        &:hover {
            background-color: #245c0e;
        }
    }
}

.no-notification-list {
    padding: rem(25) rem(15);
    .icon-block {
        @include size(rem(40));
        path {
            fill: $light-text-color;
            @include opacity(0.2);
        }
    }
    .text-block {
        font-size: rem(14);
        color: $light-text-color;
        @include opacity(0.8);
    }
}

.green-warning {
    background: #f2fced !important;
    color: #409c1c !important;
    * {
        fill: #409c1c !important;
        color: #409c1c !important;
    }
}

.yellow-warning {
    background: #fdf4c8 !important;
    color: #eab300 !important;
    * {
        fill: #eab300 !important;
        color: #eab300 !important;
    }
}
