@include media-breakpoint-down(xs) {
    .main-header {
        &.header-height-up {
            height: rem(128);
            .custom-header-inner-height {
                height: auto;
            }
        }
    }

}