@import '@assets/scss/tools-index.scss';

.invoice-preview-container {
  width: calc( 100% + 3.75rem);
  margin-top: 1.875rem;
  margin-left: -1.875rem;
  margin-bottom: -1.875rem;
  background: #F7F9FB;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.875rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    .title {
      font-family: $mont-bold;
      font-size: 1.25rem;
      color: #082244;
    }

    .icon-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .link {
        font-family: "Open Sans Regular";
        font-size: 0.875rem;
        font-weight: 700;
        color: #0258FF;
        margin-left: 0.3125rem;
        line-height: 1.225rem;
      }
    }
  }
  .preview-main {
    background: white;
    width: 100%;
    height: 100%;
    padding: 1.765rem;
    overflow: auto;
    border: .88px solid #DBE3EB;
    border-radius: 6px;
    box-shadow: 0px 2.64844px 5.29688px rgba(206, 211, 218, 0.45);


    .main-header {
      margin-bottom: 1.25rem;

      .head {
        font-family: "Open Sans Regular";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 0px;
        text-align: left;
        color: #082244;
        margin-bottom: 8px;
      }
      .sub {
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.0625rem;
        letter-spacing: 0px;
        text-align: left;

      }
    }

    .bill-to {
      font-family: "Open Sans Regular";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
      letter-spacing: 0px;
      text-align: left;
      color: #082244;

      .head {
        padding-bottom: 0.625rem;
      }

      .contact {
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.0625rem;
        letter-spacing: 0px;
        text-align: left;
        padding-bottom: 1.75rem;
        border-bottom: 0.88px solid #DBE3EB;
        color: #576C77;
      }
    }

    .item-list {
      display: flex;
      flex-direction: column;
      padding-bottom: 0.625rem;

      .item-title {
        padding-top: 1.125rem;
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans Regular";
        font-size: 0.77rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0px;
        color: #082244;
        margin-bottom: 1.125rem;
      }

      .item-count {
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75rem;
        letter-spacing: 0px;
        margin-bottom: 1.125rem;
      }

      .item-price {
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans Regular";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75rem;
        letter-spacing: 0px;
        padding-bottom: 0.875rem;
        border-bottom: 0.88px solid #DBE3EB;
      }
    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.88px solid #DBE3EB;
      font-size: 0.77rem;
      padding-bottom: 0.625rem;

      .number {
        font-family: "Open Sans Regular";
        font-size: 0.77rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0px;
        color: #082244;
      }
    }

    .total {
      display: flex;
      justify-content: flex-end;
      font-family: "Open Sans Regular";
      font-size: 0.77rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0px;
      color: #082244;
      padding-top: 1.125rem;

      .title {
        margin-right: 1.75rem;
      }
    }
  }
}

@supports (display: flex) {
  @media screen and (max-width: 575px) {
    .invoice-preview-container {
      width: calc( 100% + 2rem);
      margin-top: 1rem;
      margin-left: -1rem;
      margin-bottom: -1rem;
    }
  }
}

.form-file-upload {
  height: 16rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.label-file-upload-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.show-files {
  width: 95%;
  height: 95%;
  margin-top: 10%;
  margin-left: 10%;
}

.table-files {
  width: 100%;
  border-spacing: 30px;
}

.file-display-name {
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #082344;
  font-family: 'Avenir Next LT W01', sans-serif;
  display: 'inline-block';
}

.file-icon {
  display: 'inline-block';
  margin-left: 10%;
  cursor: pointer;
}
