@media(min-width:480px) {
  .common-payment-widget-form {
    .col-sm-4 {
      flex     : 0 0 33.33333%;
      max-width: 33.33333%;
    }

    .col-sm-6 {
      flex     : 0 0 50%;
      max-width: 50%;
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal .modal-body .custom-modal-close-button {
    top  : rem(-55);
    right: rem(10);
  }

  .btn-split-wrapper {
    position: absolute;
    right   : 0;
  }

  // booking list cancel
  .custom-btn-cancel {
    width: auto;
  }

  // end booking list cancel

  // image crop modal
  .image-crop-wrapper {
    .btn-edit-image {
      width: auto;
    }
  }

  // end image crop modal


  // for activated modal (trial page modal)
  .modal .modal-body .activate-modal-wrapper .custom-modal-close-button {
    right: rem(-12);
  }

  // end for activated modal (trial page modal)

  // Dashboard activity widget
  .custom-home-page {
    .b-card-activity {
      .content-list {
        .business-update-content {
          max-width: rem(410);
        }
      }
    }
  }

  // End Dashboard activity widget


  // full page loader
.full-page-loader {
  .center-progress {
    // &:before {
    //   background-size: 50%;
    // }
    &:after {
      right: rem(-120);
    }
  }
}
// end full page loader

}