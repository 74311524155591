@import '@assets/scss/tools-index.scss';

.connector-line {
	width: 1.575rem;
	height: 1px;
	background-color: $tertiary;
}

.instant-step-item {
  border-radius: rem(32);
  background-color: $tertiary;
  position: relative;
  z-index: 1;
}

.active {
  background-color: $primary;
  color: white;
}
