@import '@assets/scss/tools-index.scss';

.review-preview-msg {
    .review-preview-btn {
        font-size     : rem(10);
        height        : rem(26);
        padding       : rem(3) rem(10);
        vertical-align: top;
        background    : $dark-gray;
        color         : $primary;
        line-height   : rem(20);
        margin-top    : rem(20);
    }

    .review-msg-block {
        border-radius: rem(4);
        margin-bottom: rem(10);
        margin-top   : rem(20);
        padding      : rem(8) rem(10);
        border       : solid rem(1) $dark-gray;
        word-wrap    : break-word;
        min-height: rem(48);
    }
}

.review-msg-link {
    border       : rem(1) solid $dark-gray;
    display      : inline-block;
    border-radius: rem(4);
    margin-top  : rem(20);
    padding: 0 !important ;
}
