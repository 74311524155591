.global-modal-root{
  z-index: 1100 !important;
  position: fixed;
  width: auto;
  height: auto;
  background: #F7F9FB;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  left: 19px;
  top: 156px;
}

.global-modal{
  display: flex;
  max-height: calc(100% - 42px);
}

.most-popular{
    font-family: 'Open Sans Regular';
    font-style: normal;
    color: #576C77;
    min-width: 189px;
    margin-top: 8px;
    padding: 20px;
}

.popular-heading{
    font-family: 'Open Sans Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #082244;
}

.global-create-close{
  margin-top:-466px;
  float:right;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  }
  .global-create-close-showless{
  margin-top:-173px;
  font-size: 24px;
  float:right;
  cursor: pointer;
  position: absolute;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.show-less-more{
  border-top: 1px solid #898989;
  padding: 10px 0;
}

.show-less-more-button {
  cursor: pointer;
  color: #0357FF;
  size:12px;
  padding: 15px;
}

.show-less-more-button:hover {
  color: skyblue;
}

.products-list {
  width: -moz-max-content !important;
  width: max-content !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-left: 1px solid #898989;
  align-content: flex-start;
  padding: 20px;
}

.feature-section {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 251.5%;
  color: #576C77;
  padding: 0.5rem 0.75rem 1rem;

}

.feature-title {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #082244;
}

.feature-link {
  cursor: pointer;
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #576C77;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 18px 0px;
}

.feature-link:hover {
  color: skyblue;
}

@media screen and (max-width: 960px) {
  .global-modal-root {
    width: 100%;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .global-modal {
    flex-wrap: wrap;
    overflow-y: auto;
  }
  .products-list {
    flex-direction: column;
    border-left: 0;
  }
  .feature-section {
    padding-left: 0;
    padding-right: 0;
  }
  .global-modal-root.global-modal-root-collapsed {
    bottom: auto;
  }
  #frmAddContact_btnCancel{
    /* display: inherit !important; */
    visibility: visible !important;
    margin-right:15px !important;
    margin-top: -8px !important;
  }
  #frmAddContact_btnCancel:hover{
    cursor: pointer;
    color: skyblue !important;
    
  }
  .close {
    float: right;
    font-size: 2.3125rem !important
  }
}


#frmAddContact_btnCancel{
  /* display:none !important; */
  visibility:hidden
}
@media screen and (max-width: 1280px) {
  .global-modal-root {
    width: 100%;
    top: 60px;
    left: 0;
    right: 0;
  }
  .global-modal {
    flex-wrap: wrap;
    overflow-y: auto;
  }
  .products-list {
    flex-wrap: wrap;
    border-left: 0;
  }
  
}
