.b-notification {
    .button {
        background-color: transparent;
        &:after {
            display: none;
        } 
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }
    }

    .image {
        width: rem(20);
        height: rem(20);
    } 
}