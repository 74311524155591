//React Big Calendar custom designs
.rbc-custom {
    .rbc-calendar {
        height: auto;
        .rbc-event{
            padding: 0;
            @include border-radius(0);
            border: none;
        }
    }

    .calendar-dropdown-btn{
        height: rem(32);
        line-height: rem(32);
    }

    .rbc-toolbar-custom {
        position: relative;
        z-index: 9;
    }

    .calendar-navigation-btns {
        .nav-btn {
            border-color: $border-color-light;
            background-color: $white;
            height: rem(40);

            &:hover {
                background-color: $grey-background;
            }
        }

        svg {
            min-width: rem(8);

            path {
                fill: $light-text-color;
            }
        }
    }

    .calendar-view-toggle {
        .view-btn {
            border-color: $dark-gray;
            background-color: $grey-background;
            height: rem(40);
            font-size: rem(12);
            font-family: $primary-font;

            &:hover {
                background-color: $white;
            }

            &:first-child {
                border-right-color: $border-color-light;
            }

            &:last-child {
                border-left-color: $border-color-light;
            }
        }

        .active {
            color: $primary;
            font-family: $secondary-bold-font;
            background-color: $white;
        }

        svg path {
            fill: $light-text-color;
        }
    }

    .search-btn {
        @include size(rem(40));

        svg {
            @include size(rem(18));
        }

        svg path {
            stroke: $black;
        }

        &:hover {
            svg path {
                stroke: $primary;
            }
        }
    }

    //grid customization
    .rbc-month-view {
        border: none;
        z-index:0;
        .rbc-header {
            align-items: center;
            display: flex;
            justify-content: center;
            height: rem(48);
            font-family: $secondary-medium-font;
            border-top: rem(1) solid $border-color;
            border-bottom: rem(1) solid $border-color-light;
            color: $secondary;
            font-size: rem(14);
            font-weight: normal;

            +.rbc-header {
                border-left: none;
            }

            &:first-child,
            &:last-child {
                color: $light-text-color;
            }

            .week-name-mob {
                font-size: rem(12);
                color: $text-gray-variant;
            }
        }

        .rbc-month-row {
            min-height: rem(120);
        }

        .rbc-day-bg {
            &:hover {
                background-color: $grey-background;
            }
        }

        .rbc-off-range-bg,
        .rbc-day-bg.rbc-today {
            background: none;
        }

        .rbc-day-bg+.rbc-day-bg,
        .rbc-month-row+.rbc-month-row {
            border-color: $border-color-light;
        }

        .rbc-now {
            align-items: center;
            display: flex;
            justify-content: center;
            padding-top: rem(5);
            padding-bottom: rem(5);

            a {
                height: rem(20);
                min-width: rem(10);
                padding: 0 rem(7);
                @include border-radius(rem(50));
                background-color: $primary;
                color: $white;
                font-size: rem(12);
                font-family: $secondary-bold-font;
                font-weight: normal;
                align-items: center;
                display: flex;
            }
        }

        .rbc-row-segment {
            padding: rem(2) rem(5);
        }

        .rbc-date-cell {
            padding: rem(5) 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .has-custom-event-cell {
        border-left: solid rem(2) rgba($secondary, 0.5);
        @include border-radius(0);
        background-color: rgba($secondary, 0.4);
        position: relative;
        padding: rem(2) rem(5);
        height: rem(22);
        display: flex;
        align-items: center;
        &.canceled-event {
            color: $light-text-color;
            background-color: transparent;
            z-index: 1;
            // background-color: rgba($white, .8);

            .rbc-event-content {
                text-decoration: line-through;
            }

            &::before {
                position: absolute;
                content: '';
                background: url("#{$s3}/image/slice-line.png") repeat;
                background-size: rem(12);
                @include opacity(0.1);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
        }

        &.pending-event {
            border-top: rem(2) dashed $secondary;
            border-bottom: rem(2) dashed $secondary;
            border-right: rem(2) dashed $secondary;
        }

        .rbc-event-content {
            position: relative;
            z-index: 2;
            width: 100%
        }

        .btn-tick {
            @include size(rem(20));
            background-color: white;
        }

        .icon-tick {
            svg {
                @include size(rem(14), rem(10));
                // path{
                //     fill: currentColor;
                // }
            }
        }
    }

    .rbc-custom-more {
        padding-top: rem(3);
        .color-item {
            border: rem(1) solid $primary;
            background-color: $white;
            @include size(rem(8));
            @include border-radius(50%);
            margin-right: rem(3);
        }

        .color-item-1 {
            border-color: #14BDD3;
            background-color: #14BDD3;
        }

        .color-item-2 {
            border-color: #14BDD3;
        }

        .color-item-3 {
            border-color: #FFBF27;
        }

        .color-item-4 {
            border-color: #8DC150;
        }

        .show-more-text {
            font-size: rem(12);
            color: $light-text-color;
            font-weight: normal;
        }
    }

    //week view
    .rbc-time-view {
        z-index:0;
        border: none;
        padding-left: rem(10);
        border-top: rem(1) solid $border-color;

        .rbc-time-header-content {
            border-left-color: $white;
            border-bottom: rem(1) solid $border-color-light;

            .rbc-header {
                align-items: center;
                display: flex;
                justify-content: center;
                height: rem(48);
                font-family: $secondary-medium-font;
                border-bottom: rem(1) solid $border-color-light;
                color: $secondary;
                font-size: rem(14);
                font-weight: normal;

                +.rbc-header {
                    border-left: none;
                }

                &:first-child,
                &:last-child {
                    color: $light-text-color;
                }
            }

            .rbc-allday-cell {
                height: auto;
            }

            .rbc-day-bg+.rbc-day-bg {
                border-color: $border-color-light;
            }
        }

        .rbc-time-content {
            border-top: none;
            border-color: $border-color-light;
            overflow: visible;
        }

        .rbc-time-gutter.rbc-time-column {
            position: relative;
            top: rem(-12);
        }

        .rbc-day-slot .rbc-time-slot {
            border: none;
        }

        .rbc-time-gutter .rbc-timeslot-group {
            border: none;
        }

        .rbc-time-gutter.rbc-time-column+.rbc-day-slot.rbc-time-column {

            .rbc-events-container,
            .rbc-timeslot-group {
                border-left: none;
            }
        }

        .rbc-day-slot.rbc-time-column {
            .rbc-timeslot-group {
                border-color: $border-color-light;
            }
        }

        .rbc-time-content>*+*>* {
            border-color: $border-color-light;
        }

        .rbc-time-slot .rbc-label {
            font-size: rem(10);
            color: $edit-icon-grey;
        }

        // .rbc-day-slot .rbc-event {
        //     border: none;
        //     border-left: solid rem(2) $secondary;
        //     @include border-radius(0);
        //     background-color: rgba($secondary, 0.4);

        //     &.pending-event {
        //         border-top: rem(2) dashed $secondary;
        //         border-bottom: rem(2) dashed $secondary;
        //         border-right: rem(2) dashed $secondary;
        //     }

        //     &.canceled-event {
        //         color: $light-text-color;
        //         background-color: rgba($white, .8);
        //     }
        // }

        .rbc-today,
        .rbc-selected-cell {
            background-color: $grey-background;
        }

        .rbc-slot-selection {
            color: $primary;
            background-color: rgba($primary, 0.15);
        }

        .rbc-current-time-indicator {
            height: rem(1);
            background-color: $danger;

            &::before {
                content: '';
                position: absolute;
                left: rem(-5);
                top: rem(-2);
                @include size(rem(5));
                @include border-radius(50%);
                background-color: $danger;
            }
        }

        //day view
        .rbc-time-header-cell-single-day {
            display: block;

            .rbc-header {
                justify-content: flex-start;
            }
        }
    }

    .week-view-header {
        color: $text-gray-variant;
        font-size: rem(14);

        .week-view-date {
            @include border-radius(rem(50));
            padding: rem(5) rem(5);
            color: $secondary;
        }
    }

    .rbc-today {
        .week-view-date {
            background-color: $primary;
            color: $white;
            font-family: $secondary-bold-font;
            margin-left: rem(5);
        }
    }

    //week view updates
    &-view--week{
        .rbc-day-slot .rbc-event-label{
            display: none; 
        }
    }

    //custom view updates
    &-view--day {
        .rbc-time-view {
            padding: 0;

            .rbc-time-header-gutter {
                display: none;
            }

            .rbc-time-header-cell-single-day {
                padding-left: rem(30);
            }

            .rbc-day-slot .rbc-event {
                height: auto !important; //ovveride rbc inline height
                max-width: rem(300);
                padding: 0;

                .rbc-event-label {
                    display: none;
                }
            }

            .rbc-time-slot .rbc-label {
                color: $secondary;
                font-size: rem(11);
                padding: 0 rem(10);
                display: block;
                position: relative;
                top: rem(5);
            }

            .rbc-time-slot {
                flex: 1 1 0;
                flex-basis: 0;
            }

            .rbc-day-slot .rbc-time-slot+.rbc-time-slot {
                border-top: rem(1) dashed $border-color;
            }

            .rbc-time-gutter.rbc-time-column {
                top: 0;
            }

            .rbc-time-gutter .rbc-timeslot-group {
                min-height: rem(75);
                flex-grow: 1;

                .rbc-time-slot+.rbc-time-slot {
                    border-bottom: rem(1) solid $border-color-light;
                    border-top: rem(1) dashed $border-color;
                }
            }

            .rbc-day-slot.rbc-time-column .rbc-timeslot-group {
                min-height: rem(75);
            }
        }

        // .rbc-time-view .rbc-time-content{
        //   padding-left: rem(15);
        // }
        .rbc-time-header {
            margin-bottom: rem(15);
            border-bottom: rem(2) solid $border-color;
        }

        .rbc-time-view .rbc-time-header-content {
            border-bottom: none;

            .rbc-time-header-cell-single-day {
                .rbc-header {
                    border-bottom: none;
                    background-color: transparent;
                }
            }
        }

        .rbc-time-header-content {
            .rbc-allday-cell {
                display: none;
            }
        }

        .custom-day-event-content {
            padding: rem(10);
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            > header{
                width: 100%;
            }
            .time-block,
            .name-block,
            .service-title-block,
            .service-rate-block {
                color: $light-text-color;
            }

            .b-h-dots {
                @include size(rem(20));

                .dots {
                    @include size(rem(3));
                    background-color: $light-text-color;
                }
            }

            .btn-tick {
                @include size(rem(24));
                background-color: white;
            }

            .icon-tick {
                svg {
                    @include size(rem(14), rem(10));

                    path {
                        fill: currentColor;
                    }
                }
            }

            .b-button-group {
                min-width: auto;
                top: rem(-15) !important; // here using !important is to overwrite the inline style.
            }

            .btn-action {
                height: rem(32);

                svg {
                    @include size(rem(11));
                }

                .icon-action-calendar {
                    svg path {
                        fill: $secondary;
                    }
                }

                &:hover {
                    background-color: $grey-background;
                }
            }
        }
    }

    //responsive designs
    @include media-breakpoint-down(sm) {
        .calendar-navigation-btns {
            svg {
                path {
                    fill: $text-gray-variant;
                }
            }
        }
        .rbc-month-view {
            .rbc-header{
                height: rem(26);
                border: none;
            }
            .rbc-day-bg{
                border: none;
            }
            .rbc-date-cell{
                color: $secondary;
            }
            .rbc-now a{
                @include size(rem(28));
                font-family: $primary-font;
            }
            .has-custom-event-cell{
                @include size(rem(5));
                border: solid rem(1);
                @include border-radius(50%);
                padding: 0;
                margin: 0 rem(1);
                background-color: transparent;
                .rbc-event-content{
                    display: none;
                }
                // &.dashed-border{
                //     border-style: dashed;
                // }
            }
            .rbc-month-row{
                min-height: rem(54);
                border: none; 
                .rbc-row-content{
                    min-height: 100%;
                }
                .rbc-row-content .rbc-row{
                    + .rbc-row{
                        display: none;
                    } 
                }
            }
        }
        &-view--week{
            .rbc-time-view{
                border: none;
            }
            .rbc-time-view .rbc-time-header-content .rbc-header{
                height: rem(65);
            }
            .week-view-header{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column; 
            }
            .week-view-date{
                margin-top: rem(5);
            }
            .rbc-today .week-view-date{
                @include size(rem(28));
                padding: 0;
                display: flex; 
                align-items: center;
                margin-left: 0;
                justify-content: center;
            }
        }
        &-view--day{
            .rbc-time-view .rbc-time-header-cell-single-day {
                padding-left: 0;
            }
        }
    } 
}
 
