@import "@assets/scss/tools-index.scss";

.icon-dropdown-item {
  width: rem(16);
  height: rem(16);
}

.dropdown-item {
  cursor: pointer;
  color: $light-text-color;
  line-height: rem(38);
  border-left: rem(4) solid transparent;
  @include transition(all 0.3s linear);

  .icon-dropdown-item svg path {
    fill: $light-text-color;
  }

  &:hover,
  &:focus {
    color: $primary;
    border-left: rem(4) solid $primary;
    background-color: $gray-100;
    .icon-dropdown-item svg path {
      fill: $primary;
    }

    .icon-logout svg path {
      stroke: $primary;
      fill: transparent;
    }
  }
  .icon-logout svg {
    path {
      stroke: $light-text-color;
      fill: transparent;
    }

    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

.profile-img-tag {
  display: inline-block;
  object-fit: cover;
  background: $bg-grey;
}

.logo {
  width: rem(100);
  height: rem(31.88);
}
