// .dropdown-menu-import-contacts-white{
//     background-color:#fff;
//     //margin-left:rem(6);
//     width:rem(200);
//     label{
//         white-space: nowrap;
//         svg{
//             min-width:rem(16);
//         }
//     }
// }

// contact import - dropdown
.dropdown-menu-import-contacts {
  background: #fff;
  width: rem(200);
  @include border-radius(rem(6));
  h4 {
    margin-bottom: rem(20);
  }

  .import-from-contacts {
    display: block;
  }

  .btn-import-contact {
    white-space: nowrap;
    white-space: nowrap;
    svg {
      min-width: rem(16);
    }
  }

  .file-type-required {
    font-size: rem(12);
    @include opacity(0.6);
  }

  .import-from-others-btns {
    display: block;
    button {
      padding: 0 !important;
    }
    svg,
    img {
      width: rem(32);
    }
  }
  .download-sample {
    color: white;
    font-size: rem(11);

    img {
      max-width: rem(20);
      top: rem(-2);
      position: relative;
    }
  }

  .import-from-text {
    font-size: rem(14);
    @include opacity(0.8);
  }
}
.btn-nowrap {
  white-space: nowrap;
}

.btn-export {
  .icon-export {
    width: rem(18);

    svg {
      width: 100%;

      path {
        stroke: $secondary;
      }
    }
  }
  &:hover {
    .icon-export {
      svg path {
        stroke: $primary;
      }
    }
  }
}

.contact-table-icon {
  width: rem(14);

  svg {
    width: 100%;
  }
}

.fill-icon {
  svg path {
    fill: $grey-variant;
  }
}

.stroke-icon {
  svg path {
    stroke: $grey-variant;
  }
}

.custom-sub-header {
  min-height: auto;
  margin-bottom: rem(30);
}

.custom-header-width {
  width: 100%;
}

.source-content {
  width: rem(18);
}
.th-source {
  width: rem(18);
}

.b-phone-number {
  min-width: rem(143);
}

.source-icon {
  width: rem(18);
  height: rem(18);
  margin-left: rem(6);
}

.icon-wrap {
  @include opacity(0.7);
  @include transition(all 0.2s linear);

  svg {
    @include opacity(0.5);
    @include transition(all 0.2s linear);
  }

  &:hover {
    @include opacity(1);

    svg {
      @include opacity(0.7);
    }
  }

  .btn-icon-email {
    svg {
      width: rem(19);
      min-width: rem(19);
    }
  }

  .btn-icon-mobile {
    svg {
      width: rem(13);
      min-width: rem(13);
    }
  }
}

.cloud-download-btn {
  .cloud-download-icon {
    max-width: rem(20);
  }
}

// contact edit
.page-contact-edit {
  .icon-backto-allcontact {
    @include size(rem(14), rem(18));
    svg path {
      fill: $text-gray-variant;
    }
  }
  .field-inline-icon {
    min-width: rem(30);
    width: rem(30);
    height: rem(30);
    border: rem(1) solid $border-color;
  }

  // Contact information
  .b-card-contact-information {
    .custom-content {
      min-height: auto;
    }

    .title-text {
      font-size: rem(14);
      color: $secondary;
    }

    .sub-title {
      font-size: rem(12);
      color: $light-text-color;
    }
  }

  .select-state-div {
    text-align: left;
    position: relative;

    .select-state-icon {
      right: rem(20);
      position: absolute;
    }
  }

  .select-state-ul {
    height: rem(300);
  }

  .btn-upload-image {
    cursor: pointer;
  }

  .upload-invoice-logo figure img {
    width: rem(80);
    height: rem(80);
  }

  .contact-group-section {
    height: rem(70);
    display: block;
  }
  .form-personal-information {
    .icon-profile-delete {
      top: rem(-8);
      min-width: rem(30);
      @include size(rem(30));
      svg {
        @include size(rem(15));
        path {
          fill: white;
        }
      }
    }
  }
}

li {
  .value-count {
    @include border-radius(20px);
    @include transition(all 0.3s linear);
    padding: rem(2) rem(7);
    line-height: rem(17);
    font-size: rem(11);
    background: $gray-500;
    color: white;
    text-align: center;
    display: block;
  }

  &:hover .value-count {
    background-color: $primary;
  }
}

.max-height-300 {
  max-height: rem(300);
}

// add contact form
.section-add-contact-modal {
  .icon-create {
    width: rem(14);

    svg path {
      stroke: $primary;
    }
  }

  .b-profile {
    width: rem(80);
    height: rem(80);

    .profile-image {
      object-fit: cover;
    }

    .profile-icon {
      width: rem(30);
      height: rem(30);
      right: rem(-15);
      cursor: pointer;

      svg {
        height: rem(14);
      }
    }
  }

  .value-block {
    color: $secondary;
    font-family: $primary-font;
  }
}

.state-dropdown {
  height: rem(350);
  overflow-y: auto;
}

.icon-set-right {
  right: 0;
  top: 0;
  cursor: pointer;
  @include opacity(0.8);

  &:hover {
    @include opacity(1);
  }
}

.dropdwon-menu-scrollable {
  max-height: rem(200);
  overflow: hidden;
  overflow-y: auto;
  @include scrollbar;
}

.icon-trash {
  @include opacity(0.7);

  svg path {
    stroke: $secondary;
  }

  &:hover {
    @include opacity(1);

    svg path {
      stroke: $danger;
    }
  }
}

.no-data-div {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.activity-content {
  height: calc(100% - 80px);
}

.spinner {
  max-width: rem(15);
}

.b-modal-search-field {
  position: relative;

  .search-icon {
    position: absolute;
    right: rem(15);
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
}

.add-another-domain {
  cursor: pointer;
  font-size: rem(12);
  color: $secondary;
  &:hover {
    color: $primary;
  }

  svg path {
    stroke: $primary;
  }

  .icon-add-domain {
    width: rem(16);
    height: rem(16);
    min-width: rem(16);
  }
}
// contact settings
.form-account-settings {
  :global {
    .label-text {
      color: $body-color;
      margin-bottom: rem(5);
    }
  }
  .sync-checkbox {
    margin-top: rem(2);
    left: 0;
  }

  .b-blocked-delete {
    padding: rem(6) rem(10);

    .icon-blocked-delete {
      @include size(rem(14), rem(19));
      @include opacity(0);
      @include visibility(hidden);
      @include transition(all 0.3s linear);

      svg path {
        fill: $danger;
      }
    }

    &:hover {
      .icon-blocked-delete {
        @include opacity(1);
        @include visibility(visible);
      }
    }
  }

  .block-icon {
    @include size(rem(14));
  }

  .block-icon svg path {
    fill: $danger;
  }

  .icon-action-trash {
    svg path {
      stroke: $danger;
    }
  }

  .icon-contact-trash {
    @include size(rem(17), rem(15));
  }

  .btn-block-contact {
    font-size: rem(14);
  }
}

.tooltip-icon {
  svg {
    max-width: rem(14);
    max-height: rem(14);
    fill: $gray-600;
  }
}

.list-delete-info {
  background-color: #f4fbfe;
  padding: 6px 10px;
  border: solid 1px #08b2fa;
  color: #1b76a4;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;

  a {
    color: #1b76a4;
    text-decoration: underline;
  }
}

// Messenger Thred Block

.messenger-table-list {
  list-style: none;
  border: solid rem(1) white;
  background-color: #fff;
  border-bottom-color: $dark-gray;
  @include transition(all 0.2s linear);

  &:hover {
    background-color: rgba($primary, 0.05);
    border-color: rgba($primary, 0.4);
  }
  .b-list-content {
    width: calc(100% - 160px);
  }
  .name-block {
    padding-right: rem(40);
    // width: calc(100% - 30px);
  }

  .time {
    color: $offline-color;
    font-size: rem(11);
    font-style: normal;
  }

  .trash-icon {
    @include visibility(hidden);
    @include transition(all 0.2s linear);

    svg {
      width: rem(14);
    }

    svg path {
      stroke: $text-gray-variant;
    }

    &:hover {
      svg path {
        stroke: $danger;
      }
    }
  }

  &:hover {
    .trash-icon {
      @include visibility(visible);
    }
  }

  .fav-icon-block {
    cursor: pointer;
    min-width: rem(20);

    svg path {
      fill: $offline-color;
      stroke: $offline-color;
    }

    &.active {
      svg path {
        fill: $favourite-color;
        stroke: $favourite-color;
      }
    }
  }

  .message-text {
    color: $light-text-color;
    min-height: rem(21);
  }
}

.messenger-table-icon {
  min-width: rem(40);
  @include size(rem(40));
  border: solid rem(1) $primary;

  svg {
    width: 100%;
    max-width: rem(16);
    max-height: rem(21);
    // max-width : rem(22);
    // max-height: rem(22);
    fill: $primary;
    path {
      fill: $primary;
    }
  }

  .dot {
    @include size(rem(12));
    right: rem(-6);
    top: rem(6);
    border: solid rem(2) $white;
  }

  .offline {
    background-color: $offline-color;
  }

  .online {
    background-color: $status-green;
  }
}

.chats-content-loader {
  .dp-block {
    @include size(rem(40));
    min-width: rem(40);
  }
}

// Contact Group

.group-tick-icon {
  svg path {
    stroke: $status-green;
  }
}

// thumbtack alert section
.alert-wrapper {
  background-color: #f9d7d4;
}
.button-remove {
  min-width: rem(27);
  @include size(rem(27));

  svg {
    width: 100%;

    path {
      stroke: $danger;
    }
  }
}
.icon-alert-close {
  @include size(rem(16));

  svg path {
    fill: $danger;
  }
}

// end of alert

.contact-hub-empty-title {
  h2 {
    font-size: rem(20);
  }
}
.contact-hub-empty-content {
  max-width: rem(750);
}
.white-space-nowrap {
  white-space: nowrap;
}

.dropdown-menu-import-contacts-white {
  background-color: #fff;
  //margin-left:rem(6);
  width: rem(200);
  label {
    white-space: nowrap;
    svg {
      min-width: rem(16);
    }
  }
}
.icon-width {
  min-width: rem(24);
  margin-right: rem(10);
}

.quickbook-connected {
  background: $success-alert;
}

.btn-integration {
  white-space: nowrap;
  background: $bg-green;
  color: #fff;
  &:hover {
    color: #fff;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
  }
}
.close-icon {
  svg {
    max-width: rem(18);
    path {
      fill: $bg-green;
    }
  }
}
.tick-icon {
  min-width: rem(24);
  path {
    fill: $bg-green;
  }
}

.cancel-button {
  border-color: $secondary;
  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.max-90 {
  max-width: rem(250);
}
.icon-refresh-updated {
  svg {
      min-width: rem(16);
      transform        : rotate(0deg);
      -webkit-transform: rotate(0deg);
      @include transition(all 0.2s linear);

      path {
          stroke: rgba($secondary, 0.3);
      }
  }

  &:hover svg {
      transform        : rotate(90deg);
      -webkit-transform: rotate(90deg);
  }
}