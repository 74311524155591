.b-card {
    margin-bottom: rem(30);

    .header {
        padding   : 0 rem(15);
        min-height: rem(76);
    }

    .title {
        color      : $secondary;
        font-family: $primary-bold-font;
    }

    .content {
        min-height: rem(150);
        padding   : 0 rem(15) rem(15);
    }
}

.b-card-center{
    padding-left: 8.26rem !important;
}

// media query
@include media-breakpoint-up(lg) {
    .header {
        padding: 0 rem(30);
    }

    .content {
        padding: 0 rem(30) rem(30);
    }
}

.b-card.hoverable:hover {
    cursor: pointer;
}