.b-no-data {
    display        : flex;
    align-items    : center;
    justify-content: center;
    height:100%;
    min-height: rem(230);
    .no-data-content {
        text-align: center;
        color: $light-text-color;
    }
    .no-data-title {
        margin-bottom: rem(12);
    }
    &.no-data-page{
        min-height:100vh; 
    }
}
 