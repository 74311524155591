@include media-breakpoint-up(xl) { 
    .d-xxl-none{
        display:none !important;
    }
    .d-xxl-flex{
        display:flex !important;
    }
}



@media only screen and (min-width: 1280px) {
    .my-account-tab-wraper{
        display: flex;
        padding: 0 !important;
        .tab-menu{
            width       : rem(260);
            padding:rem(25);
            .dropdown-title{
                display: none !important;
            }
        }
        .tab-content{
            padding:rem(30);
        }
    }
}

@media only screen and (min-width: 1330px) {
    .my-account-tab-wraper{
        .tab-menu{
            width       : rem(300);
            padding-right:rem(35);
        }
        .tab-content{
            padding:rem(40);
        }
    }
}

@media only screen and (min-width: 1380px) {
    .my-account-tab-wraper{
        .tab-menu{
            width       : rem(320);
            padding:rem(30) rem(60) rem(30) rem(30);
        }
        .tab-content{
            padding:rem(40) rem(50);
        }
    }
}

@media (min-width: 1600px){
    .image-item{
        display: inline-flex;
        margin-right: rem(15);
    }

}