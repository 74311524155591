//visibility
//use: @include visibility(hidden|visible);
@mixin visibility($visibility) {
    @if $visibility=='hidden' {
        @include opacity(0);
        visibility: hidden;
    }

    @else {
        @include opacity(1);
        visibility: $visibility;
    }
}

// use: @include clearfloat();
@mixin clearfloat() {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

//use: @include placeholder($color, $opacity);
@mixin placeholder($color, $opacity) {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color  : $color;
        opacity: $opacity;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color  : $color;
        opacity: $opacity;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color  : rgba($color, $opacity);
        /*color: $color;
        opacity: $opacity;*/
    }

    &::-ms-input-placeholder {
        /* Firefox 18- */
        color  : $color;
        opacity: $opacity;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color  : $color;
        opacity: $opacity;
    }
}


//no text select
//@include no-select();
@mixin no-select() {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select  : none;
    /* Chrome/Safari/Opera */
    -khtml-user-select   : none;
    /* Konqueror */
    -moz-user-select     : none;
    /* Firefox */
    -ms-user-select      : none;
    /* Internet Explorer/Edge */
    user-select          : none;
    /* Non-prefixed version, currently
    not supported by any browser */
}


//custom scrollbar for webkits
//@include scrollbar(6px, $gray-400, $gray-200);
$defaultColor   : $white;
$scrollBarRadius: 6px !default;
$thumbColor     : $gray-400 !default;
$trackColor     : $gray-200 !default;

@mixin scrollbar($defaultColor: $white, $scrollBarRadius : $scrollBarRadius, $thumbColor : $thumbColor, $trackColor : $trackColor) {    
    
    scrollbar-width: thin;
    scrollbar-color: $thumbColor $trackColor;

    &::-webkit-scrollbar {
        width     : rem(4);
        height     : rem(4);
        visibility: hidden;
        opacity   : 0;
    }

    &::-webkit-scrollbar-track {
        background: white;
        border-radius: 0 rem(3) rem(3) 0;
    }

    &::-webkit-scrollbar-thumb {
        background: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: none;
    }

    &:hover {
        &::-webkit-scrollbar {
            visibility: visible;
            opacity   : 1;
        }

        &::-webkit-scrollbar-track {
            background: $trackColor;
        }

        &::-webkit-scrollbar-thumb {
            background: $thumbColor;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $trackColor;
        }
    }
}

//use @include size(10px);
@mixin size($width, $height: null) {
    width : $width;
    height: $height;

    @if ($height==null) {
        height: $width;
    }
}

//keyframe animation
//use method example below
//@include keyframes(animationName){
    // from{
    //     color: red;
    // }
    // to{
    //     color: green;
    // }
// };
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content; 
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    } 
}
  
//use @include animation(animationName 1.5s linear);
@mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    animation: $animation;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}