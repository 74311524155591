@include media-breakpoint-up(lg) {
    .messenger-wrapper {
        .conversation-list-wrapper {
            left    : 0;
            position: inherit;
        }

        .btn-conversation-list {
            display: none;
        }
 
        .b-account { 
            position: relative;
        }
        .icon-toggle-user {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-toggle {
                width: rem(15);
                height: rem(13);
            }
            &:hover {
                svg path {
                    fill: $primary;
                }
            }
        }
        // for contact chat header
        .chat-header-wrapper {
            .user-name {
                font-size: rem(20);
            }
        }
    }
}