button:focus,
button:active,
button:active:focus {
    outline: none;
    @include box-shadow(none);
}

.btn {
    font-family: $secondary-bold-font;
    height     : rem($height-md);
    padding    : rem(0) rem(15);
    line-height: rem($height-md);
    @include border-radius(4px);

    .btn-icon {
        display : inline-block;
        position: relative;
        top     : rem(-1);
        cursor  : pointer;
    }

    &.btn-xl {
        font-size  : rem(14);
        line-height: rem($height-xl);
        height     : rem($height-xl);
        padding    : rem(0) rem(25);

        .spinner {
            max-width: rem(22);
        }
    }

    &.btn-lg {
        font-size  : rem(14);
        line-height: rem($height-lg);
        height     : rem($height-lg);
        padding    : rem(0) rem(20);

        .spinner {
            max-width: rem(22);
        }
    }

    &.btn-md {
        font-size  : rem(14);
        line-height: rem($height-md);
        height     : rem($height-md);
        padding    : rem(0) rem(15);

        .spinner {
            max-width: rem(22);
        }
    }

    &.btn-sm {
        font-size  : rem(12);
        line-height: rem($height-sm);
        height     : rem($height-sm);

        .spinner {
            max-width: rem(20);
        }
    }

    &.btn-xs {
        padding    : rem(0) rem(10);
        font-size  : rem(10);
        height     : rem($height-xs);
        line-height: rem($height-xs);

        .spinner {
            max-width: rem(18);
        }
    }

    &.btn-outline {
        color : $secondary;
        border: rem(1) solid $border-color;

        &:hover,
        &:focus {
            color       : $primary;
            border-color: $primary;
        }

        &.hover-danger:hover {
            border-color: $danger;
            color       : $danger;
        }
    }

    &.btn-outline-danger {
        color : $secondary;
        border: rem(1) solid $border-color;
        &:hover {
            background: transparent;
            color     : $danger;
            border: rem(1) solid $danger;
        }
        &:active {
            color     : $danger !important;
            background-color: transparent !important;
        }
    }

    &.btn-danger {
        border: rem(1) solid $danger;

        &:hover {
            border-color: $danger;

            svg {
                fill: $danger;
            }
        }
    }

    &.btn-primary-outline {
        color          : $primary;
        text-decoration: none;
        border: rem(1) solid $primary;
        &:hover {
            color          : white;
            background-color: $primary;
        }
    }
    &.btn-view {
        color          : $primary;
        text-decoration: none;
        background     : rgba($primary, 0.1);

        &:hover {
            color     : $primary;
            background: rgba($primary, 0.2);
        }
    }



    &.btn-shadow:hover {
        box-shadow: 0 rem(6) rem(10) rgba(8, 178, 250, .38);
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active:focus {
        outline: none;
        @include box-shadow(none);
    }
}

.btn-icon {
    position: relative;
    display : inline-block;
    cursor  : pointer;
}
.custom-btn-width {
    width: 100%;
}

@media (min-width:576px) {
    .custom-btn-width {
        width: rem(118);
        min-width: rem(118);
    }
    .custom-btn-width-sm {
        width: 80px;
        min-width: 80px;
    }
}
@media (max-width:575px) {
    .btn-width-full-xs {
        width: 100%;
    }
}



 