.activity-wrapper {
  max-height: rem(200);
  overflow: hidden;
  overflow-y: auto;
  @include scrollbar;
}

.loader-circle {
  min-width: rem(50);
  @include size(rem(50));
}

.loader-square {
  @include size(rem(30));
}

.b-contact-status {
  margin-bottom: rem(30);
  border-bottom: rem(1) solid $border-color;
}

.b-barchart-status {
  margin-bottom: rem(30);
}

.icon-target-link {
  @include size(rem(12));
  top: 0;

  svg path {
    stroke: $primary;
  }
}

.icon-target-link {
  @include size(rem(12));
  top: 0;

  svg path {
    stroke: $primary;
  }
}

// .btn-view-website:hover {
//   svg path {
//     stroke: white;
//   }
// }
.sub-text span {
  font-family: $primary-bold-font;
  color: $secondary;
  font-size: rem(26);
  line-height: 1;
  margin-right: 0;
}

.text-count {
  font-size: rem(26);
}

// my business widget
.business-widget-wrapper {
  .content-title {
    font-size: rem(18);
    font-family: $primary-bold-font;
  }

  .b-icon-info {
    min-width: rem(25);
    @include size(rem(25));
  }

  .icon-info {
    @include opacity(0.5);

    svg {
      width: 100%;
      height: 100%;
    }

    &.icon-globe {
      @include opacity(1);
      @include size(rem(18));
    }
  }

  .b-icon-globe {
    top: rem(3);
  }

  .text-url {
    padding-left: rem(35);
  }

  .icon-mobile {
    @include size(rem(13), rem(20));
  }

  .icon-email {
    @include size(rem(19), rem(14));
  }

  .brand-logo {
    overflow: hidden;
    background-color: $bg-grey;
    @include size(rem(100));
  }

  .image-logo {
    object-fit: cover;
  }

  .btn-edit {
    border: rem(1) solid $border-color;
    @include size(rem(32));

    .icon-edit-business {
      @include size(rem(13));

      svg path {
        fill: $primary;
      }
    }

    &:hover {
      border: rem(1) solid $primary;
    }
  }
}

.receive-code {
  font-size: rem(13);
  margin-bottom: rem(20);
  margin-top: rem(5);

  .undo-btn {
    font-size: rem(13);
    background: transparent;
    color: $primary;
    cursor: pointer;

    .icons-undo {
      @include size(rem(13));
      display: inline-block;
      position: relative;
      top: rem(-2);
      margin-right: rem(5);

      svg path {
        fill: $primary;
      }
    }
  }
}

// preview page
.page-preview-body {
  .more-product-wrapper {
    .icon-card {
      @include size(rem(30));

      svg {
        width: 100%;
        max-width: rem(30);
        max-width: rem(30);
      }
    }

    .icon-booking {
      rect {
        stroke: $primary;
      }

      path {
        &:first-of-type {
          stroke: $primary;
        }

        &:nth-last-of-type(5) {
          stroke: $primary;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3),
        &:nth-last-of-type(4) {
          fill: $primary;
        }
      }
    }

    .icon-site {
      rect {
        stroke: $primary;
      }

      path {
        stroke: $primary;
      }
    }

    .icon-review {
      path {
        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(4),
        &:nth-last-of-type(6) {
          fill: $primary;
        }
      }
    }

    .icon-messenger {
      path {
        stroke: $primary;
      }
    }

    .icon-action-arrow {
      @include size(rem(8), rem(15));
      min-width: rem(11);
      svg {
        width: 100%;
        path {
          stroke: $text-gray-variant;
        }
      }
    }
  }
}

.title-info {
  color: $bg-green;
  font-size: rem(14);
  font-family: $primary-bold-font;
  border: 2px solid $bg-green;
  border-radius: rem(4);
}

.fee-info {
  color: $secondary;
  font-family: $primary-bold-font;
  font-size: rem(12);
}
// end preview page

.iconError {
  width: rem(40);
  path {
    stroke: $danger;
  }
}
.close-icon {
  min-width: rem(28);
  path {
    stroke: $danger;
  }
}
.alert-close-icon {
  svg {
    min-width: rem(17);
    path {
      fill: $danger;
    }
  }
}

.alert-info {
  background: $alert-info;
}
.hours-glass {
  width: rem(15);
  min-width: rem(13);
  path {
    fill: $black;
  }
}
//instant site
.instant-site-widget-wrapper {
  .b-left {
    @include size(rem(132));
  }
}
