.main-footer {
    position  : relative;
    top       : rem(68);
    background: white;
    width     : 100%;
    z-index   : 1;

    .footer-icon-email {
        width: rem(22);

        svg path {
            stroke: $light-text-color;
        }
    }

    .b-list {
        .list-item {
            list-style  : none;
            font-size   : rem(13);
            margin-right: rem(20);
        }

        .list-item-link {
            @include transition(all 0.3s linear);
        }

        .list-item-link,
        .list-item-text {
            background: transparent;
            color: $secondary;
        }

        .icon-feedback {
            width: rem(14);

            svg path {
                fill: $light-text-color;
            }
        }

        .separator {
            font-size  : rem(12);
            line-height: rem(15);
        }
    }

    .footer-icon-down-arrow {
        width : rem(12);
        height: rem(15);

        svg {
            width: 100%;
            @include opacity(0)
        }
    }

    .feedback-popover-wrapper {
        width    : 100%;
        max-width: rem(450);
        position : absolute;
        bottom   : 100%;
        left     : 50%;
        z-index  : 1030;
        transform: translateX(-50%);
        @include transition(all 0.3s linear);

        .title {
            font-size  : rem(18);
            font-family: $tertiary-font;
        }

        .label-text {
            color: $light-text-color;
        }

        .custom-textarea {
            height    : rem(88);
            resize    : none;
            overflow-y: auto;
            @include scrollbar;
            @include placeholder($light-text-color, 0.5);
        }

        .btn-footer-cancel {
            border: rem(1) solid $border-color;

            &:hover {
                color : $danger;
                border: rem(1) solid $danger;
            }
        }
    }

    .footer-action-button {
        width     : rem(22);
        background: transparent;
    }

    .footer-icon-email {
        svg path {
            stroke: $dark-gray;
        }
    }

    .footer-action-email-button {
        display: none;
    }

    .b-content {
        display: flex;
        @include transition(all 0.3s linear);
    }

    .footer-container {
        width: 100%;
        @include transition(all 0.3s linear);
    }
}

.footerFeedbackActive {
    .main-footer {
        background: transparent;

        .footer-action-email-button {
            display: block;
            @include transition(all 0.3s linear);
        }

        .b-content {
            display: none;
        }

        .footer-icon-down-arrow {
            transform        : rotate(-90deg);
            -webkit-transform: rotate(-90deg);
        }

        .footer-container {
            width: rem(85);
        }

        .feedback-popover-wrapper {
            left     : rem(30);
            transform: inherit;
        }
    }
}