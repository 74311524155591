@include media-breakpoint-up(md) { 
  .select-dropdown {
    width: rem(200);
  }
  .page-payment-list {
    .export-dropdown {
      top: rem(46) !important;
      left: 50% !important;
      transform: translate(-50%, 0) !important;
    }
  }
  .sticky-main-header-wrapper-payment{
    height: auto;
    min-height: rem(46);
  }

  .page-payment-trial-preview {
    .b-offer-price {
      margin-bottom: rem(60);
    }
  }

}