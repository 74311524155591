@import '@assets/scss/tools-index.scss';  

.business-hours-wrapper{
    .row-disable{
        @include opacity(0.6);
        *{
            cursor: default; 
        }
    }
    .caret-button-down{
        border-width:rem(6);
    }
    .b-time-session {
        .custom-caret-button-down {
            left: rem(30);
        }
    }
}