///////////////////////////////////
// GOOGLE FONTS API //
///////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800');
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=e0ddf46b-dd79-4548-b61f-8cbeddf9f674");


///////////////////////////////////
// LOCAL FONTS //
///////////////////////////////////
/// 

@font-face {
    font-family: "Open Sans Light";
    src: url("../fonts/OpenSans-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "Open Sans Regular";
    src: url("../fonts/OpenSans-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "Open Sans Medium";
    src: url("../fonts/OpenSans-Medium.ttf") format("truetype")
}

@font-face {
    font-family: "Open Sans Demi Bold";
    src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype")
}

@font-face {
    font-family: "Open Sans Demi Bold";
    src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype")
}

@font-face {
    font-family: "Open Sans Bold";
    src: url("../fonts/OpenSans-Bold.ttf") format("truetype")
}


@font-face {
    font-family: 'MontBold';
    src: url('../fonts/MontBold.eot');
    src: url('../fonts/MontBold.eot') format('embedded-opentype'), url('../fonts/MontBold.woff2') format('woff2'), url('../fonts/MontBold.woff') format('woff'), url('../fonts/MontBold.ttf') format('truetype'), url('../fonts/MontBold.svg#MontBold') format('svg');
}

@font-face {
    font-family: 'MontRegular';
    src: url('../fonts/MontRegular.eot');
    src: url('../fonts/MontRegular.eot') format('embedded-opentype'), url('../fonts/MontRegular.woff2') format('woff2'), url('../fonts/MontRegular.woff') format('woff'), url('../fonts/MontRegular.ttf') format('truetype'), url('../fonts/MontRegular.svg#MontRegular') format('svg');
}