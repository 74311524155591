// @doc off
// These defaults make the arguments optional for this mixin
// If you like, set different defaults before importing.
// @doc on

@import shared

// The default color for box shadows
$default-box-shadow-color: #333333 !default

// The default horizontal offset. Positive is to the right.
$default-box-shadow-h-offset: 0px !default

// The default vertical offset. Positive is down.
$default-box-shadow-v-offset: 0px !default

// The default blur length.
$default-box-shadow-blur: 5px !default

// The default spread length.
$default-box-shadow-spread: false !default

// The default shadow inset: inset or false (for standard shadow).
$default-box-shadow-inset: false !default

// Provides cross-browser for Webkit, Gecko, and CSS3 box shadows when one or more box
// shadows are needed.
// Each shadow argument should adhere to the standard css3 syntax for the
// box-shadow property.
=box-shadow($shadow-1: default, $shadow-2: false, $shadow-3: false, $shadow-4: false, $shadow-5: false, $shadow-6: false, $shadow-7: false, $shadow-8: false, $shadow-9: false, $shadow-10: false)
  @if $shadow-1 == default
    $shadow-1: -compass-space-list(compact(if($default-box-shadow-inset, inset, false), $default-box-shadow-h-offset, $default-box-shadow-v-offset, $default-box-shadow-blur, $default-box-shadow-spread, $default-box-shadow-color))
  $shadow: compact($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  +experimental(box-shadow, $shadow, -moz, -webkit, not -o, not -ms, not -khtml, official)

// Provides a single cross-browser CSS box shadow for Webkit, Gecko, and CSS3.
// Includes default arguments for horizontal offset, vertical offset, blur length, spread length, color and inset.
=single-box-shadow($hoff: $default-box-shadow-h-offset, $voff: $default-box-shadow-v-offset, $blur: $default-box-shadow-blur, $spread: $default-box-shadow-spread, $color: $default-box-shadow-color, $inset: $default-box-shadow-inset)
  @if not ($inset == true or $inset == false or $inset == inset)
    @warn "$inset expected to be true or the inset keyword. Got #{$inset} instead. Using: inset"
  @if $color == none
    +box-shadow(none)
  @else
    $full: $hoff $voff
    @if $blur
      $full: $full $blur
    @if $spread
      $full: $full $spread
    @if $color
      $full: $full $color
    @if $inset
      $full: inset $full
    +box-shadow($full)
