////////////////////////////////////////////
// Bootstrap Alert Customization
//////////////////////////////////////////// 
.alert{
    border: none;
    padding: rem(12) rem(15);
    &-primary{
        color: $primary;
        background-color: rgba($primary,.05);
    }
    &-success{
        color: $success;
        background-color: rgba($success,.15);
    }
    &-warning{
        background-color: $warningbox-bg-color;
        color: $warningbox-color;
    }
    &-danger{
        color: $danger;
        // background-color: rgba($danger,.05);
        background-color: #F9D7D4;
    }
    &-info{
        color: $info;
        background-color: rgba($info,.1);
    }
}
 
// .thumbtack-alert-danger{
//     background-color: #F9D7D4;
//     .button-remove{
//         min-width: rem(27);
//         @include size(rem(27));
    
//         svg {
//             width: 100%;
    
//             path {
//                 stroke: $danger;
//             }
//         }
//     }
//     .icon-alert-close{
//       @include size(rem(16));
  
//       svg path {
//           fill: $danger;
//       }
//   }
// }