@include media-breakpoint-up(lg) {

    // full page loader
    .full-page-loader {
        .center-progress {
            &:before {
                background-size: 40%;
            }
            &:after {
                background-size: 60%;
            }
        }
    }
    // end full page loader

    // sticky header
    .sticky-wrapper {
        padding: 0 rem(15);
    }

    // booking calendar    
    .calendar-square-loader {
        width: calc(14% - 8px);
    }

    // payment advance search
    .custom-page-payment-list {

        .advanced-dropdown {
            max-width: rem(440);
            min-width: rem(440);
            padding  : rem(10);
        }

        .custom-select-dropdown {
            width: rem(160);
        }
    }

    // contact auto search
    .contact-auto-search{
        width:rem(215);
    } 

    //contact details widget height settings
    $c-details-chat-widget-height: rem(870);
    $c-details-note-widget-height: rem(450);

    //contact details personal information 
    .c-details-personal-information-widget{
        min-height: $c-details-chat-widget-height;
    }
    //contact details chatbox height 
    .c-details-chat-widget{  
        height: $c-details-chat-widget-height;
        .conversation-content-wrapper{
            padding-left: 0;
        }
        .conversation-wrapper{
            height: calc(#{$c-details-chat-widget-height} - 4px);
        }
        .conversation-wrapper.is-contact-chat-search-wrapper{

            .conversation-content-wrapper{
                height: calc(#{$c-details-chat-widget-height} - 260px);
            }

            .compose-area-has-more-content{ 
                height: calc(#{$c-details-chat-widget-height} - 260px - 35px);
            }
        } 
        .conversation-wrapper.is-contact-search-header-enabled .conversation-body{

            .conversation-content-wrapper{
                height: calc(#{$c-details-chat-widget-height} - 280px);
            }
            .compose-area-has-more-content{ 
                height: calc(#{$c-details-chat-widget-height} - 280px - 35px);
            }
        } 
        .b-no-data-chat{
            height: calc(#{$c-details-chat-widget-height} - 65px); //header height
        }
    }

    //contact details activity height
    .c-details-activity-widget{
        height: $c-details-note-widget-height;
        .content{
            min-height: 100%;
        }
        .content-list .b-status-list{
            margin-bottom: 0;
            max-height: calc(#{$c-details-note-widget-height} - 90px);
        }
    }

    //contact details note height
    .c-details-notes-widget{
        .b-card-notes{
            margin-bottom: 0 !important;
        }
        .note-content{ 
            height: calc(#{$c-details-note-widget-height} - 90px);
        }
    } 
}