.sidemenu-panel.sidemenu-panel-update {
    min-height: calc(100vh - 108px);
    height    : calc(100vh - 108px);
    top       : rem(105);
    width     : rem(80);
    position  : fixed;
    z-index   : 1;
    @include transition(all 0.3s linear);
    overflow: auto;
    left    : rem(-80);
    @include scrollbar;
    overflow-y        : scroll;
    -ms-overflow-style: none;
    scrollbar-width   : none;

    &::-webkit-scrollbar {
      display: none;
    }
}

@media screen and (max-width: 575px) {
    .sidemenu-panel-container {
        padding-top: 38px;
    }
}

@media screen and (max-width: 575px) {
    .sidemenu-panel-container-for-upgrade {
        padding-top: 78px;
    }
}

.b-sidemenu {
    margin: rem(45) 0;

    .item {
        list-style   : none;
        margin-bottom: rem(30);
        position     : relative;

        &:hover .text {
            color: $secondary;
        }

        a {
            padding-left: rem(10);
            white-space : nowrap;
            color       : $body-color;
        }
    }

    .icons {
        vertical-align: middle;
        width         : rem(30);
        height        : rem(20);
        text-align    : center;
        position      : relative;
        margin-right  : rem(10);

        .btn-icon {
            margin  : 0 auto;
            position: absolute;
            margin-top: rem(-2);
            width: 100%;
            // @include size(rem(20), rem(20));
            @include transform(translate(-50%, -50%));
            top       : 50%;
            left      : 50%;
            max-height: rem(20);
        }
    }

    .normal-icon {
        display: block;
    }

    .icon-is-active {
        display: none;
    }

    .text {
        font-size     : rem(14);
        vertical-align: middle;
        width         : 0;
        overflow      : hidden;
        line-height   : rem(21);
        height        : rem(20);
        @include transition(width 0.3s linear);
        display: inline-block;
    }

    .item-active {
        &:before { 
            content         : '';
            position        : absolute;
            left            : 0;
            width           : rem(3);
            height          : rem(15);
            top             : 50%;
            margin-top      : -(rem(7.5));
            background-color:$primary;
            @include border-radius(rem(32));
        }

        .normal-icon {
            display: none;
        }

        .icon-is-active {
            display: block;
        }

        .text {
            color      : $secondary;
            font-family: $secondary-bold-font;
        }
    }
}


.main-wrapper  {
    // padding-left: rem(0);   
    overflow-x  : hidden;

    main.main {
        position: relative;
        @include transition(all 0.3s linear);
        left: 0;
    }
}


.side-menu-open {
    .sidemenu-panel.sidemenu-panel-update {
        width: $sidebar-width;
        left : 0;
    }

    .b-sidemenu {
        li {
            .text {
                width: calc(90% - 30px);
            }
        }
    }

    .main-wrapper {
        // overflow: hidden;

        main.main {
            left           : $sidebar-width;
        }
    }
}

// sidemenu custom breakpoint

@media (min-width: 1024px) {

    .main-wrapper {
        padding-left: rem(80); 
    }

    .sidemenu-panel.sidemenu-panel-update {
        left: 0;
    }

    .side-menu-open {
        .sidemenu-panel.sidemenu-panel-update {
            left: 0;
        }

        .main-wrapper {
            main.main {
                left        : 0;
                padding-left: rem(170);
            }
        }
    }
}

@media (min-width: 1500px) {

    .main-wrapper {
        main.main {
            left: rem(0);
        }
    }

    .side-menu-open {
        .main-wrapper {
            main.main {
                left        : rem(0);
                padding-left: rem(170);
            }
        }
    }
}

.side-menu-open .global-create-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px 10px 12px;
    width: 200px;
    height: 40px;
    left: 18px;
    top: 112px;
    // background: #0357FF;
    cursor: pointer;
    /* Neutral / 01.5 - Light Line */
    border: 1px solid #EDF1F6;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.5);
    border-radius: 40px;
}

.global-create-btn{
    border-radius: 48px;
    width: 48px;
    height: 48px;
    border: 1px solid #EDF1F6;
}
.global-create-btn:hover{
    background: #1041A3 !important;
}
.global-create-btn-bg{
    background: #0357FF;
}
.global-create-btn-bg-active{
    background: #1041A3;
}
.side-menu-open .global-create-btn-txt{
    height: 20px;
left: 48px;
right: 94px;
top: calc(50% - 20px/2);

font-family: 'Open Sans Regular';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 17px;
/* or 121% */

display: flex;
align-items: flex-end;
letter-spacing: 0.35px;
text-transform: uppercase;

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 12px;
}
.global-create-btn-txt{
    display: none;
}

.plus-icon{
    left: 4.17%;
    right: 4.17%;
    top: 45.83%;
    bottom: 45.83%;
    background: #FFFFFF
}

