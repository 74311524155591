@include media-breakpoint-up(xl) {
  .side-menu-open {
    .enterprises-wrapper {
      .is-header-sticky {
        left : rem(250);
        width: calc(100% - 250px);
      }
    }
  }

  // enterprise user header
  .is-enterprise-user {
    .main-header.enterprise-main-header {

      .logo-block {
        max-height: rem(68);
      }
    }
  }

  // end enterprise user header
}


@include media-breakpoint-up(xxl) {
  .custom-page-managers-list {
    .table thead tr th:nth-last-of-type(1) {
      width: rem(125);
    }
  }
}