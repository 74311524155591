.main-header { 
    .dropdown {
        width : rem(125);
        height: rem(40);
        margin: 0;
    }

    .b-select-custom {
        width : rem(125);
        height: rem(40);
    }

    .select-custom {
        width : rem(125);
        height: rem(40);
    }
    .select-dropdown-custom{
        height: rem(40);
        > div{
            min-height: rem(40);
            border-color: $dark-gray;
        }
    } 
}

.icon-badge {
    top   : rem(20);
    right : rem(20);
    width : rem(22);
    height: rem(22);
}

.inner-content {
    width: calc(100% - 100px);
}

.list-content {
    min-height: auto;
}

.select-dropdown-custom {
    height: rem(36);
}

.filter-dropdown-icon {
    top      : 50%;
    transform: translateY(-50%);
    right    : rem(15);
}

.b-list,
.custom-placeholder {
    min-height: rem(168);
}

.b-image {
    width    : rem(80);
    min-width: rem(80);
    height   : rem(80);
}

.image {
    object-fit: cover;
}

.user-name {
    font-size    : rem(16);
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.user-email {
    font-size    : rem(14);
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
    font-family  : $primary-font;
    color        : $secondary;
    &:hover {
        text-decoration: none;
        color: $primary;
    }
}

.date-create {
    font-size: rem(12);
}

.list-footer {
    border-top: rem(1) solid $border-color;

}

.manage-user {
    width      : 50%;
    height     : rem(47);
    line-height: rem(47);
    font-family: $secondary-bold-font;
    font-size  : rem(12);
    cursor     : pointer;
    @include transition(all 0.2s linear);
    background-color: transparent;
    &:after {
        position        : absolute;
        right           : 0;
        width           : rem(1);
        height          : 100%;
        background-color: $border-color;
        content         : '';
    }

    &:hover {
        background-color: $tertiary; 
        .manage-dropdown-block {
            @include visibility(visible);
            margin-top: 0;
        }
    }
}

.manage-dropdown-block {
    @include visibility(hidden);
    @include transition(all 0.2s linear);
    border : none;
    z-index: 1;
    margin-top: rem(-5);
}

.dropdown-item {
    list-style   : none;
    border-bottom: rem(1) solid $border-color;
    font-family  : $font-weight-regular;
    line-height: normal;
    &:hover {
        color: $primary;
    }
}

.manage-user-btn {
    color: $primary;
    font-size   : rem(12);
    &:hover{
        color: inherit;
    }
    svg path {
        fill: $primary;
    }
} 

.active-btn {
    width           : 50%;
    background-color: #fff;
    height          : rem(47);
    font-family     : $secondary-bold-font;
    font-size       : rem(12);
    cursor          : pointer;
    color           : $light-text-color;
    @include border-radius(0 0 $border-radius-sm 0);
    &:hover{
        background-color: $tertiary;
    }
}

.create-block {
    font-size: rem(12);
}
.b-popup-header {
    .custom-modal-close {
        top: rem(-10);
        right: 0;
    }
    .progress-item {
        cursor: pointer;
        list-style: none; 
        border-top: rem(2) solid $border-color;
        &:before {
            content: '';
            width: rem(18);
            height: rem(18);
            border-radius: 50%;
            background: $progress-bg;
            border: rem(2) solid $border-color;
            position: absolute;
            top: rem(-10);
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
        &:first-child:after,
        &:last-child:after {
            position: absolute;
            content: '';
            top: rem(-2);
            width: 50%;
            height: rem(4);
            background: $body-bg;
        }
        &:first-child:after{
            left: 0;
        }
        &:last-child:after {
            right: 0;
        }
    }
    .active {
        color: $primary;
        font-family: $headings-font-family;
        &:before {
            background: $body-bg;
            border: rem(5) solid $primary;
        }
    }
    .disable-step{
        cursor: not-allowed;
    }
}
.list-text {
    color: $secondary;
}
.product-icon {
    width: rem(20);
    height: rem(20);
    line-height: rem(20);
}
.product-list-item {
    height: rem(54);
    cursor: pointer;
}
.product-list-item-field {
    @include visibility(hidden);
    position: absolute;
}