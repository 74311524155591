.form-control {
    @include border-radius(rem(4));
    @include box-shadow(none);
    //height      : rem(44);
    font-size   : rem(14);
    border-color: $border-color;
    @include placeholder($secondary, 0.8);

    //to hide default type=number arrows
    &[type=number]::-webkit-inner-spin-button, 
    &[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
    &[type=number] {
        -moz-appearance:textfield;
    }

    //set diferent size of text field
    &.form-control-xl {
        height: rem($height-xl);
    }

    &.form-control-lg {
        height: rem($height-lg);
    }

    &.form-control-md {
        height: rem($height-md);
    }

    &.form-control-sm {
        height: rem($height-sm);
    }
}

.text-as-placeholder{
    color: rgba($secondary, 0.8);
}

input.form-control {

    //set diferent size of text field
    &.form-control-xl {
        height: auto;
        min-height: rem($height-xl);
    }

    &.form-control-lg {
        height: auto;
        min-height: rem($height-lg);
    }

    &.form-control-md {
        height: auto;
        min-height: rem($height-md);
    }

    &.form-control-sm {
        height: auto;
        min-height: rem($height-sm);
    }
}


select {
    &.form-control {
        -webkit-appearance: none;
        -moz-appearance   : none;
        appearance        : none;
        padding-right     : rem(30);
        background        : url('https://dufzo4epsnvlh.cloudfront.net/image/icons/arrow-thin-down.svg') no-repeat calc(100% - 15px) center;
    }
}

.form-group {
    sup {
        color  : $danger;
        padding: 0 rem(3);
    }

    .react-datepicker-wrapper {
        display: block;
    }

    .datepicker {
        cursor: pointer;
        background: url('https://gosite-assets.s3-us-west-1.amazonaws.com/image/activity-icons/calendar-dark.svg') no-repeat calc(100% - 15px) center;
        background-size: rem(22);
    }

    .prevent-typing{
        cursor: pointer !important;
        color: transparent;
        text-shadow: 0px 0px 0px $gray-700;
        &:focus{
            cursor: pointer !important;
        }
    }

    .label-text {
        margin-bottom: rem(10);
        font-size    : rem(12);
        color        : $secondary;
        font-family  : $secondary-bold-font;

        &.lowercase-text {
            font-size: rem(16);
        }
    }

    .form-error {
        color        : $danger;
        margin-left  : rem(8);
        font-size    : rem(11);
        margin-bottom: rem(5);
        display      : inline-block;
        padding      : rem(3) rem(10);
        border       : rem(1) solid $danger;
        font-family  : $secondary-bold-font;
        @include border-radius($input-border-radius-sm);
    }

    .star {
        color: $danger;
        font-family: $primary-font;
        font-style: normal;
    }
}

.form-success{
    background-color: #f4fff4;
    border: rem(1) solid $success; 
    color: $success;
    font-size    : rem(11);
    font-family  : $secondary-bold-font;
    @include border-radius($input-border-radius-sm);
    padding: rem(6) rem(10);
    display      : inline-block;
    padding      : rem(3) rem(10);
    text-align: center; 
}

//b-search-field
.b-search-field {
    position: relative;

    .form-control {
        font-size: rem(12);
        min-width: rem(160);
        @include border-radius($input-border-radius);
    }

    .btn-search {
        position: absolute;
        right   : 0;
        top     : 0;

        img {
            display: block;
        }
    }
    .custom-search-input{
        padding-right: rem(40);
    }
}

//form-check
.form-check {
    padding: 0;
    color: $secondary;

    label {
        position: relative;
        margin  : 0;
        cursor  : pointer;

        input {
            position: absolute;
            z-index : -1;
            @include opacity(0);

            &:checked+.checkbox-icon {
                border-color   : $border-color;
                background     : $primary url('https://dufzo4epsnvlh.cloudfront.net/image/icon-new/tick-icon.svg') no-repeat center;
                background-size: rem(12);
                border: 0; 
            }
            &[disabled]+ .checkbox-icon{
                background-color: #e7e7e7;
                background: #e7e7e7;           
                border: solid rem(1) #ccc;
                cursor: not-allowed;
            }
            &[disabled][checked]+ .checkbox-icon{ 
                background: #ccc url('https://dufzo4epsnvlh.cloudfront.net/image/icon-new/tick-icon.svg') no-repeat center;  
                background-size: rem(12);
            }
        }

        .checkbox-icon {
            vertical-align: middle;
            display       : inline-block;
            width         : rem(20);
            min-width     : rem(20);
            height        : rem(20);
            border        : rem(1) solid $border-color;
            @include border-radius($input-border-radius-sm);
            background: white;
            position  : relative;
            top       : rem(-2);
            &:hover {
                border: rem(2) solid $primary;
                @include transition(all 0.1s linear);
            }
        }
    }
    &--has-label{
        label{
            padding-left: 30px; 
            .checkbox-icon {
                position: absolute;
                left: 0;
                top: rem(2);
            }
        }
    }
}

//form-radio
.form-radio {
    $size: 18;
    padding: 0;
    color: $secondary;
    position: relative;
    padding-left: rem($size + 10); 
    margin  : 0;
    cursor  : pointer;

    input {
        position: absolute;
        z-index : -1;
        left: 0;
        top: 0;
        @include opacity(0);

        &:checked+.radio-icon {
            border-color:  $primary;
            &::after{
                background-color: $primary;
            }
        }
        &[disabled]+ .radio-icon{
            background-color: #e7e7e7;
            background: #e7e7e7;           
            border: solid rem(1) #ccc;
            cursor: not-allowed;
        }
        &[disabled][checked]+ .radio-icon::after{ 
            background-color: #bbb;
        }
    }

    .radio-icon {
        vertical-align : middle;
        display : flex;
        align-items: center;
        justify-content: center;
        border : rem(1) solid $radio-btn-border;
        background : white;
        position : absolute;
        left: 0;
        top : 0;
        @include size(rem($size));
        @include border-radius(rem($size));
        @include transition(all 0.2s linear); 
        &::after{
            content: '';
            background-color: transparent; 
            @include size(rem($size - 10));
            @include border-radius(rem($size));
            @include transition(all 0.2s linear); 
        }
    }
}

.form-switch {
    label {
        position: relative;
        margin  : 0;
        cursor  : pointer;

        .checkbox-icon {
            vertical-align: middle;
            display       : inline-block;
            width         : rem(30);
            height        : rem(18);
            @include border-radius(rem(18));
            background: $gray-200;
            position  : relative;
            top       : rem(-2);

            &:after {
                width : rem(18);
                height: rem(18);
                @extend .rounded-circle;
                display   : block;
                content   : '';
                left      : rem(-2);
                position  : absolute;
                background: white;
                // @extend .shadow;
                @include transition(all 0.1s linear);
                box-shadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.199304);
            }
        }

        input {
            position: absolute;
            z-index : -1;
            @include opacity(0);

            &:checked+.checkbox-icon {
                background: $primary;
                &::after {
                    background: white;
                    left      : rem(13);
                }
            }
        }
    }
    &--has-label{
        label{
            padding-left: 45px;
            .btn-icon:not(.btn-icon--children){
                position: static;
            }
            .checkbox-icon{
                position: absolute;
                left: 0;
                top: rem(2);
            }
        }
    }
    &--size-lg{
        label {
            .checkbox-icon { 
                width         : rem(60);
                height        : rem(28);
                @include border-radius(rem(28));
                top       : rem(-2);
    
                &:after {
                    width : rem(28);
                    height: rem(28); 
                    left      : rem(-2); 
                    top: 0;
                }
            }
    
            input {  
                &:checked+.checkbox-icon {
                    &::after {
                        left      : rem(34); 
                    }
                }
            }
        }
    }
}

// form buttons
.add-btn {
    width: 100%;
}

.cancel-btn {
    color : $secondary;
    border: rem(1) solid $border-color;

    &:hover {
        color       : $secondary;
        border-color: $secondary;
    }
}

// Media Query
@include media-breakpoint-up(sm) {
    .add-btn {
        width: auto;
    }
    // .cancel-btn {
        // width: rem(180);
    // }
}
.without-gmb-btn{
    text-align:center 
}