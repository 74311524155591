@import "@assets/scss/tools-index.scss";

.left-section {
    background: #131313;

    border-radius: 0.3em 0em 0em 0.3em;

    > div:first-child {
        padding: 81px 43px 0px 43px;
    }

    > div:nth-child(2) {
        padding: 79px 9px 24px 9px;
        a {
            color: #3ec1fa;
        }
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
        width: 100%;

        border-radius: 0.3em 0.3em 0em 0em;

        > div:first-child {
            padding: 66px 12px 0px 12px;
        }

        > div:nth-child(2) {
            padding: 24px 9px 24px 9px;
        }
    }
}

.right-section {
    padding: 97px 26px 0px 46px;
    > img {
        min-width: 286px;
        max-width: 286px;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        width: 100%;
        padding: 19px 9px 29px 9px;
        justify-content: center;
    }

    @media screen and (max-width: 375px) {
        > img {
            min-width: 195px;
            max-width: 195px;
            width: 195px;
        }
    }
}

.header-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;

    > .header {
        position: relative;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        text-align: center;

        color: #ffffff;
        > svg {
            position: absolute;
            top: calc(100% - 8px);
            left: 0;
            width: 100%;
        }
    }
}

.amount-due {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-align: center;

    color: #5ac58d;
    width: 100%;
    margin-top: 16px;
}

.subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;

    color: #ffffff;
    margin-top: 16px;
}

.upgrade-btn {
    margin-top: rem(57);
    width: 100%;

    @media screen and (max-width: 768px) {
        margin-top: rem(19);
    }
}

.subtitle2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;

    color: #ffffff;
}

.model {
    :global(.modal-custom-md) {
        min-width: 768px;
    }

    :global(.modal-body) {
        padding: 0;
    }

    @media screen and (max-width: 768px) {
        :global(.modal-custom-md) {
            min-width: 80%;
        }
    }

    @media screen and (max-width: 375px) {
        :global(.modal-custom-md) {
            min-width: 308px;
        }
    }
}
.container {
    display: flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.talk-to-sales {
    color: #0357ff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
