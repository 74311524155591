////////////////////////////////////////////
// Payment Card Widget
////////////////////////////////////////////

.payment-card-widget{
    min-height: 100%;
    padding: rem(20);
    .card-image{
        width: rem(77);
        min-width: rem(77);
        img{
            display: block;
            @include border-radius($border-radius);
        }
    }
    .card-number{
        font-size: rem(14);
        color: $secondary;
        font-family: $secondary-bold-font;
    }
    .primary-card{
        padding: rem(2) rem(5);
        border: solid rem(1) rgba($primary, .2);
        color: $primary;
        font-size: rem(10);
        @include border-radius($border-radius);
    }
    .expiry-date{
        font-size: rem(12);
        color: $light-text-color;
    }
    .dropdown-arrow{
        @include size(rem(16)); 
        cursor: pointer;
        svg{
            width: rem(11);
        }
        svg path{
            fill: $light-text-color;
        }
    }
    .drop-down-block{
        &:hover{
            .dropdown-arrow{
                svg path{
                    fill: $primary;
                }
            }
            .dropdown-menu-cover{
                @include visibility(visible);
            }
        }
        .dropdown-menu-cover{
            @include visibility(hidden);
            @include transition(all 0.2s linear);
            margin-top: rem(-5);
            padding-top: rem(10);
            position: absolute;
            top: 100%;
            right: rem(-21);
            z-index: 1;
        }
        .dropdown-menu{
            display: block;
            position: static;
        }
    }
    .dropdown-menu{
        background-color: #fff;
        border: rem(1) solid $border-color-light;
        @include box-shadow($dropdown-shadow);
        @include border-radius($border-radius);
        padding: rem(5) 0;
        overflow: hidden;
        .dropdown-menu-item{
            background-color: #fff;
            @include transition(all 0.2s linear);
            padding: rem(10) rem(15);
            font-size: rem(14);
            &:hover{
                background-color: $light-gray;
            }
        }
    }
    &--add{ 
        border: rem(1) dashed #D1DBE1; //no variable for this border color
        font-size: rem(12); 
        min-height: rem(95);
        @include transition(all 0.2s linear);
        &:hover{
            border-color: $primary;
        }
    }
    ////////////////////////////////////////////
    // Media Query
    ////////////////////////////////////////////
    @media (min-width: 1200px) and (max-width: 1299px) {
        padding: rem(10);
    }
}

