@include media-breakpoint-down(md) {

    // sticky header
    .sticky-wrapper {
        display: none;
    }

    .is-header-sticky {
        position: static;
    }
    //contact details note height
    .c-details-notes-widget{
        .b-card-notes {
            padding-bottom: 0;
        }
    }
}
