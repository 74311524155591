.details-page {
  .icon-information {
    width: rem(16);
    height: rem(16);
    svg {
      @include opacity(0.4);
    }
  }
  .icon-fill-calendar {
    width: rem(12);
    height: rem(12);
    svg path {
      fill: white;
    }
  }
  .b-information-tooltip {
    min-width: rem(180);
    .description-text {
      font-family: $primary-font;
    }
  }
  .complete-transaction-icon {
    width: rem(15);
    svg path {
      stroke: white;
    }
  }
  .booking-history-close-icon {
    svg {
      path {
        stroke: $primary;
      }
    }
  }
}
.b-loader-calendar {
  // for calendar header loader
  width: 100%;
  position: absolute;
  top: rem(8);
}
.outline-icon {
  width: rem(30);
  height: rem(30);
  cursor: pointer;
  @include border-radius(rem(4));

  .mob-icon,
  .email-icon {
    @include opacity(0.7);
  }
}

.status {
  font-size: rem(12);
  // font-family: $secondary-bold-font;
}

// .pending {
//     color: $warning;
// }
// .confirmed {
//     color: $success;
// }
// .canceled {
//     color:$danger;
// }
.message-main {
  width: rem(100);
  @include text-truncate();
}

.label_text {
  // font-size: rem(12);
  // font-weight: normal;
  // display: block;
  // margin-bottom: rem(5);
  // min-height: rem(25);
  // line-height: rem(16);
  // font-family: $headings-font-family;
  color: $light-text-color;
  // text-transform: uppercase;
}

.booking-icon {
  height: rem(18);

  svg {
    display: block;
    height: 100%;

    rect {
      stroke: $primary;
    }

    path:nth-child(2),
    path:nth-child(3) {
      stroke: $primary;
    }
  }
}

.btn-color {
  color: $secondary;

  &:hover {
    color: $primary;
  }
}

.label-value {
  font-size: rem(16);
  color: $secondary;
  .customer-email {
    max-width: calc(100% - 35px);
  }
}

.link-color {
  font-size: rem(16);
  color: $light-text-color;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.extra-bold {
  font-size: rem(18);
  font-family: $headings-font-family;
  color: $secondary;
}

.total-amout {
  font-size: rem(24);
  color: $primary;
  font-family: $primary-font;
  font-weight: 700;
}

.paid-amount {
  font-size: rem(24);
  color: $success;
  font-family: $primary-font;
  font-weight: 700;
}
.pointer {
  cursor: pointer;
}

.total-due {
  font-size: rem(24);
  color: $danger;
  font-family: $primary-font;
  font-weight: 700;
}

.warning-alert {
  background-color: $warningbox-bg-color;
  color: $warning;
  padding: rem(6) rem(10);
  border-radius: rem(3);
  font-size: rem(13);
  font-family: $font-family-base;
  position: relative;
  border: solid rem(1) $warning;
  margin-right: rem(10);
}

// .height-450{
//     min-height:rem(450);
// }
.reschedule-btn {
  color: $secondary;
  border: rem(1) solid $border-color;

  &:hover {
    border: solid rem(1) $primary;
    color: $primary;
  }
}
.edit-booking-btn {
  color: $secondary;
  border: rem(1) solid $border-color;

  &:hover {
    border: solid rem(1) $primary;
    color: $primary;
  }
  .edit-icon-wrap {
    @include size(rem(14));

    svg path {
      fill: $primary;
    }
  }
}
.edit-icon-wrap {
  width: rem(14);
  height: rem(14);
  color: $primary;

  svg path {
    stroke: $primary;
  }
}

.cancel-btn {
  border: rem(1) solid $border-color;

  &:hover {
    border: solid rem(1) $danger;
    color: $danger;
  }
  .cancel-icon-wrap {
    @include size(rem(14));
    svg path {
      stroke: $danger;
    }
  }
}
.confirm-btn {
  border: rem(1) solid $border-color;

  &:hover {
    border: solid rem(1) $success;
    color: $success;
  }
}
.cancel-icon-wrap {
  width: rem(14);
  height: rem(14);
  color: $secondary;

  svg path {
    stroke: $danger;
  }
}
.confirm-icon-wrap {
  width: rem(14);
  height: rem(14);
  color: $secondary;

  svg path {
    stroke: $success;
  }
}
.remove-btn {
  background-color: $danger;
  border: solid rem(1) $danger;
  border-radius: rem(4);
  // width:75%;
  color: $body-bg;

  &:hover {
    color: $body-bg;
  }
}

.details-list {
  padding: rem(10);
  list-style: none;

  li {
    margin: 0 0 rem(10);

    .label {
      font-size: rem(14);
      font-weight: normal;
      color: $secondary;
      position: relative;
      display: inline-block;
      line-height: rem(25);
      font-family: $font-family-base;
      vertical-align: top;
    }

    .details {
      font-size: rem(14);
      font-weight: normal;
      color: $secondary;
      display: inline-block;
      line-height: rem(25);
      vertical-align: top;
      margin-left: rem(5);
      font-family: $secondary-bold-font;
    }
  }
}

.b-history-data {
  .item {
    list-style: none;
    padding-bottom: rem(40);

    &:last-child {
      padding-bottom: 0;
    }

    // border-left: solid rem(1) #EDF1F5;
    .booking-icon {
      left: rem(-12);
      top: 50%;
      transform: translateY(-50%);
      // width: rem(48);
      // height: rem(48);
    }

    .item-title {
      font-family: $open-sans-regular;
    }

    .item-details {
      font-size: rem(12);
    }
  }
}

.split-btn {
  width: rem(68);
  border: rem(1) solid $border-color;
  border-radius: rem(5);
  height: rem(38);
  z-index: 1;
  .active-split-btn {
    width: rem(28);
    height: rem(28);
    @include border-radius(rem(2));
    &:hover {
      background: $dark-gray;
    }
  }
  .btn-switch {
    width: rem(16);

    svg path {
      fill: $secondary;
    }
  }
  .btn-switch-list {
    width: rem(16);

    svg {
      path:nth-of-type(1),
      path:nth-of-type(2) {
        stroke: white;
      }

      rect {
        stroke: white;
      }
      path {
        fill: white;
      }
    }
  }
}

// ////////////// trial  page
.page-booking-trial-preview {
  .b-header {
    svg {
      width: rem(24);

      path {
        stroke: $primary;
      }
    }

    .title-text {
      font-size: rem(18);
      letter-spacing: rem(1);
      font-family: $primary-bold-font;
    }

    .symbol-registered {
      font-size: rem(21);
      font-family: $secondary-light-font;
    }

    .sub-title-text {
      font-size: rem(20);
      font-family: $primary-bold-font;
    }
  }

  .section-trial-list {
    max-width: rem(810);
    margin-bottom: rem(60);

    .title-text {
      font-size: rem(20);
      font-family: $primary-bold-font;
    }

    .b-trial-list {
      padding-left: rem(50);
    }

    .icon-badge {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .trial-list-item {
      font-size: rem(16);
    }
  }

  .section-trial-testimonial {
    max-width: rem(850);
    padding: rem(20);
    margin-bottom: rem(40);
    background: $grey-background;

    .testimonial {
      font-size: rem(16);
    }

    .author-name {
      font-size: rem(14);
    }
  }

  .section-trial-connect,
  .section-trial-footer {
    max-width: rem(740);
    margin-bottom: rem(40);

    .title-text {
      font-size: rem(20);
      font-family: $primary-bold-font;
    }

    .sub-title {
      font-size: rem(16);
    }

    .box-title-text {
      font-family: $primary-bold-font;
    }

    .box-title-text,
    .content-para {
      font-size: rem(16);
    }
  }
}

//   END OF TRIAL PAGE

// booking list page
.page-list {
  .custom-date-picker {
    font-size: rem(12);
    height: rem(40);
    width: 100%;
  }
  .btn-switch svg {
    rect {
      stroke: $secondary;
    }
    path {
      fill: $secondary;
      &:nth-last-of-type(5),
      &:nth-last-of-type(6) {
        stroke: $secondary;
      }
    }
  }
  .status-dropdown {
    width: 100%;
    font-size: rem(12);
  }
  .date-picker {
    width: 100%;
    input {
      padding: rem(6);
      height: rem(38);
    }
  }
  .booking-status {
    font-family: $secondary-bold-font;
    font-size: rem(12);
  }
  .list-table-tooltip {
    min-width: rem(163);
    cursor: pointer;
  }
  .icon-export {
    width: rem(18);
    svg {
      width: 100%;
      path {
        stroke: $secondary;
      }
    }
  }
}
.message-textarea {
  min-height: rem(66);
  resize: vertical;
}
// end booking list page

// complete transaction popup form

.complete-transaction-form {
  .card-loader {
    .loader-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-61%);
    width: 100%;
  }
  .card-loader-hidden {
    display: none;
    transition: all 2500ms linear;
  }
}

//

.email-phone-error {
  @include border-radius(rem(3));
  font-size: rem(12);
  font-family: $secondary-bold-font;
}
.form-loader {
  background: rgba(white, 0.7);
}
.appt-time-loader {
  height: rem(44);
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 30px);
  background: rgba(white, 0.7);
}
.b-image {
  min-width: rem(30);
  min-height: rem(30);
  height: rem(30);
  width: rem(30);
  .image {
    object-fit: cover;
    height: 100%;
  }
}
.address-close {
  width: rem(20);
  path {
    fill: $text-gray-variant;
  }
}

// booking history activity

.booking-history-activity {
  max-height: rem(380);
}

// End of  booking history activity

:global(.booking-icon-refund svg path) {
  fill: $gray-light;
}
:global(.booking-icon-refund-primary svg path) {
  fill: $primary;
}
:global(.drawer-content-wrapper .drawer-content) {
  position: static;
}

// calendar sideview booking list
.b-sideview-booking-list {
  width: 100%;

  .icon-close {
    @include size(rem(12));
    svg path {
      fill: $text-gray-variant;
    }
  }

  .b-h-dots {
    height: rem(15);
    .dots {
      @include size(rem(3));
      background-color: $light-text-color;
    }
  }
  .btn-tick {
    @include size(rem(24));
    background-color: white;
  }
  .icon-tick {
    svg {
      @include size(rem(14), rem(10));
      path {
        fill: currentColor;
      }
    }
  }
  .b-button-group {
    min-width: auto;
    top: rem(-8) !important; // here using !important is to overwrite the inline style.
  }
  .btn-action {
    height: rem(32);
    svg {
      @include size(rem(11));
    }
    .icon-action-calendar {
      svg path {
        fill: $secondary;
      }
    }
    &:hover {
      background-color: $grey-background;
    }
  }
}
// End calendar sideview booking list

// calendar page
.calendar-btn-split-wrapper {
  .calendar-split-list-btn {
    background-color: $grey-background;
    @include size(rem(32));
    svg {
      @include size(rem(18), rem(14));
    }
  }
  .calendar-list-btn-active {
    svg path {
      fill: $primary;
    }
  }
  .calendar-btn-switch-inactive {
    background-color: $grey-background;
    svg {
      @include size(rem(18));
      min-width: rem(18);
      rect {
        stroke: $secondary;
      }

      path {
        &:first-of-type {
          stroke: $secondary;
        }

        &:nth-last-of-type(5) {
          stroke: $secondary;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3),
        &:nth-last-of-type(4) {
          fill: $secondary;
        }
      }
    }
  }
  .calendar-btn-switch-block {
    @include size(rem(32));
    svg {
      @include size(rem(18));
      min-width: rem(18);
      rect {
        stroke: $primary;
      }

      path {
        &:first-of-type {
          stroke: $primary;
        }

        &:nth-last-of-type(5) {
          stroke: $primary;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3),
        &:nth-last-of-type(4) {
          fill: $primary;
        }
      }
    }
  }
}
.page-booking-calendar {
  .calendar-dropdown-list .calendar-dropdown-label span {
    top: 50%;
    transform: translateY(-50%);
  }
  .b-calander-action-dropdown {
    min-width: auto;
  }
  .calendar-icon-settings {
    @include size(rem(16));
    min-width: rem(16);
  }
  .calendar-btn-settings {
    height: rem(32);
    line-height: rem(32);
  }
  .btn-md-primary {
    height: rem(32);
    line-height: rem(32);
  }
}
.add-booking-tooltip {
  z-index: 1019;
}

// End calendar page
