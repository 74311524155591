#referralrock{
iframe{
    background-color: white;
    height:500px;
    max-height:80vh;
    width:100%;
    border: 0px;
    padding-top:25px
}
.iframe-loader{
    position: absolute;
    top:50%;
    left:49%;
}
}
@media only screen and (min-width: 900px) {
    #referralrock{
    iframe{
        height:550px;
        max-height:95vh;
    }
    }
}