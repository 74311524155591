@import '@assets/scss/tools-index.scss';

.drawer {
  z-index: 1500;
}

.hide-mask-bg-color {
  .drawer-mask {
    background: none;
  }
}
.drawer-content-wrapper {
  width: 100%;

  .drawer-content {
    @include scrollbar;
  }

  .icon-drawer-close {
    svg path {
      fill: $text-gray-variant;
    }
  }

  .quick-view {
    .quick-view-header {
      cursor: pointer;

      i {
        @include size(rem(12));
      }
    }

    .booking-status {
      &.status-pending {
        color: $warning;
      }
    }
  }

  .btn-target {
    @include size(rem(32));
    background-color: transparent;
    border: rem(1) solid $border-color;
    @include transition(all 0.2s linear);

    svg {
      min-width: rem(16);

      path {
        stroke: $secondary;
        @include transition(all 0.2s linear);
      }
    }

    &:hover {
      border: rem(1) solid $primary;

      svg {
        path {
          stroke: $primary;
        }
      }
    }
  }

  .icon-message,
  .icon-phone,
  .icon-email {
    svg {
      @include size(rem(16));

      path {
        stroke: $primary;
      }
    }
  }

  // service card
  .quick-view-service-wrapper {
    .btn-edit {
      @include size(rem(32));
      background-color: transparent;
      border: rem(1) solid $border-color;
      @include transition(all 0.2s linear);

      svg {
        min-width: rem(15);
        max-width: rem(15);

        path {
          fill: $secondary;
          @include transition(all 0.2s linear);
        }
      }

      &:hover {
        border: rem(1) solid $primary;

        svg {
          path {
            fill: $primary;
          }
        }
      }
    }

    .icon-schedule,
    .icon-location {
      max-width: rem(16);
      min-width: rem(16);

      svg {
        max-width: rem(14);
      }
    }

    .icon-schedule {
      svg {
        path {
          fill: $text-gray-variant;
        }
      }
    }

    .icon-close {
      svg {
        @include size(rem(16));
        max-width: rem(16);
        min-width: rem(16);

        path {
          stroke: $danger;
        }
      }
    }

    .icon-calendar-date {
      svg {
        @include size(rem(16));
        max-width: rem(16);
        min-width: rem(16);
      }
    }

    .icon-tick-only {
      svg {
        max-width: rem(18);
        min-width: rem(18);
      }
    }

    .strikethrough {
      .strike {
        text-decoration: line-through;
      }
    }

    .profile-image {
      @include size(rem(16));
      min-width: rem(16);
      object-fit: cover;
    }

    // confirmed button animation
    .status-confirmed {
      .btn-confirm-animation {
        width: 0;
        background-color: $confirm-green;
        @include transition(all 1s linear);
      }

      .text-transition {
        // display: none;
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      }

      .icon-tick-only {
        svg {
          width: 0;
          transition: all 0.6s cubic-bezier(0.42, 0, 0.58, 1);

          path {
            transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
            fill: white;
          }
        }
      }
    }

    // End confirmed button animation
  }

  // End service card

  // booking History
  .quick-view-booking-history-wrapper {
    max-height: rem(380);

    .b-history-data {
      .item {
        list-style: none;
        padding-bottom: rem(40);

        &:last-child {
          padding-bottom: 0;
        }

        .booking-icon {
          height: rem(18);
          left: rem(-1);
          top: 50%;
          transform: translateY(-50%);
        }

        .item-title {
          font-family: $open-sans-regular;
        }

        .item-details {
          font-size: rem(12);
        }
      }
    }

    .booking-history-close-icon {
      svg {
        path {
          stroke: $primary;
        }
      }
    }

    .has-left-space-40 {
      padding-left: rem(40);
    }
  }
}

// booking History

/////////////////////////////////////////////
/////////////// Media Query/////////////////
////////////////////////////////////////////

@include media-breakpoint-up(sm) {
  .drawer-content-wrapper {
    width: rem(384);
  }
}
