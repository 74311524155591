// Contact Notes
.b-card-notes {
    padding-bottom: rem(30);
    border        : rem(1) solid $border-color;
    box-shadow    : 0 rem(1) rem(3) rgba(206, 211, 218, 0.8);
    @include border-radius(rem(6));

    .no-notes {
        height: rem(245);

        h2 {
            font-size: rem(20);
        }
    }

    .b-note-list {
        max-height   : calc(100% - 70px);
        padding-right: rem(15);
    }

    .note-content {
        min-height    : calc(100% - 76px);
        position      : relative;
        padding-bottom: rem(44); 
    }

    .list-item {
        list-style   : none;
        margin-bottom: rem(20);
        padding-right: rem(60);

        &:hover .b-note-action-btn .btn-hover-action {
            @include visibility(visible);
        }
    }

    .b-note-action-btn {
        right: 0;
        top  : 0;

        .btn-hover-action {
            @include visibility(hidden);
        }
    }

    p {
        color      : $secondary;
        font-family: $font-family-base;
    }

    .note-info {
        font-size  : rem(12);
        color      : $grey-variant;
        font-family: $font-family-base;
    }

    .btn-note-trash {
        .icon-note-trash {
            width : rem(16);
            height: rem(16);

            svg path {
                stroke: $danger;
            }
        }
    }

    .btn-note-push-pin {
        .icon-note-push-pin {
            width : rem(17);
            height: rem(17);
            @include opacity(0.6);
        }

        .icon-pinned {
            width : rem(17);
            height: rem(17);

            svg path {
                fill: $primary;
            }
        }
    }

    .btn-note-close {
        background: transparent;
        font-size : rem(24);
        color     : $light-text-color;

        &:focus {
            outline: none;
        }

        &:hover {
            color: $link-color;
        }
    }

    .note-comment {
        bottom: 0;
        right : 0;
        left  : 0;
    }

    .comment-field {
        position: relative;
    }

    .send {
        position : absolute;
        right    : rem(5);
        top      : 50%;
        transform: translateY(-50%);

        svg path {
            stroke: $secondary;
        }

        &:focus,
        &:active {
            outline: none;
        }
    }

    .textarea {
        resize: none;
        padding-top  : rem(12);
        padding-right: rem(50);
    }

    // pagination style  customizing for notes
    .notes-pagination-wrapper {
        bottom: rem(50);

        .b-pagination {
            display    : flex;
            align-items: center;
        }

        .pagination-arrow {
            width : auto;
            height: rem(20);
            background: none;
            img {
                width : rem(12);
                height: rem(12);
            }
        }

        .pagination-next,
        .pagination-prev {
            width : auto;
            height: rem(25);
        }

        .pagination-item {
            width    : rem(25);
            height   : rem(25);
            min-width: rem(25);
            font-size: rem(12);
        }
    }

    // end pagination style  customizing for notes
} 

// For messenger notes
.notes-wrapper {
    .b-card-notes {
        .note-content {
            padding-top: rem(20);
            min-height : calc(100% - 80px);
            height     : calc(100% - 80px);
            position   : relative;
            top        : rem(50);
        }

        .note-nodata {
            margin-top: rem(-20);
        }

        .no-notes {
            height: 100%;
        }

        .icon-no-notes {
            width : rem(70);
            height: rem(70);

            svg {
                width: 100%;

                path {
                    fill: rgba(114, 128, 142, .3);
                }
            }
        }

        .notes-nodata-description {
            font-size: rem(14);
            color    : rgba(114, 128, 142, .7);
        }
    }
}