.resume-subscription-btn {
    background-color: #409c1c;
    padding-right: 24px;
    padding-left: 24px;

    &:hover {
        background-color: #245c0e;
    }
}

.notification-container-top-center {
    top: 200px;
}

.tab-menu {
    //width:320px;
    border-right: 1px solid $border-color;
}
.secMyProfile {
    max-width: rem(760);
}

.tab-menu {
    //width: rem(320);
    border-right: rem(1) solid $border-color;
}
.change-password {
    max-width: rem(340);
}
.menu-header {
    color: $light-text-color;
}
.menu-list {
    margin: 0;
    padding: 0;
    .menu-list-item {
        cursor: pointer;
        padding: rem(15) rem(15) rem(15) rem(25);
        list-style: none;
        position: relative;
        @include border-radius($border-radius);
        color: $secondary;
        i {
            @include opacity(0.5);
            position: absolute;
            left: rem(15);
            margin-top: rem(-1);
        }
        &.active {
            background: $tertiary;
            font-family: $headings-font-family;
            i {
                @include opacity(1);
            }
        }
    }
}
.avatar-section {
    @include size(rem(120));
    position: relative;
    &:hover {
        .delete {
            @include opacity(1);
            @include transition(all 0.3s linear);
        }
    }
}
.delete {
    @include size(rem(32));
    border: solid rem(2) #fff;
    color: #fff;
    text-align: center;
    line-height: rem(28);
    background: #ea4335;
    @include opacity(0);
    @include transition(all 0.3s linear);
    @include border-radius(50%);
    cursor: pointer;
    svg {
        max-width: 98%;
        path {
            stroke: #fff;
        }
    }
}
.avatar-img {
    height: 100%;
    object-fit: cover;
}

.status-verified {
    font-size: rem(10);
    height: rem(22);
    @include border-radius(rem(4));
    padding: 0 rem(10);
    line-height: rem(22);
    svg {
        width: rem(10);
        fill: $bg-green;
        margin: 0 rem(5) 0 0;
    }
}
.status-verified.status-not-verified {
    height: rem(22);
}
.form-success {
    color: $success;
    margin-left: rem(8);
    font-size: rem(11);
    margin-bottom: rem(5);
    display: inline-block;
    padding: rem(3) rem(10);
    border: rem(1) solid $success;
    font-family: $secondary-bold-font;
    @include border-radius($input-border-radius-sm);
}

.b-change-password {
    .hide-button {
        right: rem(12);
        top: rem(8);
        z-index: 10;
        @include opacity(0.8);
        @include transition(all 0.2s linear);
        cursor: pointer;
        svg {
            width: rem(24);
            path {
                fill: $light-text-color;
            }
        }
        &:hover {
            @include opacity(1);
        }
    }
}
.sub-title {
    font-size: rem(12);
    display: inline-block;
    width: 100%;
}
.product-item-left {
    padding-left: rem(40);
}
.b-products {
    .product-icon {
        width: rem(22);
        min-width: rem(22);
    }
    .tooltip-content {
        bottom: 100%;
        left: 0;
        display: none;
        z-index: 1;
        @include transition(all 0.3s linear);
    }
    .tooltip-hover {
        cursor: pointer;
        min-height: 100%;
        &:hover .tooltip-content {
            display: block;
        }
    }
}

.dropdown {
    width: 100%;
}
.predefined-template-item {
    border-radius: rem(60);
    cursor: pointer;
    margin-right: rem(8);
    margin-bottom: rem(8);
    padding-top: rem(8);
    padding-bottom: rem(8);
    .template-delete-btn {
        right: rem(8);
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 10;
        svg {
            width: rem(12);
        }
    }
    .trash-custom-color {
        svg path {
            stroke: $danger;
        }
    }
}
.social-login {
    .btn-google-custom {
        font-size: rem(14);
        width: rem(260);
        height: rem(50);
        .google-icon {
            width: rem(20);
            height: rem(20);
        }
    }
    .btn-fb-custom {
        width: rem(260);
        height: rem(50);
        font-size: rem(14);
        background: $facebook-color;
    }

    .btn-thumbstack {
        font-size: rem(14);
        .thumbstack-icon {
            width: rem(20);
            height: rem(20);
        }
    }
    .thumbstack-list {
        .thumbstack-icon {
            width: rem(30);
            height: rem(30);
        }
        .trash-custom-color {
            svg path {
                stroke: $danger;
            }
        }
    }
    .unsync-btn {
        vertical-align: middle;
        border: none;
        height: rem(26);
        line-height: rem(26);
        padding: 0 rem(15);
        border-radius: rem(30);
        color: #fff;
        background-color: #b4becb;
        font-size: rem(12);
    }
}

.b-customer-key {
    .btn-copy {
        top: 50%;
        right: rem(10);
        @include transform(translateY(-50%));
        background: transparent;
        z-index: 1;
        width: rem(20);
    }

    .icon-image {
        max-height: rem(15);
    }
}

.form-group-gn {
    .label-text {
        font-size: rem(14);
    }
}
.width-full {
    width: 100% !important;
}

.fb-icon {
    display: inline-block;
    width: rem(14);
}

.profil-image-block {
    text-align: left;
    .image-item {
        margin-bottom: rem(10);
    }
    figure {
        display: inline-block;
    }
    .upload-photo {
        font-size: rem(12);
    }
}

.my-account-btn {
    padding: 0 rem(30);
    font-size: rem(14);
    font-family: $tertiary-font;
    min-width: rem(120);
}

.gn-settings-btn {
    padding: 0 rem(24);
    font-family: $tertiary-font;
}

.gn-settings-show-btn {
    font-family: $tertiary-font;
    height: rem(42);
    padding: 0 rem(24);
}

.social-link-item {
    margin-bottom: rem(20);
    position: relative;
    padding-left: rem(52);
    a {
        font-size: rem(14);
        font-family: $tertiary-font;
        font-weight: 400;
        display: inline-block;
        margin-right: rem(15);
        vertical-align: middle;
        color: $secondary;
        text-decoration: none;
    }
    .social-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: rem(32);
    }
    .social-icon-fb {
        position: absolute;
        left: 0;
        top: 0;
        width: rem(32);
        text-align: center;
        svg {
            width: rem(32);
            height: rem(32);
        }
    }
}

.form-group-gn {
    .dropdown {
        svg {
            display: block;
        }
    }
}

.receive-code {
    font-size: rem(13);
    margin-bottom: rem(20);
    margin-top: rem(5);
    .undo-btn {
        font-size: rem(13);
        background: transparent;
        color: $primary;
        cursor: pointer;
        .icons-undo {
            @include size(rem(13));
            display: inline-block;
            position: relative;
            top: rem(-2);
            margin-right: rem(5);
            height: auto;
            svg path {
                fill: $primary;
            }
        }
    }
}

.add-templates-btn {
    color: $secondary;
    padding-left: rem(18);
    &:before {
        content: "+";
        font-size: rem(17);
        position: absolute;
        left: 0;
        margin-top: rem(-1);
        top: 50%;
        @include transform(translateY(-50%));
    }
    &:hover {
        color: $primary;
    }
}

.font-size-14 {
    font-size: rem(14);
}

.subscription-item {
    position: relative;
    display: flex;
    justify-content: space-between;

    .subscription-info {
        padding: 15px 0px 15px 20px;

        .subscription-value {
            font-size: rem(24);
            font-family: $tertiary-font;
        }

        .subscription-label {
            font-size: rem(16);
        }
    }

    .subscription-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        padding: 9px 18px 10px 0px;

        .subscription-icon {
            display: flex;
            width: rem(30);
            height: rem(30);
            border: 1px solid #dbe3eb;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            > .rotate-45 {
                transform: rotate(45deg);

                path {
                    stroke: red;
                }
            }
        }
    }
}

.notification-outer-wrapper {
    .field-additional-email {
        width: 100%;
        max-width: rem(340);
    }
    .no-action {
        cursor: not-allowed;
    }
}

.toast {
    color: #082244;
    width: 100%;
    display: flex;
    align-items: center;

    > span {
        margin-left: rem(8.67);
    }

    > svg {
        width: rem(14.67);
        height: rem(14.67);

        > path {
            fill: black;
        }
    }
}

.subscription-cancelled-icon {
    width: rem(80);
    height: rem(80);

    > path {
        fill: #409c1c;
    }
}

.subscription-cancel-failed-icon {
    width: rem(80);
    height: rem(80);
}

.cancel-popup {
    :global(.modal-body) {
        padding-bottom: 0;
    }
}
