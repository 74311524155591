.move-down-close-btn {
  top: 6px !important;
}

.modal-spacing {
  padding-top: 1.875rem;
}

.modal-spacing-nocsv {
  padding-top: calc(1.875rem + 5px);
  padding-bottom: 5px;
}