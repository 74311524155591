    //@include media-breakpoint-down(lg) { 
    @media only screen and (max-width: 1279px) {
    .tab-menu{
        width:rem(250);
        border-right:none;
        position: relative;
        z-index:1;
        .menu-list{
            .menu-list-item{
                padding-top:rem(5);
                padding-bottom:rem(5);              
                padding: rem(8) rem(10) rem(8) rem(40);
                position: relative;
                .max-width{
                    max-width:rem(17);
                }
                i{
                    position: absolute; 
                    left: rem(10);                   
                }
                .btn-icon{
                    top:0;
                    img{
                        max-width:rem(16);
                    }
                }
            }
        }
    }
    .dropdown-title{
        cursor: pointer;
        span{
            font-size:rem(14);
            color:$black;
            padding:0 rem(10);
        }
    }
    .dropdown-menu{
        position:absolute;
        z-index:1;
        width:100%;
        background:$white;
        border:rem(1) solid $border-color;
        padding:rem(15);
        @include border-radius($border-radius);
        left:0;
        top:80%;
        margin-top:rem(5);
        visibility:hidden;
        @include opacity(0);
        @include transition(all 0.1s linear);
        &.dropdown-menu-open{
            visibility:visible;
            top:100%;
            @include opacity(1);
        }
        .menu-header{
            padding: rem(5) 0 rem(10);
            font-weight: 400;
            font-family: $tertiary-font;
        }
    }

   
}

