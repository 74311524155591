@include media-breakpoint-up(md) {
    .account-side-open {
        .messenger-wrapper {
            .b-account {
                width: rem(330);
            } 
            .btn-account-details{
                right: rem(329);
            }
        }
    }
}