@include media-breakpoint-only(lg) { 

}
@media (min-width: 1200px) and (max-width: 1250px) {
    .side-menu-open {
      .b-table-footer-btn {
        .footer-custom-button {
          font-size: rem(11);
        }
      }
    }
  }
  
  // ////////////// end between media query
  
  //react select highlight text
  .react-select-unavailable-data {
    font-family: $secondary-bold-font;
    color      : $primary;
  
    .react-select-highlight-text {
      color: $primary;
    }
  }